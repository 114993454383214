import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  sessionWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    padding: '50px 15px',
    '& button.MuiButton-contained': {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: { padding: '100px 20px 50px 20px' },
  },
  countDown: {
    fontSize: '4rem',
    [theme.breakpoints.up('lg')]: { fontSize: '4.5rem' },
    fontWeight: 700,
  },
  subTitle: {
    fontSize: '2.063rem',
    fontFamily: 'interBold, "Open Sans", Arial, sans-serif',
    color:
      theme.palette.mode === 'light'
        ? theme.bdColors.fuscousGrey
        : theme.palette.common.white,
    textTransform: 'none',
  },
  timeOutIcon: {
    fontSize: '3rem',
    fill: theme.palette.warning.main,
  },
}));

export default useStyles;
