import isEqual from 'lodash/isEqual';
import {
  DateTime,
  Duration,
  DurationLike,
  DurationLikeObject,
  DurationUnit,
  Interval,
  ToRelativeCalendarOptions,
} from 'luxon';

import {
  DATE_TIME_FORMAT_WITH_TIMEZONE,
  DAY_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_EMPTY_VALUE,
  LanguageLocale,
  MONTH_DAY_FORMAT,
  MONTH_DAY_WEEK_FORMAT,
  MONTH_SHORT_FORMAT,
  SEVEN_DAYS_IN_MS,
  TIME_API_FORMAT,
  TIME_FORMAT_WITH_TIMEZONE,
  TIME_FORMAT_WITHOUT_TIMEZONE,
} from '../constants/common.constant';
import {
  DATE_TIME_END_MIDNIGHT_SECOND,
  DATE_TIME_END_MINUTE,
  DATE_TIME_HOUR_BEFORE_MIDNIGHT,
  DATE_TIME_MIDNIGHT_HOUR,
  DATE_TIME_START_MINUTE,
  DATE_TIME_START_SECOND,
} from '../constants/date-time.constants';
import { DateRange } from '../models/common.model';

// CONSTANTS

export const MINUTE = 1000 * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

// CREATION / PARSING

export const fromISODate = (value?: string): Date | undefined =>
  value && isISODate(value) ? DateTime.fromISO(value).toJSDate() : undefined;

export const startDateTime = (
  params = {} as { date?: string; startTime?: string }
): Date => {
  const { date, startTime } = params;
  return DateTime.fromJSDate(date ? new Date(date) : new Date())
    .setZone('UTC')
    .set({
      second: 0,
      minute: startTime ? parseInt(startTime.split(':')[1]) : 0,
      hour: startTime ? parseInt(startTime.split(':')[0]) : 0,
    })
    .toJSDate();
};

export const endDateTime = (
  params = {} as { date?: string; endTime?: string }
): Date => {
  const { date, endTime } = params;
  return DateTime.fromJSDate(date ? new Date(date) : new Date())
    .setZone('UTC')
    .set({
      second: 59,
      minute: endTime ? parseInt(endTime.split(':')[1]) : 59,
      hour: endTime ? parseInt(endTime.split(':')[0]) : 23,
    })
    .toJSDate();
};

export const startDateTimeInTimeZone = ({
  date,
  startTime,
  timeZone,
}: {
  date?: string;
  startTime?: string;
  timeZone?: string;
}): DateTime => {
  const jsDate = date ? new Date(date) : new Date();

  return DateTime.fromJSDate(jsDate)
    .setZone(timeZone)
    .set({
      hour: startTime
        ? parseInt(startTime.split(':')[0])
        : DATE_TIME_MIDNIGHT_HOUR,
      minute: startTime
        ? parseInt(startTime.split(':')[1])
        : DATE_TIME_START_MINUTE,
      second: DATE_TIME_START_SECOND,
    });
};

export const endDateTimeInTimeZone = ({
  date,
  endTime,
  timeZone,
}: {
  date?: string;
  endTime?: string;
  timeZone?: string;
}): DateTime => {
  const jsDate = date ? new Date(date) : new Date();

  return DateTime.fromJSDate(jsDate)
    .setZone(timeZone)
    .set({
      hour: endTime
        ? parseInt(endTime.split(':')[0])
        : DATE_TIME_HOUR_BEFORE_MIDNIGHT,
      minute: endTime ? parseInt(endTime.split(':')[1]) : DATE_TIME_END_MINUTE,
      second: DATE_TIME_END_MIDNIGHT_SECOND,
    });
};

export const getDateRangeConfig = (range: DateRange) => {
  const startOfToday: DateTime = DateTime.now().startOf('day');
  const endOfToday: DateTime = startOfToday.endOf('day');
  switch (range) {
    case DateRange.TODAY:
      return { start: startOfToday.toJSDate() };
    case DateRange.YESTERDAY: {
      const yesterday = startOfToday.minus({ days: 1 });
      return {
        start: yesterday.toJSDate(),
        end: endOfToday.toJSDate(),
      };
    }
    case DateRange.THIS_WEEK: {
      const thisWeek = startOfToday.startOf('week');
      return {
        start: thisWeek.toJSDate(),
        end: endOfToday.toJSDate(),
      };
    }
    case DateRange.LAST_WEEK: {
      const lastWeek = startOfToday.startOf('week').minus({ week: 1 });
      return {
        start: lastWeek.toJSDate(),
        end: endOfToday.toJSDate(),
      };
    }
  }
};

export const createMidnightStartTime = (timeZone?: string): DateTime => {
  const date = new Date();
  const ONE_BASED_INDEX = 1;
  const MIDNIGHT_HOUR = 0;
  const START_MINUTE = 0;

  return DateTime.local(
    date.getFullYear(),
    date.getMonth() + ONE_BASED_INDEX,
    date.getDate(),
    {
      zone: timeZone,
    }
  ).set({
    hour: MIDNIGHT_HOUR,
    minute: START_MINUTE,
  });
};

export const createMidnightEndTime = (timeZone?: string): DateTime => {
  const date = new Date();
  const ONE_BASED_INDEX = 1;
  const HOUR_BEFORE_MIDNIGHT = 23;
  const END_MINUTE = 59;
  const END_SECOND = 59;

  return DateTime.local(
    date.getFullYear(),
    date.getMonth() + ONE_BASED_INDEX,
    date.getDate(),
    {
      zone: timeZone,
    }
  ).set({
    hour: HOUR_BEFORE_MIDNIGHT,
    minute: END_MINUTE,
    second: END_SECOND,
  });
};

// CALCULATIONS

export const add = (date: Date, data: DurationLike): Date =>
  DateTime.fromJSDate(date).plus(data).toJSDate();

export const sub = (date: Date, data: DurationLike): Date =>
  DateTime.fromJSDate(date).minus(data).toJSDate();

export const subDays = (date: Date, days: number): Date => sub(date, { days });

// Luxon starts week on Monday, but we want Sunday
export const getStartOfWeek = (date: DateTime) => {
  return isSunday(date)
    ? date.startOf('day')
    : date.startOf('week').minus({ day: 1 });
};

// This intentionally returns yesterday if the date is this week
// We exclude today because the report data isn't ready
// Luxon ends week on Sunday, but we want Saturday
export const getEndOfWeekDvic = (date: DateTime, today: DateTime) => {
  const isCurrentDayThisWeek = isEqual(
    getStartOfWeek(date),
    getStartOfWeek(today)
  );

  if (isCurrentDayThisWeek) {
    // Show yesterday unless today is Sunday
    return isSunday(today)
      ? today.endOf('day')
      : today.minus({ day: 1 }).endOf('day');
  }

  // Sunday is the end of the week in Luxon, so we use days to skip forward to Saturday
  if (isSunday(date)) {
    return date.plus({ day: 6 }).endOf('day');
  }

  // Not this week and not Sunday means we just want Saturday
  return date.endOf('week').minus({ day: 1 });
};

export const daysBetween = (dateOne: Date, dateTwo: Date): number => {
  const end = DateTime.fromJSDate(dateTwo);
  const start = DateTime.fromJSDate(dateOne);

  return end.diff(start, 'days').days;
};

export const getDateRange = ({
  days,
  date = new Date(),
}: {
  days: number;
  date?: Date;
}): { startDate: Date; endDate: Date } => ({
  startDate: sub(date, {
    days,
  }),
  endDate: sub(date, {
    days: 1,
  }),
});

export const THIRTY_DAYS_AGO = (): Date =>
  DateTime.fromJSDate(new Date())
    .set({ second: 0, minute: 0, hour: 0 })
    .minus({ day: 30 })
    .toJSDate();

// COMPARISON

export const isBefore = (dateToCompare: Date, date: Date): boolean =>
  Interval.fromDateTimes(dateToCompare, dateToCompare).isBefore(
    DateTime.fromJSDate(date)
  );

export const isAfter = (dateToCompare: Date, date: Date): boolean =>
  Interval.fromDateTimes(dateToCompare, dateToCompare).isAfter(
    DateTime.fromJSDate(date)
  );

export const dateDiff = (dateOne: Date, dateTwo: Date): number =>
  Math.abs(
    DateTime.fromJSDate(dateOne)
      .diff(DateTime.fromJSDate(dateTwo))
      .as('seconds')
  );

export const isSameDate = (
  dateOne: string,
  dateTwo: string,
  timeZone?: string
): boolean =>
  DateTime.fromISO(dateOne).setZone(timeZone).toString().split('T')[0] ===
  DateTime.fromISO(dateTwo).setZone(timeZone).toString().split('T')[0];

export const isBetween = (
  startDate: Date,
  endDate: Date,
  dateToCompare: Date,
  timeZone?: string
): boolean =>
  Interval.fromDateTimes(
    DateTime.fromJSDate(startDate).setZone(timeZone).minus({ milliseconds: 1 }),
    DateTime.fromJSDate(endDate).setZone(timeZone).plus({ milliseconds: 1 })
  ).contains(DateTime.fromJSDate(dateToCompare).setZone(timeZone));

// FORMATTING

export const format = (
  date?: Date,
  format: string = DEFAULT_DATE_FORMAT,
  locale: string = LanguageLocale.EN,
  timeZone?: string
): string => {
  return date
    ? DateTime.fromJSDate(date)
        .setLocale(locale)
        .setZone(timeZone)
        .toFormat(format)
    : '';
};

export const formatToDefaultDate = (date: Date): string =>
  DateTime.fromJSDate(date).toFormat(DEFAULT_DATE_FORMAT);

export const formatToDefaultDateTime = (
  date: Date,
  options?: { zone: string | undefined }
): string =>
  DateTime.fromJSDate(date, options).toFormat(DEFAULT_DATE_TIME_FORMAT);

export const formatToDateTimeWithTimezone = (
  date: Date,
  format: string = DATE_TIME_FORMAT_WITH_TIMEZONE,
  timeZone?: string
): string => DateTime.fromJSDate(date).setZone(timeZone).toFormat(format);

export const formatToTimeWithTimezone = (date: Date): string =>
  DateTime.fromJSDate(date).toFormat(TIME_FORMAT_WITH_TIMEZONE);

export const formatDateForAPI = (date: Date, format: string): string =>
  DateTime.fromJSDate(date).toUTC().toFormat(format).replace(' ', 'T');

export const toDateStringWithTimezone = (value: string): string =>
  DateTime.fromISO(value, { zone: 'utc' }).toISO();

export const toDateStringWithoutTimezone = (value: string): string =>
  DateTime.fromISO(value, { zone: 'utc' }).toFormat(DEFAULT_DATE_TIME_FORMAT);

export const formatToDateTimeMed = (value: string): string =>
  DateTime.fromISO(value, { zone: 'utc' }).toLocaleString(
    DateTime.DATETIME_MED
  );

export const formatISOString = (value: string, format: string): string =>
  DateTime.fromISO(value).toFormat(format);

export const formatDate = (
  date: Date,
  format: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT,
  locale: string = LanguageLocale.EN
): string => {
  return DateTime.fromJSDate(date).setLocale(locale).toLocaleString(format);
};

export const formatFromISO = (
  value: string,
  format: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT
) => DateTime.fromISO(value).toLocaleString(format);

export const toRelative = (date: Date, locale: string): string | null =>
  DateTime.fromJSDate(date).setLocale(locale).toRelative();

export const toRelativeCalendar = (
  date: Date,
  locale: string,
  opt?: ToRelativeCalendarOptions
): string | null =>
  DateTime.fromJSDate(date).setLocale(locale).toRelativeCalendar(opt);

export const toTimeFormatWithoutTimeZone = (date: Date): string | null =>
  DateTime.fromJSDate(date).toFormat(TIME_FORMAT_WITHOUT_TIMEZONE);

export const formatDurationTime = (
  time: number,
  emptyValue?: string
): string => {
  const valueWithoutSeconds = time - (time % MINUTE);
  const units: DurationUnit[] = [];
  const removeLastCommaRegex = /,(?=[^,]*$)/;

  if (valueWithoutSeconds / HOUR >= 1) {
    units.push('hours');
  }
  if ((valueWithoutSeconds % HOUR) / MINUTE >= 1) {
    units.push('minutes');
  }
  const formattedValue = valueWithoutSeconds
    ? Duration.fromMillis(valueWithoutSeconds)
        .shiftTo(...units)
        .toHuman({
          unitDisplay: 'narrow',
          notation: 'standard',
          useGrouping: true,
        })
    : emptyValue
      ? emptyValue
      : DEFAULT_EMPTY_VALUE;

  return units.length == 2
    ? formattedValue.replace(removeLastCommaRegex, '')
    : formattedValue;
};

export const formatDateRange = ({
  dateFormat = MONTH_DAY_FORMAT,
  locale = LanguageLocale.EN,
  ...rest
}: {
  days: number;
  date?: Date;
  dateFormat?: string;
  locale?: string;
}): string => {
  const { startDate, endDate } = getDateRange(rest);
  return `${format(startDate, dateFormat, locale)} - ${format(
    endDate,
    dateFormat,
    locale
  )}`;
};

export const formatDurationObject = (duration: DurationLikeObject): string => {
  return Duration.fromObject(duration).toHuman({
    unitDisplay: 'short',
    notation: 'compact',
  });
};

export const formatDurationDays = (time: number): string => {
  const valueWithoutHours = time > DAY ? time - (time % DAY) : time;
  const units: DurationUnit[] = ['days'];
  if (valueWithoutHours >= YEAR) {
    units.push('years');
  }
  if (valueWithoutHours >= MONTH) {
    units.push('months');
  }
  return valueWithoutHours
    ? Duration.fromMillis(valueWithoutHours)
        .shiftTo(...units)
        .toHuman({
          unitDisplay: 'short',
          notation: 'compact',
        })
    : DEFAULT_EMPTY_VALUE;
};

export const formatMilliseconds = (
  milliseconds: number,
  units: DurationUnit[],
  unitDisplay: 'short' | 'long' | 'narrow' = 'long',
  userLocale: string = LanguageLocale.EN
) =>
  Duration.fromMillis(milliseconds, { locale: userLocale })
    .shiftTo(...units)
    .toHuman({
      unitDisplay,
      notation: 'compact',
    });

export const formatDuration = (
  dateOne: Date,
  dateTwo: Date,
  userLocale?: string,
  unitDisplay?: 'short' | 'long' | 'narrow'
): string | undefined => {
  const milliseconds = Math.abs(
    DateTime.fromJSDate(dateOne)
      .diff(DateTime.fromJSDate(dateTwo))
      .as('milliseconds')
  );
  if (milliseconds >= DAY) {
    return formatMilliseconds(
      milliseconds - (milliseconds % DAY),
      ['days'],
      unitDisplay,
      userLocale
    );
  } else if (milliseconds >= HOUR) {
    return formatMilliseconds(
      milliseconds - (milliseconds % HOUR),
      ['hours'],
      unitDisplay,
      userLocale
    );
  } else if (milliseconds >= MINUTE) {
    return formatMilliseconds(
      milliseconds - (milliseconds % MINUTE),
      ['minutes'],
      unitDisplay,
      userLocale
    );
  } else {
    return undefined;
  }
};

export const formatDateFromDayNumber = (
  day: number,
  year: number = new Date().getFullYear()
): string => {
  const date = DateTime.fromMillis(Date.UTC(year, 0, day)).toUTC();

  return date.toFormat(MONTH_DAY_WEEK_FORMAT);
};

export const formatDateFromMonthNumber = (
  day: number,
  year: number = new Date().getFullYear()
): string => {
  const date = DateTime.fromMillis(Date.UTC(year, day - 1)).toUTC();

  return date.toFormat(MONTH_SHORT_FORMAT);
};

export const formatDateRangeFromWeekNumber = (
  week: number,
  year: number = new Date().getFullYear()
): string => {
  const date = DateTime.fromObject({
    weekYear: year,
    weekNumber: week,
  }).toUTC();

  const fromDate = date.startOf('week');
  const toDate = date.endOf('week');

  const fromMonth = fromDate.toFormat(MONTH_SHORT_FORMAT);
  const toMonth = toDate.toFormat(MONTH_SHORT_FORMAT);

  const fromDay = fromDate.toFormat(DAY_FORMAT);
  const toDay = toDate.toFormat(DAY_FORMAT);

  const from = `${fromMonth} ${fromDay}`;
  const to = fromMonth === toMonth ? toDay : `${toMonth} ${toDay}`;

  return `${from} - ${to}`;
};

export const formatTimezone = (language: string, timeZone?: string): string =>
  new Date()
    .toLocaleDateString(language, {
      day: '2-digit',
      timeZoneName: 'shortGeneric',
      timeZone,
    })
    .slice(4);
export const formatDurationSecondsMinutes = (
  time: number,
  emptyValue?: string
): string => {
  const units: DurationUnit[] = [];
  if ((time % HOUR) / MINUTE >= 1) {
    units.push('minutes');
  }
  if ((time % HOUR) / MINUTE < 1) {
    units.push('seconds');
  }
  return time
    ? Duration.fromMillis(time)
        .shiftTo(...units)
        .toHuman({
          unitDisplay: 'narrow',
          notation: 'compact',
        })
        .replace(/,/g, '')
    : emptyValue
      ? emptyValue
      : DEFAULT_EMPTY_VALUE;
};

export const formatTimeWithZone = (
  date: string,
  timeZone?: string | null,
  format: string = TIME_FORMAT_WITH_TIMEZONE
): string => {
  const convertedDate = timeZone
    ? DateTime.fromJSDate(new Date(date)).setZone(timeZone).isValid
      ? DateTime.fromJSDate(new Date(date)).setZone(timeZone)
      : DateTime.fromISO(date).setZone('utc')
    : DateTime.fromISO(date).setZone('utc');
  return convertedDate.isValid
    ? convertedDate.toFormat(format)
    : DEFAULT_EMPTY_VALUE;
};

export const formatDateString = ({
  value,
  dateFormat,
  locale,
  timeZone,
}: {
  value?: string;
  dateFormat?: string;
  locale?: string;
  timeZone?: string;
}): string | undefined =>
  value ? format(new Date(value), dateFormat, locale, timeZone) : undefined;

export const formatDateStringToDateTimeAPI = (
  value?: string,
  locale?: string,
  timeZone?: string
): string | undefined =>
  formatDateString({ value, dateFormat: TIME_API_FORMAT, locale, timeZone });

export const formatDateTimeAPIToDateTimeString = (
  dateString?: string,
  zone?: string
): string | undefined => {
  if (!dateString) {
    return undefined;
  }
  const dateTime = DateTime.fromFormat(dateString, TIME_API_FORMAT, { zone });
  return dateTime.isValid ? dateTime.toJSDate().toISOString() : undefined;
};

// VALIDATION

export const isDate = (value?: Date): boolean =>
  !!value && DateTime.fromJSDate(value).isValid;

export const isISODate = (value?: string): boolean =>
  !!value && DateTime.fromISO(value).isValid;

const isSunday = (date: DateTime) => date.weekdayShort === 'Sun';

/**
 * Checks if the provided timestamp is stale based on a threshold.
 * @param timestamp - The device timestamp in ISO format or number (milliseconds).
 * @param thresholdMs - Optional custom threshold in milliseconds. Defaults to 7 days.
 * @returns `true` if data is stale, `false` otherwise.
 */
export const isDataStale = (
  timestamp?: string | number,
  thresholdMs: number = SEVEN_DAYS_IN_MS
): boolean => {
  if (!timestamp) return false;

  const now = DateTime.now();
  const dataTime =
    typeof timestamp === 'string'
      ? DateTime.fromISO(timestamp)
      : DateTime.fromMillis(timestamp);

  return now.diff(dataTime).milliseconds > thresholdMs;
};
