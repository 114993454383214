import { useLDClient } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, useCallback, useState } from 'react';

import FeatureFlagsProviderContext from './FeatureFlagsProviderContext';

interface FeatureFlagsResolverProps {
  async?: boolean;
}

const FeatureFlagsResolver = ({
  async,
  children,
}: PropsWithChildren<FeatureFlagsResolverProps>): JSX.Element => {
  const ldClient = useLDClient();
  const [ready, setReady] = useState(!!async);

  const setContext = useCallback(
    (name, value) => {
      if (name && value) {
        const context = {
          //It is multi to support other context objects easily
          kind: 'multi',
          [name]: value,
        };

        ldClient?.identify(context, undefined, () => {
          setReady(true);
        });
      }
    },
    [ldClient]
  );

  return (
    <FeatureFlagsProviderContext.Provider value={{ ready, setContext }}>
      {children}
    </FeatureFlagsProviderContext.Provider>
  );
};

export default FeatureFlagsResolver;
