import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { RootState } from '~/app/rootReducer';

import { BDError, type ValidationError } from '../error.model';

export enum BDRequestType {
  GET_ALL = 'Get All',
  GET_BY_ID = 'Get by ID',
  ADD = 'Add',
  UPDATE = 'Update',
  DELETE = 'Delete',
  AUTH = 'Auth',
  PROFILE = 'Profile',
  PERMISSIONS = 'Permissions',
  UNKNOWN = 'Unknown',
  GET_USER_COUNTS = 'Get User Counts',
  GET_ASSET_COUNTS = 'Get Asset Counts',
  GET_USER_SUMMARY = 'Get User Summary',
  GET_ASSET_SUMMARY = 'Get Asset Summary',
  GET_STATS = 'Get Stats',
  VALIDATE_SSO_EMAIL = 'Validate SSO Email domain',
}

export enum BDRequestStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface OperationStatus {
  status: BDRequestStatus;
  errors?: BDError<unknown>[];
}
export interface ApiResponse<T = null> {
  success: boolean;
  targetUrl: string | null;
  unauthorizedRequest: boolean;
  result: T;
  errors: (BDError | ValidationError)[] | null;
  __bd: boolean;
}

export interface ThunkAPIConfig<T = null> {
  state: RootState;
  rejectValue: BDError<T>;
}

export interface ApiActionConfig {
  requestParams?: { [k: string]: string | undefined | null };
  requestBody?: unknown;
}

export interface ThunkCreatorConfig {
  useDefaultHeaders?: boolean;
  axiosConfig?: AxiosRequestConfig;
}

type ThunkUrlAdapter<ThunkArg> = (args: ThunkArg, state: RootState) => string;

type ThunkArgAdapter<ThunkArg> = (
  args: ThunkArg,
  state: RootState
) => ApiActionConfig;

type ThunkResponseAdapter<Returned, ThunkArg> = (
  data: unknown,
  state: RootState,
  args: ThunkArg
) => Returned;

type ThunkErrorAdapter<Returned> = (
  err: AxiosError<Returned> | BDError,
  config?: { data?: unknown; state?: RootState }
) => BDError<Returned>;

export type ThunkConfigAdapter<ThunkArg> = (
  args: ThunkArg,
  state: RootState
) => ThunkCreatorConfig;

export type ThunkPayloadCreatorParams<Returned, ThunkArg> = {
  url: string | ThunkUrlAdapter<ThunkArg>;
  axiosOptions?: ThunkCreatorConfig | ThunkConfigAdapter<ThunkArg>;
  argAdapter?: ThunkArgAdapter<ThunkArg>;
  responseAdapter?: ThunkResponseAdapter<Returned, ThunkArg>;
  errorAdapter?: ThunkErrorAdapter<Returned>;
  skipAuth?: boolean;
};

export type ThunkPayloadCreatorResult<Returned, ThunkArg> =
  AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig<Returned>>;
