import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { type FunctionComponent, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import NotificationIcon from '~/assets/icons/new/notification.svg?react';
import SettingsIcon from '~/assets/icons/new/settings.svg?react';
import CSPlatformDateTime from '~/common/components/date/CSPlatformDateTime';
import CSPlatformSettingsMenu from '~/common/components/SettingsMenu/CSPlatformSettingsMenu';
import usePageTitle from '~/common/hooks/usePageTitle';
import useSessionId from '~/common/hooks/useSessionId';
import { AlertEvent } from '~/common/models/alert.model';
import { NotificationType } from '~/common/models/notification.model';
import { FLEET_NOTIFICATIONS } from '~/common/models/pages/fleetPages.model';
import {
  SETTINGS_NOTIFICATIONS_ACCOUNT,
  SETTINGS_NOTIFICATIONS_USERS,
  SETTINGS_NOTIFICATIONS_VEHICLES,
  SETTINGS_NOTIFICATIONS_VEHICLES_ACTIVITY,
  SETTINGS_NOTIFICATIONS_VEHICLES_DIAGNOSTICS,
} from '~/common/models/pages/settingsPages.model';
import {
  markAllAlertAsRead,
  setEditableSubscriptionId,
  setHighlightedSubscriptionId,
} from '~/features/alerts/alertsSlice';
import { mapEventToMessage } from '~/features/alerts/utils/alerts.utils';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

import { useTranslations } from '../../../../../../packages/localization-client';
import accountIcon from '../../../assets/icons/new/account.svg';
import vehicleIcon from '../../../assets/icons/new/carIcon.svg';
import CheckSquareIcon from '../../../assets/icons/new/checkSquareIcon.svg?react';
import greenCircledCheckMark from '../../../assets/icons/new/greenCircledCheckMark.svg';
import ShareIcon from '../../../assets/icons/new/shareIcon.svg?react';
import userIcon from '../../../assets/icons/new/user.svg';
import { useStyles } from './CSPlatformNotificationListView.styles';

export interface ListItem {
  date: Date | string;
  id: string | number;
  message: string;
  read: boolean;
  iconKey?: string;
  showSettings?: boolean;
}

export enum DisplayType {
  All = 'read',
  UNREAD = 'unread',
}

export enum TabType {
  all = 'all',
  unread = 'unread',
}

export const iconMap: { [key: string]: string } = {
  'app-account-update': accountIcon,
  'low-washer-fluid': vehicleIcon,
  'flat-tire': vehicleIcon,
  'oil-life': vehicleIcon,
  'brake-pads': vehicleIcon,
  'airbag-system': vehicleIcon,
  'antilock-braking-system': vehicleIcon,
  'electric-drive-system': vehicleIcon,
  'emission-system': vehicleIcon,
  'engine-and-transmission': vehicleIcon,
  'lithium-ion-battery': vehicleIcon,
  'low-battery': vehicleIcon,
  'low-brake-fluid': vehicleIcon,
  'low-tire': vehicleIcon,
  'stability-control-system': vehicleIcon,
  'app-add-user': userIcon,
  'app-remove-user': userIcon,
  'app-add-vehicle': vehicleIcon,
  'app-remove-vehicle': vehicleIcon,
  'vehicle-onboarding': vehicleIcon,
  user: userIcon,
  vehicle: vehicleIcon,
};

const EVENT_NOTIFICATION_MAPPING: { [key in AlertEvent]?: NotificationType } = {
  [AlertEvent.ADD_USER]: NotificationType.USER_ACTIVITY,
  [AlertEvent.REMOVE_USER]: NotificationType.USER_ACTIVITY,
  [AlertEvent.ACCOUNT_UPDATE]: NotificationType.ACCOUNT_ACTIVITY,
  [AlertEvent.ONBOARDING_VEHICLE]: NotificationType.VEHICLE,
};

const filterListData = (listData: any[], tabValue: TabType) => {
  return listData.filter((item) => {
    return tabValue === TabType.all || !item.read;
  });
};

export type PlatformListProps = {
  listData: any[];
  hasPermission: boolean;
  show: DisplayType;
  handleMarkNotification?: (alertId: string, read: boolean) => void;
  handleMarkAllAsRead?: () => void;
  insidePopover?: boolean;
};

interface CSPlatformNotificationListViewProps {
  insidePopover?: boolean;
  handleCloseNotification: () => void;
}

type CombinedProps = PlatformListProps & RouteComponentProps;

const CSPlatformNotificationListView: FunctionComponent<
  CombinedProps & CSPlatformNotificationListViewProps
> = ({
  listData,
  hasPermission,
  handleMarkNotification,
  handleMarkAllAsRead,
  insidePopover,
  handleCloseNotification,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);
  const [tabValue, setTabValue] = useState(TabType.all);
  const dispatch = useDispatch();
  const sessionId = useSessionId(true, true);
  const history = useHistory();

  // Consolidate filteredListData logic here using the helper function
  const filteredListData = filterListData(listData, tabValue);

  const unreadCount = filteredListData.filter((item) => !item.read).length;

  const handleChangeTab = (
    event: React.ChangeEvent<object>,
    newValue: TabType
  ) => {
    setTabValue(newValue);
  };

  const getFeedNotificationSettingsMainMenuItems = () => {
    return [
      {
        text: (
          <Typography variant="body_regular">
            {translations['settings:notifications.markAllRead']}
          </Typography>
        ),
        icon: (
          <CheckSquareIcon data-testid="header-notifications-feed-options-mark-all-read" />
        ),
        onClick: (
          e: { stopPropagation: () => void },
          handleClose: () => void
        ) => {
          e.stopPropagation();
          if (handleMarkAllAsRead) {
            handleMarkAllAsRead();
          }
          handleClose();
        },
      },
      {
        text: (
          <Typography variant="body_regular">
            {translations['settings:settings']}
          </Typography>
        ),
        icon: (
          <SettingsIcon data-testid="header-notifications-feed-options-settings" />
        ),
        onClick: (
          e: { stopPropagation: () => void },
          handleClose: () => void
        ) => {
          e.stopPropagation();
          history.push(SETTINGS_NOTIFICATIONS_VEHICLES);
          handleClose();
          handleCloseNotification();
        },
      },
      {
        text: (
          <Typography variant="body_regular">
            {translations['settings:notifications.seeAll']}
          </Typography>
        ),
        icon: <ShareIcon data-testid="header-notifications-feed-view-all" />,
        onClick: (
          e: { stopPropagation: () => void },
          handleClose: () => void
        ) => {
          e.stopPropagation;
          history.push(FLEET_NOTIFICATIONS, { insidePopover: true });
          handleClose();
          handleCloseNotification();
        },
      },
    ];
  };

  const getNotificationSettingsMainMenuItems = () => {
    return [
      {
        text: (
          <Typography variant="body_regular">
            {translations['settings:notifications.markAllRead']}
          </Typography>
        ),
        icon: (
          <NotificationIcon data-testid="notifications-options-mark-all-read" />
        ),
        onClick: (
          e: { stopPropagation: () => void },
          handleClose: () => void
        ) => {
          e.stopPropagation();
          if (handleMarkAllAsRead) {
            handleMarkAllAsRead();
          }
          handleClose();
        },
      },
      {
        text: (
          <Typography variant="body_regular">
            {translations['settings:settings']}
          </Typography>
        ),
        icon: <SettingsIcon data-testid="notifications-options-settings" />,
        onClick: (
          e: { stopPropagation: () => void },
          handleClose: () => void
        ) => {
          e.stopPropagation();
          handleClose();
          history.push(SETTINGS_NOTIFICATIONS_VEHICLES);
        },
      },
    ];
  };

  const getSettingsMenuItems = (notificationItem: {
    read: boolean;
    id: string;
    eventType?: string;
    subscriptionId?: string;
  }) => [
    {
      text: notificationItem.read
        ? translations['settings:notifications.markAsUnRead']
        : translations['settings:notifications.markAsRead'],
      icon: notificationItem.read ? (
        <NotificationIcon data-testid="notifications-row-options-mark-as-unread" />
      ) : (
        <CheckSquareIcon data-testid="notifications-row-options-mark-as-read" />
      ),
      onClick: (
        e: { stopPropagation: () => void },
        handleClose: () => void
      ) => {
        e.stopPropagation();
        if (handleMarkNotification) {
          handleMarkNotification(
            notificationItem?.id as string,
            !notificationItem?.read
          );
        }
        handleClose();
      },
    },
    {
      text: (
        <Typography variant="body_regular">
          {translations['settings:notifications.editSettings']}
        </Typography>
      ),
      icon: <SettingsIcon data-testid="notifications-options-edit-settings" />,
      onClick: (
        e: { stopPropagation: () => void },
        handleClose: () => void
      ) => {
        e.stopPropagation();
        handleClose();
        const notificationType =
          EVENT_NOTIFICATION_MAPPING[notificationItem?.eventType as AlertEvent];
        dispatch(setEditableSubscriptionId(notificationItem?.subscriptionId));
        dispatch(
          setHighlightedSubscriptionId(notificationItem?.subscriptionId)
        );
        switch (notificationType) {
          case NotificationType.ACCOUNT_ACTIVITY:
            history.push(SETTINGS_NOTIFICATIONS_ACCOUNT);
            break;
          case NotificationType.USER_ACTIVITY:
            history.push(SETTINGS_NOTIFICATIONS_USERS);
            break;
          case NotificationType.VEHICLE:
            history.push(SETTINGS_NOTIFICATIONS_VEHICLES_ACTIVITY);
            break;
          default:
            history.push(SETTINGS_NOTIFICATIONS_VEHICLES_DIAGNOSTICS);
        }
      },
    },
  ];

  usePageTitle(translations['settings:notifications.title']);

  return hasPermission ? (
    <>
      {!insidePopover && (
        <Box display="flex" justifyContent={'space-between'}>
          <Typography variant="header1_medium">
            {translations['settings:notifications.title']}
          </Typography>
          <Box sx={{ paddingRight: '24px' }}>
            <CSPlatformSettingsMenu
              options={getNotificationSettingsMainMenuItems()}
            />
          </Box>
        </Box>
      )}
      <Box
        className={
          insidePopover && markAllAlertAsRead()
            ? classes.popoverTabsContainer
            : classes.tabsContainer
        }
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="notification tabs"
        >
          <Tab
            data-testid="notifications-tabs-view-all"
            label={
              <Typography variant="body_medium">
                {translations['settings:notifications.allTab']}
              </Typography>
            }
            value={TabType.all}
          />
          <Tab
            data-testid="notifications-tabs-view-unread"
            label={
              <Typography variant="body_medium">
                {unreadCount > 0 ? (
                  <Trans
                    i18nKey={'settings:notifications.unreadTabWithCount'}
                    values={{ count: unreadCount }}
                  />
                ) : (
                  translations['settings:notifications.unreadTab']
                )}
              </Typography>
            }
            value={TabType.unread}
          />
        </Tabs>
        {insidePopover && tabValue === TabType.all ? (
          <CSPlatformSettingsMenu
            data-testid="CSPlatformSettingsMenu"
            options={getFeedNotificationSettingsMainMenuItems()}
          />
        ) : null}
      </Box>
      {insidePopover && <Divider />}
      <section className="contentInner">
        <Grid container spacing={2}>
          <Grid marginTop={insidePopover ? '40px' : undefined} item xs={12}>
            <List
              data-testid="CSPlatformList"
              className={classes.listContainer}
            >
              {filteredListData.length === 0 ? (
                <Grid item md={12} className={classes.messageContainer}>
                  <img
                    src={greenCircledCheckMark}
                    alt={'greenCircledCheckMark'}
                  />
                  <Typography variant={'body_medium'}>
                    {tabValue === TabType.all
                      ? translations[
                          'settings:notifications.messages.noneAtAll'
                        ]
                      : translations[
                          'settings:notifications.messages.noUnread'
                        ]}
                  </Typography>
                </Grid>
              ) : (
                filteredListData.map((item, index) => (
                  <ListItem
                    className={classes.listItem}
                    alignItems="flex-start"
                    divider={true}
                    disableGutters={true}
                    data-testid={`CSPlatformListItem${index}`}
                    key={index}
                    onClick={() => {
                      if (!item.read) {
                        handleMarkNotification &&
                          handleMarkNotification(item.id, true);
                      }
                    }}
                    secondaryAction={
                      !insidePopover && (
                        <CSPlatformSettingsMenu
                          options={getSettingsMenuItems(item)}
                        />
                      )
                    }
                  >
                    <ListItemAvatar
                      className={
                        item.read ? classes.readItem : classes.unreadItem
                      }
                    >
                      <Avatar
                        className={
                          item.read
                            ? classes.iconContainer
                            : classes.unReadIconContainer
                        }
                      >
                        {item.eventType && iconMap[item.eventType] ? (
                          <img
                            src={iconMap[item.eventType]}
                            alt={item.eventType}
                            width="24"
                            height="24"
                          />
                        ) : item.event && iconMap[item.event.event] ? (
                          <img
                            src={iconMap[item.event.event]}
                            alt={item.event.event}
                            width="24"
                            height="24"
                          />
                        ) : null}
                      </Avatar>
                      {!item.read && (
                        <Badge
                          data-testid={`unread-notification-icon${index}`}
                          aria-label={'Unread Notification'}
                          overlap="circular"
                          badgeContent=" "
                          variant="dot"
                          className={classes.badge}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      data-testid={`CSPlatformListItemText${index}`}
                      primary={
                        <Typography variant="body_regular">
                          {mapEventToMessage(item, translations)}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="label_regular"
                          color={theme.new.color.textSecondary}
                        >
                          <CSPlatformDateTime date={item.eventTimestamp} />
                        </Typography>
                      }
                    />
                  </ListItem>
                ))
              )}
            </List>
          </Grid>
        </Grid>
      </section>
    </>
  ) : (
    <>NO NOTIFICATION LIST PERMISSIONS</>
  );
};

export default CSPlatformNotificationListView;
