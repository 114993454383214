import type {
  AllowedContexts,
  ContextItem,
} from '~/features/profile/profileSlice';

export const transformAllowedContexts = ({
  allowedContexts,
  actingPermissionsContextIds,
}: {
  allowedContexts?: AllowedContexts;
  actingPermissionsContextIds?: {
    organizationsId?: string;
    hubsId?: string;
    fleetsId?: string;
  };
}): { root: ContextItem; subContexts: ContextItem[] } | undefined => {
  if (!allowedContexts || Object.keys(allowedContexts).length === 0) {
    return undefined;
  }

  const rootId = Object.keys(allowedContexts)[0];
  const rootContext = allowedContexts[rootId];

  if (!rootContext) {
    return undefined;
  }

  const hubContexts = rootContext.hubs || {};

  // if the user is fleet-scoped, return the fleet context
  if (actingPermissionsContextIds?.fleetsId) {
    const fleetId = actingPermissionsContextIds.fleetsId;
    const fleetHub = Object.values(hubContexts).find((hubContext) => {
      const fleetContexts = hubContext.fleets || {};
      return Object.hasOwn(fleetContexts, fleetId);
    });
    if (!fleetHub) {
      console.error(
        `Allowed contexts do not contain fleet-scoped user's fleet`
      );
      return undefined;
    }
    const fleetContext: ContextItem = {
      ...fleetHub.fleets[fleetId],
      parentIds: [rootId, fleetHub.id],
    };
    return { root: fleetContext, subContexts: [] };
  }

  const subContexts: ContextItem[] = Object.entries(hubContexts).flatMap(
    ([hubId, hubContext]) => {
      const fleetContexts = hubContext.fleets || {};
      return Object.values(fleetContexts).map((fleet) => ({
        ...fleet,
        parentIds: [rootId, hubId],
      }));
    }
  );

  return {
    root: { id: rootContext.id, name: rootContext.name },
    subContexts,
  };
};
