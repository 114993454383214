import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useTranslations } from '@brightdrop/localization-client';
import { cx } from '@emotion/css';
import { NavBarConfig } from '@gm-commercial/navbar-model';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { useAppDispatch } from '~/app/store';
import { SecureWrapper } from '~/common/components';
import useActingProfile from '~/common/hooks/useActingProfile';
import useSessionId from '~/common/hooks/useSessionId';
import { FLEET_SWITCHER_FLAG_NAME } from '~/common/models/featureFlags.model';
import { getDefaultRoutePath } from '~/common/utils/route/route.utils';
import { isNavListItem } from '~/features/navigationDrawer/config/legacy.navDrawer.config';
import {
  getAppNameFromPath,
  getNavDrawerConfig,
} from '~/features/navigationDrawer/config/navDrawer.config';
import NavDrawerItem from '~/features/navigationDrawer/NavDrawerItem';
import {
  selectActingPermissionsContextIds,
  selectActingProfileFirstName,
  selectActingProfileId,
  selectActingProfileLastName,
  selectActingProfileOrganization,
  selectActingProfileStatus,
  selectActingRole,
} from '~/features/profile/profileSlice.selectors';

import CloseDrawerIcon from '../../assets/icons/closeDrawerIcon.svg?react';
import FleetSwitcherContainer from '../fleets/switcher/FleetSwitcherContainer';
import { VIEW_AS_BANNER_HEIGHT } from '../header/components/ViewAsHeader/ViewAsHeader.styles';
import { HEADER_HEIGHT } from '../header/GlobalHeader.styles';
import { NAV_DRAWER_MESSAGES } from './GlobalNavDrawer.messages';
import useStyles, {
  NAV_DRAWER_CLOSED_WIDTH,
  NAV_DRAWER_OPEN_WIDTH,
} from './GlobalNavDrawer.styles';

// Exported for use in global wrapper to ensure proper spacing of content below header
/**
 * Used to
 *
 * Exported for use in global wrapper to ensure proper spacing of content below header.
 *
 * @see https://v5.mui.com/material-ui/react-app-bar/#fixed-placement
 */
export const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActing',
})<{ isActing: boolean }>(({ theme, isActing }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // adjust spacer height when ViewAsHeader is being rendered
  ...(isActing && {
    height: `${HEADER_HEIGHT + VIEW_AS_BANNER_HEIGHT}px`,
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: `${NAV_DRAWER_OPEN_WIDTH}px`,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `${NAV_DRAWER_CLOSED_WIDTH}px`,
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  width: NAV_DRAWER_OPEN_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const selectActingProfileParams = createSelector(
  selectActingProfileId,
  selectActingProfileFirstName,
  selectActingProfileLastName,
  selectActingRole,
  selectActingProfileStatus,
  selectActingProfileOrganization,
  selectActingPermissionsContextIds,
  (usersId, firstName, lastName, role, status, organization, contextIds) => {
    return {
      usersId,
      firstName,
      lastName,
      role,
      status,
      organization,
      ...contextIds,
    };
  }
);

/**
 * Removes any config items or children with an empty path.
 * @param {NavBarConfig} config - The config object to sanitize
 */
const sanitizeConfig = (config: NavBarConfig): NavBarConfig => {
  return {
    ...config,
    items: config.items.filter((item) => {
      // Check if the item is enabled
      if (item.path === '') {
        return false;
      }

      // Recursively filter children if they exist
      if (item.children) {
        item.children = sanitizeConfig({
          items: item.children,
        }).items;
      }

      return true;
    }),
  };
};

const GlobalNavDrawer = ({
  navExpanded,
  toggleDrawer,
  onNavItemClick,
  toggleable = true,
}: {
  navExpanded: boolean;
  toggleDrawer: () => void;
  onNavItemClick?: () => void;
  toggleable?: boolean;
}) => {
  const { isActing } = useActingProfile();
  const { classes } = useStyles({ isActing });
  const { translations } = useTranslations(NAV_DRAWER_MESSAGES);
  const actingProfileParams = useSelector(selectActingProfileParams);
  const { pathname } = useLocation();

  const { getFlag } = useFeatureFlags();
  const appName = getAppNameFromPath(pathname);
  const dispatch = useAppDispatch();
  const sessionId = useSessionId();
  const defaultRoutePath = actingProfileParams?.role
    ? getDefaultRoutePath(
        actingProfileParams?.role,
        actingProfileParams,
        getFlag
      )
    : '/';

  const navConfig = useMemo(
    () =>
      sanitizeConfig(
        getNavDrawerConfig({
          appName,
          messages: translations,
          profileParams: actingProfileParams,
          sessionId,
          dispatch,
          findFlagScope: getFlag,
        })
      ),
    [dispatch, appName, translations, sessionId, actingProfileParams, getFlag]
  );

  const showFleetSwitcher = getFlag(FLEET_SWITCHER_FLAG_NAME);

  return (
    <>
      {!!navConfig?.items.length && (
        <StyledDrawer
          id="nav-drawer-menu"
          variant="permanent"
          open={navExpanded}
          className={cx(classes.drawer, {
            [classes.drawerOpen]: !!navExpanded,
            [classes.drawerClose]: !navExpanded,
          })}
          classes={{
            paper: cx({
              [classes.drawerOpen]: !!navExpanded,
              [classes.drawerClose]: !navExpanded,
            }),
          }}
        >
          <DrawerHeader className={classes.logoContainer} isActing={isActing}>
            <Box>
              <Link to={defaultRoutePath || '/'}>
                <Box
                  className={cx('logo', { isOpen: navExpanded })}
                  data-testid="bd-logo"
                  id="bd-logo"
                />
              </Link>
              {
                <IconButton
                  className={cx('menu', { isOpen: navExpanded })}
                  onClick={toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              }
            </Box>
            <IconButton
              className={cx('toggle', { isOpen: navExpanded })}
              onClick={toggleDrawer}
            >
              <CloseDrawerIcon />
            </IconButton>
          </DrawerHeader>
          {showFleetSwitcher && (
            <>
              <FleetSwitcherContainer navExpanded={navExpanded} />
              <Divider sx={{ marginBottom: '16px' }} />
            </>
          )}
          {navConfig.title && (
            <Box
              className={cx(classes.titleContainer, { isOpen: navExpanded })}
              data-testid="nav-title"
            >
              <Typography variant="h3_deprecated">{navConfig.title}</Typography>
            </Box>
          )}
          <List className={classes.navDrawerList}>
            {navConfig.items.map((item, index) => {
              // SecureWrapper is (currently) only used to control visibility for legacy nav links only
              if (isNavListItem(item)) {
                return (
                  <SecureWrapper
                    requiredPermissions={item.permissions}
                    excludedRoles={item.excludedRoles}
                    requiredRoles={item.requiredRoles}
                    key={`${item.label}-${index}`}
                  >
                    <NavDrawerItem
                      key={`menu-item-${item.testId}`}
                      {...item}
                      isNavDrawerOpen={navExpanded}
                      callback={onNavItemClick}
                    />
                  </SecureWrapper>
                );
              }
              return (
                <NavDrawerItem
                  key={`menu-item-${item.testId}`}
                  {...item}
                  isNavDrawerOpen={navExpanded}
                  callback={onNavItemClick}
                />
              );
            })}
          </List>
          {toggleable && (
            <Box className={cx(classes.drawerToggle, { isOpen: navExpanded })}>
              <Divider />
              <Box>
                <IconButton
                  onClick={toggleDrawer}
                  id={navExpanded ? 'nav-bar-close-btn' : 'nav-bar-open-btn'}
                  data-testid={
                    navExpanded ? 'nav-bar-close-btn' : 'nav-bar-open-btn'
                  }
                >
                  <CloseDrawerIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </StyledDrawer>
      )}
    </>
  );
};

export default GlobalNavDrawer;
