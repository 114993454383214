export enum AuthenticationType {
  SSO = 'sso',
  CDI = 'cdi',
}

export interface AuthenticationConfig {
  authenticationType: AuthenticationType;
}

export const DEFAULT_AUTHENTICATION_TYPE = AuthenticationType.CDI;
