import { createSelector } from 'reselect';

import { selectAuthState } from './authSlice';

export const selectAuthToken = createSelector(
  selectAuthState,
  (authState) => authState.authToken
);

export const selectAuthTokenExpiration = createSelector(
  selectAuthToken,
  (authToken) => authToken?.expiration
);

export const selectAuthB2CTokenExpiration = createSelector(
  selectAuthToken,
  (authToken) => authToken?.b2cExpiration
);

export const selectShortestExpiration = createSelector(
  selectAuthTokenExpiration,
  selectAuthB2CTokenExpiration,
  (expiration, b2cExpiration) =>
    expiration && b2cExpiration
      ? Math.min(expiration, b2cExpiration)
      : expiration || b2cExpiration
);

export const selectAuthTokenValue = createSelector(
  selectAuthToken,
  (authToken) => authToken?.value
);
export const selectAuthType = createSelector(
  selectAuthToken,
  (authToken) => authToken?.authenticationType
);

export const selectAuthDomainHint = createSelector(
  selectAuthState,
  (authState) => authState?.domainHint
);

export const selectLoginAttempt = createSelector(
  selectAuthState,
  (authState) => authState.loginAttempt
);
export const selectForceLogout = createSelector(
  selectAuthState,
  (authState) => authState.forceLogout
);

export const selectFirstSignIn = createSelector(
  selectAuthState,
  (authState) => authState?.firstSignIn
);
