import { InteractionRequiredAuthError } from '@azure/msal-common';
import {
  AnyAction,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { RootState } from '../../../app/rootReducer';
import { doDelete, doGet, doPatch, doPost, doPut } from '../../apis/apiBase';
import { mapError } from '../../mappers/error.mappers';
import {
  ApiActionConfig,
  ApiResponse,
  ThunkAPIConfig,
  ThunkCreatorConfig,
  ThunkPayloadCreatorParams,
  ThunkPayloadCreatorResult,
} from '../../models/apis/apiResponse.model';
import { BDError } from '../../models/error.model';

declare module 'axios' {
  export interface AxiosRequestConfig {
    bdExtra?: {
      getState?: () => RootState;
      dispatch?: ThunkDispatch<RootState, unknown, AnyAction>;
      authToken?: string;
      skipAuth?: boolean;
    };
    _retry?: boolean;
  }
}

type BuildRequestParams = { [k: string]: string | null | undefined };
type RequestParams = { [k: string]: string };

function buildRequestUrl(
  urlTemplate: string,
  params: BuildRequestParams = {}
): [string, RequestParams] {
  const remainingParams: RequestParams = {};
  Object.keys(params).forEach((k) => {
    const paramValue = params[k];
    if (params[k] && paramValue) {
      if (urlTemplate.indexOf(':' + k) > -1) {
        urlTemplate = urlTemplate.replace(':' + k, paramValue);
      } else {
        remainingParams[k] = paramValue;
      }
    }
  });

  return [urlTemplate, remainingParams];
}

export const buildAxiosConfig = (
  config: ThunkCreatorConfig,
  getState: () => RootState,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  skipAuth?: boolean
): AxiosRequestConfig => {
  const axiosConfig = Object.assign(
    {} as AxiosRequestConfig,
    config?.axiosConfig
  );
  return { ...axiosConfig, bdExtra: { getState, dispatch, skipAuth } };
};

export const makeGetPayloadCreator =
  <Returned, ThunkArg>({
    url,
    axiosOptions = { useDefaultHeaders: true } as ThunkCreatorConfig,
    argAdapter = (_: ThunkArg) => ({}) as ApiActionConfig,
    responseAdapter,
    errorAdapter = mapError,
    skipAuth = false,
  }: ThunkPayloadCreatorParams<Returned, ThunkArg>): ThunkPayloadCreatorResult<
    Returned,
    ThunkArg
  > =>
  (args, { getState, requestId, rejectWithValue, dispatch }) => {
    const actionConfig = argAdapter(args, getState());
    const [requestUrl, requestParams] = buildRequestUrl(
      typeof url === 'string' ? url : url(args, getState()),
      actionConfig?.requestParams || {}
    );
    const customAxiosOptions =
      typeof axiosOptions === 'function'
        ? axiosOptions(args, getState())
        : axiosOptions;

    if (customAxiosOptions.axiosConfig) {
      customAxiosOptions.axiosConfig.params = requestParams;
    } else {
      customAxiosOptions.axiosConfig = { params: requestParams };
    }

    const axiosConfig = buildAxiosConfig(
      customAxiosOptions,
      getState,
      dispatch,
      skipAuth
    );

    return doGet<Returned>(requestUrl, axiosConfig)
      .then((response) => {
        return responseAdapter
          ? responseAdapter(response.data, getState(), args)
          : response.data;
      })
      .catch(
        (
          err: AxiosError<Returned> | BDError | InteractionRequiredAuthError
        ) => {
          return rejectWithValue({
            requestId,
            ...errorAdapter(err, { state: getState() }),
          });
        }
      );
  };

export const makePostPayloadCreator =
  <Returned, ThunkArg>({
    url,
    axiosOptions = { useDefaultHeaders: true } as ThunkCreatorConfig,
    argAdapter = (_: ThunkArg) => ({}) as ApiActionConfig,
    responseAdapter,
  }: ThunkPayloadCreatorParams<Returned, ThunkArg>): AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    ThunkAPIConfig<Returned>
  > =>
  (args, { getState, requestId, rejectWithValue, dispatch }) => {
    const actionConfig = argAdapter(args, getState());
    const [requestUrl, requestParams] = buildRequestUrl(
      typeof url === 'string' ? url : url(args, getState()),
      actionConfig?.requestParams || {}
    );
    const customAxiosOptions =
      typeof axiosOptions === 'function'
        ? axiosOptions(args, getState())
        : axiosOptions;

    if (customAxiosOptions.axiosConfig) {
      customAxiosOptions.axiosConfig.params = requestParams;
    } else {
      customAxiosOptions.axiosConfig = { params: requestParams };
    }

    const axiosConfig = buildAxiosConfig(
      customAxiosOptions,
      getState,
      dispatch
    );

    return doPost<Returned>(
      requestUrl,
      actionConfig?.requestBody || null,
      axiosConfig
    )
      .then((response) =>
        responseAdapter
          ? responseAdapter(response.data, getState(), args)
          : response.data
      )
      .catch(
        (
          err: AxiosError<Returned> | BDError | InteractionRequiredAuthError
        ) => {
          return rejectWithValue({ ...mapError(err), requestId, data: args });
        }
      );
  };

export const makePutPayloadCreator =
  <Returned extends ApiResponse<unknown>, ThunkArg>({
    url,
    axiosOptions = { useDefaultHeaders: true } as ThunkCreatorConfig,
    argAdapter = (_: ThunkArg) => ({}) as ApiActionConfig,
    responseAdapter,
  }: ThunkPayloadCreatorParams<Returned, ThunkArg>): AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    ThunkAPIConfig<Returned>
  > =>
  (args, { getState, requestId, rejectWithValue, dispatch }) => {
    const actionConfig = argAdapter(args, getState());
    const [requestUrl, requestParams] = buildRequestUrl(
      typeof url === 'string' ? url : url(args, getState()),
      actionConfig?.requestParams || {}
    );
    const customAxiosOptions =
      typeof axiosOptions === 'function'
        ? axiosOptions(args, getState())
        : axiosOptions;

    if (customAxiosOptions.axiosConfig) {
      customAxiosOptions.axiosConfig.params = requestParams;
    } else {
      customAxiosOptions.axiosConfig = { params: requestParams };
    }

    const axiosConfig = buildAxiosConfig(
      customAxiosOptions,
      getState,
      dispatch
    );

    return doPut<Returned>(
      requestUrl,
      actionConfig?.requestBody || null,
      axiosConfig
    )
      .then((response) =>
        responseAdapter
          ? responseAdapter(response.data, getState(), args)
          : response.data
      )
      .catch(
        (
          err: AxiosError<Returned> | BDError | InteractionRequiredAuthError
        ) => {
          return rejectWithValue({ ...mapError(err), requestId, data: args });
        }
      );
  };

export const makePatchPayloadCreator =
  <Returned extends ApiResponse<unknown>, ThunkArg>({
    url,
    axiosOptions = { useDefaultHeaders: true } as ThunkCreatorConfig,
    argAdapter = (_: ThunkArg) => ({}) as ApiActionConfig,
    responseAdapter,
  }: ThunkPayloadCreatorParams<Returned, ThunkArg>): AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    ThunkAPIConfig<Returned>
  > =>
  (args, { getState, requestId, rejectWithValue, dispatch }) => {
    const actionConfig = argAdapter(args, getState());
    const [requestUrl, requestParams] = buildRequestUrl(
      typeof url === 'string' ? url : url(args, getState()),
      actionConfig?.requestParams || {}
    );
    const customAxiosOptions =
      typeof axiosOptions === 'function'
        ? axiosOptions(args, getState())
        : axiosOptions;

    if (customAxiosOptions.axiosConfig) {
      customAxiosOptions.axiosConfig.params = requestParams;
    } else {
      customAxiosOptions.axiosConfig = { params: requestParams };
    }

    const axiosConfig = buildAxiosConfig(
      customAxiosOptions,
      getState,
      dispatch
    );

    return doPatch<Returned>(
      requestUrl,
      actionConfig?.requestBody || null,
      axiosConfig
    )
      .then((response) =>
        responseAdapter
          ? responseAdapter(response.data, getState(), args)
          : response.data
      )
      .catch(
        (
          err: AxiosError<Returned> | BDError | InteractionRequiredAuthError
        ) => {
          return rejectWithValue({ ...mapError(err), requestId, data: args });
        }
      );
  };

export const makeDeletePayloadCreator =
  <Returned extends ApiResponse<unknown>, ThunkArg>({
    url,
    axiosOptions = { useDefaultHeaders: true } as ThunkCreatorConfig,
    argAdapter = (_: ThunkArg) => ({}) as ApiActionConfig,
  }: ThunkPayloadCreatorParams<Returned, ThunkArg>): AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    ThunkAPIConfig<Returned>
  > =>
  (args, { getState, requestId, rejectWithValue, dispatch }) => {
    const actionConfig = argAdapter(args, getState());
    const [requestUrl, requestParams] = buildRequestUrl(
      typeof url === 'string' ? url : url(args, getState()),
      actionConfig.requestParams
    );
    const customAxiosOptions =
      typeof axiosOptions === 'function'
        ? axiosOptions(args, getState())
        : axiosOptions;

    if (customAxiosOptions.axiosConfig) {
      customAxiosOptions.axiosConfig.params = requestParams;
    } else {
      customAxiosOptions.axiosConfig = { params: requestParams };
    }

    const axiosConfig = buildAxiosConfig(
      customAxiosOptions,
      getState,
      dispatch
    );

    return doDelete<Returned>(requestUrl, axiosConfig)
      .then((response) => response.data)
      .catch(
        (
          err: AxiosError<Returned> | BDError | InteractionRequiredAuthError
        ) => {
          return rejectWithValue({ ...mapError(err), requestId, data: args });
        }
      );
  };

export const makeThunk = <Returned, ThunkArg>(
  type: string,
  thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig<Returned>>
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig<Returned>> => {
  return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig<Returned>>(
    type,
    (async (arg: ThunkArg, thunkAPI: any) => {
      return await thunk(arg, thunkAPI);
    }) as any
  );
};

export const makeThunkCreatorConfig = ({
  timeout,
  retries = 0,
  delay = 0,
  customHeaders,
}: {
  timeout: number;
  retries?: number;
  delay?: number;
  customHeaders?: { [id: string]: string };
}): ThunkCreatorConfig => ({
  useDefaultHeaders: true,
  axiosConfig: {
    timeout,
    'axios-retry': {
      retries,
      retryDelay: () => delay,
    },
    headers: customHeaders,
  },
});
