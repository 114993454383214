import { WebPubSubClient } from '@azure/web-pubsub-client';
import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useEffect, useRef, useState } from 'react';

import { PUB_SUB_FLAG } from '~/common/models/featureFlags.model';

import type { Message } from './pubSub.model';
import { useFetchUrl } from './useFetchUrl';

const MAX_ATTEMPTS = 5;

type MessageLite = {
  id: string;
  read: boolean;
};

const usePubSubSocket = () => {
  const { url } = useFetchUrl();
  const [isConnected, setIsConnection] = useState(false);
  const [messages, setMessages] = useState<Array<MessageLite>>([]);
  const client = useRef<WebPubSubClient | null>(null);
  const { getFlag } = useFeatureFlags();
  const isPubSubEnabled = getFlag(PUB_SUB_FLAG);

  useEffect(() => {
    if (!url || !isPubSubEnabled) {
      return;
    }

    const handleConnection = async () => {
      try {
        // Create WebSocket client.
        client.current = new WebPubSubClient(url, {
          reconnectRetryOptions: {
            maxRetries: MAX_ATTEMPTS,
            mode: 'Exponential',
            maxRetryDelayInMs: 30000,
          },
        });
        await client.current.start();

        // Connection opened
        client.current.on('connected', () => {
          setIsConnection(true);
        });

        // Listen for messages
        client.current.on('server-message', (event) => {
          if (
            typeof event.message.data === 'object' &&
            'data' in event.message.data
          ) {
            // keep it light for now
            const message = event.message.data.data as Message;
            setMessages((prev) => {
              return [{ id: message.id, read: message.read }, ...prev];
            });
          }
        });

        client.current.on('disconnected', () => {
          setIsConnection(false);
        });
      } catch (e) {
        console.error('WebPubSubClient failed:', e);
      }
    };

    handleConnection();

    return () => {
      if (client.current) {
        client.current.stop();
      }
    };
  }, [url, isPubSubEnabled]);

  const setMessageRead = (id: string) => {
    if (!isPubSubEnabled) {
      return;
    }
    setMessages((prevState) => {
      const newState = prevState.filter((m) => {
        if (m.id === id) {
          return false;
        }
        // keep only unread
        return m.read === false;
      });
      return newState;
    });
  };

  const hasUnreadEvent = messages.some((m) => m.read === false);

  return { isConnected, setMessageRead, hasUnreadEvent };
};

export { usePubSubSocket };
