import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import {
  BDRequestStatus,
  BDRequestType,
} from '~/common/models/apis/apiResponse.model';

import { RootState } from '../../app/rootReducer';
import { AuthenticationType } from './model/authentication.model';

export interface AuthState {
  authToken: {
    value: string;
    expiration: number;
    b2cExpiration?: number;
    domainHint?: string;
    authenticationType?: AuthenticationType;
  } | null;
  operation: BDRequestType;
  status: BDRequestStatus;
  loginAttempt: number;
  forceLogout: boolean;
  domainHint?: string;
  authExpired?: boolean;
  firstSignIn?: boolean;
}

export const INITIAL_AUTH_STATE: AuthState = {
  authToken: null,
  operation: BDRequestType.UNKNOWN,
  status: BDRequestStatus.IDLE,
  loginAttempt: 0,
  forceLogout: false,
  firstSignIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_AUTH_STATE,
  reducers: {
    setAuthToken: (
      state,
      action: PayloadAction<{
        value: string;
        expiration: number;
        b2cExpiration?: number;
        domainHint?: string;
        authenticationType?: AuthenticationType;
      }>
    ) => {
      const { domainHint, ...authToken } = action.payload;
      state.operation = BDRequestType.AUTH;
      state.status = BDRequestStatus.SUCCEEDED;
      state.authToken = authToken;
      state.domainHint = domainHint;
      state.authExpired = false;
    },
    incrementLoginAttempts: (state) => {
      state.loginAttempt += 1;
    },
    enableForceLogout: (state) => {
      state.forceLogout = true;
    },
    setFirstSignIn: (state, action: PayloadAction<boolean>) => {
      state.firstSignIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = INITIAL_AUTH_STATE;
    });
  },
});

export const selectAuthState = (state: RootState): RootState['auth'] =>
  state.auth;

export const {
  setAuthToken,
  incrementLoginAttempts,
  enableForceLogout,
  setFirstSignIn,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
