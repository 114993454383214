import { Box, Paper, Skeleton, Tab, Tabs } from '@mui/material';
import { ReactNode } from 'react';

import useStyles from './BDTabOverview.styles';

interface TabContentProps<T> {
  label: string | ReactNode;
  type: T;
  value?: string | number | ReactNode;
}
interface BDTabOverviewProps<T> {
  contentConfig: TabContentProps<T>[];
  handleTabSelection: (newTab: T) => void;
  currentTab?: T | false;
  isLoading?: boolean;
}
const BDTabOverview = <T,>({
  contentConfig,
  handleTabSelection,
  currentTab,
  isLoading,
}: BDTabOverviewProps<T>): JSX.Element => {
  const { classes } = useStyles();

  const TabContent = ({
    content,
    isLoading,
  }: {
    content?: TabContentProps<T>;
    isLoading?: boolean;
  }): JSX.Element => {
    return (
      <>
        <Box className="tab">
          <Box>{content?.label}</Box>
          <Box mt={2}>
            <Box component="span" className="value">
              {isLoading ? (
                <Skeleton width={70} height={70} variant="text" />
              ) : (
                content?.value
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Paper className={classes.overviewWrapper}>
        {contentConfig && (
          <Tabs
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            variant="fullWidth"
            value={currentTab}
            onChange={(e, newTab) => handleTabSelection(newTab)}
            className={classes.tabs}
          >
            {contentConfig.map((item) => {
              return (
                <Tab
                  key={`${item.type}-tab`}
                  data-testid={`${item.type}-overview`}
                  label={<TabContent content={item} isLoading={isLoading} />}
                  value={item.type}
                />
              );
            })}
          </Tabs>
        )}
      </Paper>
    </>
  );
};
export default BDTabOverview;
