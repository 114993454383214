import i18n from 'i18next';
import { PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

const TranslationWrapper = (
  { language = 'en', children, messages } = {
    language: 'en',
    messages: {},
  } as PropsWithChildren<{
    language?: string;
    messages?: { [key: string]: string };
  }>
): JSX.Element => {
  const namespaces = Object.entries(messages || {}).reduce(
    (acc: { [key: string]: string }, [key, value]) => {
      const [namespace, keyName] = key.split(':');
      const current = acc[namespace] || {};
      return {
        ...acc,
        [namespace]: {
          ...current,
          [keyName]: value,
        },
      } as { [key: string]: string };
    },
    {} as { [key: string]: string }
  );
  Object.entries(namespaces).forEach(([namespace, bundle]) => {
    i18n.addResourceBundle(language, namespace, bundle, true, true);
  });
  if (Object.entries(namespaces).length) {
    i18n.changeLanguage(language);
  }
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default TranslationWrapper;
