import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { API_VERSION_DEFAULTS } from '~/common/apis/api.constants';
import { PUB_SUB_FLAG } from '~/common/models/featureFlags.model';
import { selectAuthToken } from '~/features/session/authSlice.selectors';

import type { TokenResponse } from './pubSub.model';

const MINUTE_55 = 1000 * 60 * 55;

const useFetchUrl = () => {
  const [url, setURL] = useState('');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const auth = useSelector(selectAuthToken);
  const { getFlag } = useFeatureFlags();
  const isPubSubEnabled = getFlag(PUB_SUB_FLAG);

  useEffect(() => {
    if (!auth?.value || !isPubSubEnabled) {
      return;
    }

    const getURL = async () => {
      try {
        // token valid for 1 hour
        const req = await fetch(
          `${globalThis.appConfig.apiBaseUrl}/communications/${API_VERSION_DEFAULTS.pubSubAPI}/pubSubToken`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${auth.value}`,
            },
          }
        );
        const res = (await req.json()) as TokenResponse;
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // url only good for one hour, refresh url every 55 minutes
        timeoutRef.current = setTimeout(() => {
          getURL();
        }, MINUTE_55);

        setURL(res?.result?.url ?? '');
      } catch (e) {
        console.error('Error getting URL:', e);
      }
    };

    getURL();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [auth?.value, isPubSubEnabled]);

  return { url };
};

export { useFetchUrl };
