import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  protectYourAccountWarningMessage: {
    color: theme.new.color.textSecondary,
  },

  buttonContainer: {
    alignSelf: 'end',
    display: 'flex',
    flexDirection: 'row',
    paddingInlineEnd: '16px',
  },

  checkBox: {
    '&.Mui-checked svg': {
      fill: theme.new.color.brandPrimary,
    },
  },
}));
export default useStyles;
