import type { FunctionComponent } from 'react';
import { createContext, useContext } from 'react';

import { usePubSubSocket } from './usePubSubSocket';

const PubSubContext = createContext<ReturnType<typeof usePubSubSocket>>({
  isConnected: false,
  setMessageRead: () => {},
  hasUnreadEvent: false,
});

const PubSubProvider: FunctionComponent = ({ children }) => {
  const value = usePubSubSocket();
  return (
    <PubSubContext.Provider value={value}>{children}</PubSubContext.Provider>
  );
};

const usePubSubContext = () => {
  return useContext(PubSubContext);
};

export { PubSubProvider, usePubSubContext };
