import { Flex } from '@brightdrop/bd-ui';
import { Tooltip as MuiTooltip, Typography } from '@mui/material';
import type { ReactElement, ReactNode } from 'react';

// Design spec: https://www.figma.com/design/tHuavQeTzVIG2XBlMfBhrh/Design-System-1.5?node-id=527-36094

interface TooltipProps {
  // Goes inside the tooltip
  content: string | JSX.Element;

  // Element that users interact with to trigger the tooltip
  trigger: ReactElement;

  // MUI has corner placements as well if we ever need them
  placement?: 'top' | 'bottom' | 'left' | 'right';

  // The arrow will move around as needed to display properly
  showArrow?: boolean;

  // Optional title above the content
  title?: string;

  dataTestId?: string;

  // custom elements inside the tooltip
  customChildren?: ReactNode;
}

interface TooltipContentProps {
  // Goes inside the tooltip
  content: string | JSX.Element;

  // Optional title above the content
  title?: string;

  // To check if content has rendered
  dataTestId?: string;

  // custom elements inside the tooltip
  customChildren?: ReactNode;
}

const TooltipContent = ({
  title,
  content,
  dataTestId,
  customChildren,
}: TooltipContentProps) => {
  return (
    <Flex col gap={8} style={{ padding: 16 }} dataTestId={dataTestId}>
      {title ? (
        <Typography variant="small_body_medium">{title}</Typography>
      ) : null}
      {content && !customChildren ? (
        <Typography variant="small_body_regular">{content}</Typography>
      ) : null}
      {customChildren ? customChildren : null}
    </Flex>
  );
};

// The first number is perpendicular offset for the arrow
// The second number is the gap between the tooltip and the trigger
const OFFSET = [0, -8];
const autoHideDuration = 5000;
const Tooltip = ({
  placement = 'top',
  showArrow,
  title,
  content,
  trigger,
  dataTestId,
  customChildren,
}: TooltipProps) => {
  return (
    <MuiTooltip
      leaveTouchDelay={autoHideDuration}
      enterTouchDelay={0} // this will enable the info to appear on a click in touch devices
      title={
        <TooltipContent
          title={title}
          content={content}
          dataTestId={dataTestId}
          customChildren={customChildren}
        />
      }
      placement={placement}
      arrow={showArrow}
      componentsProps={{
        tooltip: {
          sx: {
            '& .MuiTooltip-arrow': {
              left: '50%',
              transform: 'translateX(-50%)',
            },
          },
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: OFFSET,
              },
            },
          ],
        },
        tooltip: {
          sx: {
            padding: 0,
          },
        },
      }}
      data-testid={`${dataTestId}-trigger`}
    >
      {trigger}
    </MuiTooltip>
  );
};

export default Tooltip;
