import {
  COLOR_PALETTE,
  DESIGN_SYSTEM_COLORS,
} from '@brightdrop/mui-theme-override';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    svg: {
      filter:
        'brightness(0) saturate(100%) invert(4%) sepia(5%) saturate(4095%) hue-rotate(159deg) brightness(97%) contrast(94%)',
    },
    alignItems: 'center',
    columnGap: '16px',
    minHeight: '56px',
    marginBottom: '8px',
    padding: '16px 24px',
    position: 'relative',
    '&.Mui-disabled': {
      opacity: '1',
      pointerEvents: 'none',
    },
    '&.drawerToggleLink': {
      minHeight: '64px',
      height: '64px',
      cursor: 'pointer',
    },
    '&.contentItem': {
      display: 'none',
      flexDirection: 'column',
      '&.isNavDrawerOpen': {
        display: 'flex',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    '&:hover': {
      backgroundColor: theme.new.color.brand[100],
    },
    '&:focus-visible': {
      outlineOffset: '1px',
      outline: `2px auto ${theme.new.color.brandPrimary}`,
    },
  },
  nestingContainer: {
    '.MuiListItem-root.Mui-selected': {
      backgroundColor: 'transparent',
      padding: '0',
    },
    padding: '0',
    '.nestingContainerTitle': {
      borderRadius: '0',
      display: 'flex',
      height: '56px',
      justifyContent: 'space-between',
      padding: '16px 24px',
      width: '100%',
      '.nestingContainerTitleIconText': {
        alignItems: 'center',
        columnGap: '16px',
        width: '100%',
        button: {
          marginInlineStart: 'auto',
        },
      },
      '&:hover': {
        backgroundColor: theme.new.color.brand[100],
      },
      '&:focus-visible': {
        outlineOffset: '1px',
        outline: `2px auto ${theme.new.color.brandPrimary}`,
      },
      '&.parentClickable': {
        //the actual link should be the only thing that is clickable (i.e. has a cursor pointer)
        '.nestingContainerTitleIconText p[class$="itemText"]': {
          cursor: 'pointer',
        },
      },
    },
    '&::before': {
      display: 'none',
    },
    a: {
      borderRadius: '8px',
      padding: '12px 40px',
      '&::before': {
        display: 'none',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.active .nestingContainerTitle::before': {
      borderEndEndRadius: '100px',
      borderStartEndRadius: '100px',
      borderRight: `4px solid ${theme.palette.brand.main}`,
      bottom: '0',
      content: '" "',
      height: '56px',
      left: '0',
      position: 'absolute',
      width: '4px',
    },
  },
  nestingContainerArrow: {
    padding: '0',
    'svg.noFill path': {
      stroke: theme.palette.text.primary,
    },
  },
  itemIcon: {
    alignItems: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    height: '24px',
    justifyContent: 'center',
    minWidth: '24px',
  },
  itemText: {
    cursor: 'pointer',
    fontSize: '1rem',
    '&.contentItem': {
      fontFamily: 'interMedium, "Open Sans", Arial, sans-serif',
    },
  },
  contentNavDrawerItemContainer: {
    padding: '0 24px',
    width: '100%',
    a: {
      marginBottom: '0px',
    },
    'a[class$="activeItem"].Mui-selected': {
      backgroundColor: DESIGN_SYSTEM_COLORS.brand[200],
      padding: '12px 40px',
      'p[class$="itemText"]': {
        color: theme.new.color.brand[600],
      },
    },
    '&.collapsedHover': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '10px',
      border: '4px solid transparent',
      boxShadow: `0px 4px 12px 0px ${COLOR_PALETTE.light.lightGray}`,
      insetInlineStart: '100%',
      padding: '8px',
      position: 'absolute',
      minWidth: '175px',
      width: 'fit-content',
      'a[class$="listItem"]': {
        borderRadius: '10px',
        justifyContent: 'flex-start',
        padding: '10px',
        width: '100%',
      },
      'a[class$="activeItem"].Mui-selected': {
        borderRadius: '10px',
        padding: '10px',
        width: '100%',
      },
    },
  },
  activeItem: {
    'svg, div[class$="itemIcon"] svg': {
      filter:
        'brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(1672%) hue-rotate(190deg) brightness(90%) contrast(101%)',
    },
    '.MuiTypography-root.MuiTypography-body1': {
      fontFamily: 'interMedium, "Open Sans", Arial, sans-serif',
    },
    '.nestingContainerTitleIconText p[class$="itemText"]': {
      color: theme.new.color.brand[600],
      cursor: 'default',
    },
    '&::before': {
      borderEndEndRadius: 100,
      borderStartEndRadius: 100,
      borderRight: `4px solid ${theme.palette.brand.main}`,
      content: '" "',
      height: 56,
      left: 0,
      position: 'absolute',
      top: 0,
      width: 4,
    },
    '&.MuiListItem-root p[class$="itemText"]': {
      color: theme.new.color.brand[600],
    },
  },
  toggleIcon: {
    height: 'auto',
    justifyContent: 'flex-end',
    minWidth: 16,
    padding: '5px 3px',
    '&:focus-visible': {
      borderRadius: 0,
      outlineOffset: '1px',
      outline: `2px auto ${theme.new.color.brandPrimary}`,
    },
  },
}));

export default useStyles;
