import { createContext } from 'react';

export interface FeatureFlagsProviderContextProps<T> {
  ready: boolean;
  setContext: (name: string, value: T) => void;
}

const FeatureFlagsProviderContext = createContext<
  FeatureFlagsProviderContextProps<any>
>({
  ready: false,
  setContext: () => {},
});

export default FeatureFlagsProviderContext;
