import {
  Skeleton,
  Typography,
  type TypographyPropsVariantOverrides,
} from '@mui/material';
import type { Variant } from '@mui/material/styles/createTypography';
import { type OverridableStringUnion } from '@mui/types';

import { Card } from '../Card';
import { Flex } from '../Flex';

const BDSkeleton = ({
  variant,
  width,
}: {
  variant: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  width: number | string;
}) => {
  return (
    <Typography variant={variant}>
      <Skeleton variant="rounded" width={width} style={{ borderRadius: 32 }} />
    </Typography>
  );
};

const SkeletonCard = ({
  isHeader,
  dataTestId,
}: {
  isHeader?: boolean;
  dataTestId?: string;
}) => {
  if (isHeader)
    return (
      <Flex
        gap={32}
        center
        style={{
          padding: '24px 16px',
        }}
        dataTestId={dataTestId}
      >
        <Skeleton
          variant="rounded"
          width={100}
          height={100}
          style={{ borderRadius: 16 }}
        />
        <Flex col gap={16}>
          <BDSkeleton variant="header2_medium" width={350} />
          <BDSkeleton variant="body_medium" width={250} />
        </Flex>
      </Flex>
    );

  return (
    <Card dataTestId={dataTestId}>
      <Flex
        gap={64}
        col
        style={{
          flexGrow: 1,
          height: '100%',
          padding: '24px 16px',
        }}
      >
        <Flex col>
          <BDSkeleton variant="header2_medium" width="55%" />
        </Flex>

        <Flex col gap={16}>
          <BDSkeleton variant="body_medium" width="60%" />
          <BDSkeleton variant="body_medium" width="90%" />
          <BDSkeleton variant="body_medium" width="80%" />
          <BDSkeleton variant="body_medium" width="40%" />
        </Flex>
      </Flex>
    </Card>
  );
};

export default SkeletonCard;
