import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import {
  numberSortCompare,
  stringSortCompare,
} from '~/common/components/table/utils/table.utils';
import {
  DATE_TIME_API_FORMAT_COMPLETE,
  DEFAULT_EMPTY_VALUE,
} from '~/common/constants/common.constant';
import { TimeUnit } from '~/common/models/common.model';
import {
  SingleUtilizationDetail,
  TimePeriodUtilizationSummary,
  UtilizationSummaries,
} from '~/common/models/utilization.model';
import {
  endDateTime,
  formatDateForAPI,
  sub,
} from '~/common/utils/date-time.utils';

import {
  DEFAULT_TIME_PERIOD,
  DEFAULT_UNIT_SUMMARY,
  DEFAULT_UTILIZATION_START_DATE,
  MILLISECOND,
  NINETY_TIME_PERIOD,
} from '../utilization.constant';
import { UtilizationParams } from '../utilizationSlice';

export const DEFAULT_DISTANCE_THRESHOLD_KM = 2;
export const DEFAULT_DISTANCE_THRESHOLD_MI = 1.25;

export const mapUtilizationApiResponse = (
  response: Partial<ApiUtilizationSummaries>,
  numberOfTimePeriods = DEFAULT_TIME_PERIOD,
  onlyUseMatchedDates = false
): UtilizationSummaries => {
  const currentDate = DateTime.now();
  const lastDate = DateTime.fromObject({
    year: currentDate.year,
    month: currentDate.month,
    day: currentDate.day,
  });
  const firstDate = lastDate.plus({ days: -1 * numberOfTimePeriods });
  const responseDays = Array.isArray(response.days)
    ? response.days
        .map((res) => {
          if (!isEmpty(res?.day)) {
            return res;
          }
        })
        .sort((a, b) =>
          numberSortCompare(
            'asc',
            new Date(a?.day as string)?.getTime(),
            new Date(b?.day as string)?.getTime()
          )
        ) || []
    : [];
  const newTimeUnitSummaries = [];
  const utilizedDates = [];
  let daysOfOperation = 0;
  let apiResDaysIndex = 0;
  for (let index = 0; index < numberOfTimePeriods; index++) {
    const indexedDate = firstDate.plus({ days: index }).toISO();
    const responseDaysEntry = responseDays[apiResDaysIndex];
    const matched =
      responseDaysEntry &&
      DateTime.fromISO(responseDaysEntry.day).toISO() === indexedDate
        ? responseDaysEntry
        : undefined;
    apiResDaysIndex = matched ? apiResDaysIndex + 1 : apiResDaysIndex;
    if (matched) {
      utilizedDates.push(matched.day);
      newTimeUnitSummaries.push({
        ...matched,
        date: matched.day,
        timeDriven: matched.driveTime * MILLISECOND || 0,
        distanceDriven: matched.distanceDriven || 0,
        batteryUsed:
          matched.batteryStart && matched.batteryEnd
            ? matched.batteryStart - matched.batteryEnd
            : undefined,
        rangeUsed:
          matched.estimatedRangeStart && matched.estimatedRangeEnd
            ? matched.estimatedRangeStart - matched.estimatedRangeEnd
            : undefined,
        daysDriven: matched.daysDriven || 0,
      });
      if (matched.daysDriven) {
        daysOfOperation++;
      }
    } else if (!onlyUseMatchedDates) {
      newTimeUnitSummaries.push({
        ...DEFAULT_UNIT_SUMMARY,
        date: indexedDate.toString().slice(0, -6),
      });
    }
  }
  return {
    timeUnitSummaries: newTimeUnitSummaries,
    assetSummaries: Array.isArray(response.assets)
      ? response.assets.map(
          ({ driveTime, id, distanceDriven, daysDriven, energyUsed }) => ({
            id,
            timeDriven: driveTime * MILLISECOND || 0,
            distanceDriven,
            daysDriven: daysDriven || 0,
            averageDistanceDriven: distanceDriven
              ? distanceDriven / (daysDriven || 1)
              : undefined,
            energyUsed,
            energyUsedRate:
              energyUsed && distanceDriven
                ? energyUsed / distanceDriven
                : undefined,
          })
        )
      : [],
    utilizedDates,
    daysOfOperation,
  };
};

export const mapUtilizationSummariesByAssetApiResponse = (
  response: Partial<ApiUtilizationSummaries>
): Partial<UtilizationSummaries> => {
  const responseDays = Array.isArray(response.days) ? response.days : [];
  const newTimeUnitSummaries = [];
  for (let index = 0; index < responseDays.length; index++) {
    const responseDaysEntry = responseDays[index];
    newTimeUnitSummaries.push({
      ...responseDaysEntry,
      date: responseDaysEntry.day,
      timeDriven: responseDaysEntry.driveTime * MILLISECOND || 0,
      distanceDriven: responseDaysEntry.distanceDriven || 0,
      batteryUsed:
        responseDaysEntry.batteryStart && responseDaysEntry.batteryEnd
          ? responseDaysEntry.batteryStart - responseDaysEntry.batteryEnd
          : undefined,
      rangeUsed:
        responseDaysEntry.estimatedRangeStart &&
        responseDaysEntry.estimatedRangeEnd
          ? responseDaysEntry.estimatedRangeStart -
            responseDaysEntry.estimatedRangeEnd
          : undefined,
      daysDriven: responseDaysEntry.daysDriven || 0,
    });
  }
  return {
    timeUnitSummaries: newTimeUnitSummaries,
  };
};

export const mapParamsToRequest = ({
  organizationsId,
  hubsId,
  fleetsId,
  assetsId,
  timePeriod,
  numberOfTimePeriods,
}: Partial<UtilizationParams> & {
  timePeriod: TimeUnit;
  numberOfTimePeriods: number;
}): ApiUtilizationSummariesRequest => ({
  organizationId: organizationsId,
  fleetIds: fleetsId ? [fleetsId] : [],
  hubIds: hubsId ? [hubsId] : [],
  assetIds: assetsId ? [assetsId] : [],
  endDate: formatDateForAPI(
    endDateTime({ date: sub(new Date(), { day: 1 }).toISOString() }),
    DATE_TIME_API_FORMAT_COMPLETE
  ),
  startDate: formatDateForAPI(
    endDateTime({
      date:
        numberOfTimePeriods <= NINETY_TIME_PERIOD
          ? sub(new Date(), {
              [timePeriod]: numberOfTimePeriods + 1,
            }).toISOString()
          : new Date(DEFAULT_UTILIZATION_START_DATE).toISOString(),
    }),
    DATE_TIME_API_FORMAT_COMPLETE
  ),
  timePeriod,
});

export const mapParamsToKey = ({
  organizationsId,
  hubsId,
  fleetsId,
  assetsId,
}: Partial<UtilizationParams>): string =>
  [organizationsId, hubsId, fleetsId, assetsId]
    .filter((item) => !!item)
    .join(DEFAULT_EMPTY_VALUE) || DEFAULT_EMPTY_VALUE;

export type ApiUtilizationSummaries = {
  days: {
    day: string;
    numberOfVehicles: number;
    driveTime: number;
    distanceDriven: number;
    energyUsed: number;
    energyUsedRate: number;
    batteryStart: number;
    batteryEnd: number;
    estimatedRangeStart: number;
    estimatedRangeEnd: number;
    odometerEnd: number;
    odometerStart: number;
    ignitionStart: string;
    ignitionEnd: string;
    daysDriven: number;
  }[];
  assets: {
    id: string;
    daysDriven: number;
    driveTime: number;
    distanceDriven: number;
    energyUsed: number;
  }[];
};
export type ApiUtilizationSummariesRequest = {
  assetIds: string[];
  fleetIds: string[];
  hubIds: string[];
  endDate: string;
  startDate: string;
  timePeriod: string;
  organizationId?: string;
};

export const mapUtilizationDetail = (
  data?: TimePeriodUtilizationSummary[]
): SingleUtilizationDetail[] => {
  return (
    data
      ?.map(
        ({ date, ...summary }) =>
          ({
            ...summary,
            date,
            id: date,
            name: date,
          }) as unknown as SingleUtilizationDetail
      )
      .sort(function (a, b) {
        const first = a.date.split('/').reverse().join('');
        const second = b.date.split('/').reverse().join('');
        return stringSortCompare('desc', first, second);
      }) || []
  );
};
