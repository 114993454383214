import { Box, Skeleton } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/app/store';
import {
  ContextItem,
  setSelectedContext,
} from '~/features/profile/profileSlice';
import {
  selectActingPermissionsContextIds,
  selectSelectedContext,
} from '~/features/profile/profileSlice.selectors';

import FleetSwitcher, { StaticFleetSwitcher } from './FleetSwitcher';
import { useAllowedContexts } from './hooks/useAllowedContexts';
import { transformAllowedContexts } from './utils/fleetSwitcher.utils';

/**
 * Handles data and shared app state related to the FleetSwitcher
 */
const FleetSwitcherContainer = ({ navExpanded }: { navExpanded: boolean }) => {
  const dispatch = useAppDispatch();
  const { allowedContexts, isLoading, isFailed } = useAllowedContexts();
  const actingPermissionsContextIds = useSelector(
    selectActingPermissionsContextIds
  );
  const transformedContexts = useMemo(() => {
    return transformAllowedContexts({
      allowedContexts,
      actingPermissionsContextIds,
    });
  }, [allowedContexts, actingPermissionsContextIds]);
  const selectedContext = useSelector(selectSelectedContext);

  useEffect(() => {
    if (!selectedContext && transformedContexts?.root) {
      dispatch(setSelectedContext(transformedContexts.root));
      return;
    }
    if (selectedContext?.id && transformedContexts?.root) {
      const matchesRoot = selectedContext.id === transformedContexts.root.id;
      if (matchesRoot) return;

      const matchesSubContext = transformedContexts.subContexts.some(
        (subContext) => subContext.id === selectedContext.id
      );
      if (matchesSubContext) return;

      dispatch(setSelectedContext(transformedContexts.root));
    }
  }, [dispatch, selectedContext, transformedContexts]);

  const handleContextSelection = (item: ContextItem) => {
    dispatch(setSelectedContext(item));
  };

  if (isLoading || !selectedContext) {
    return (
      <Box
        sx={{
          width: '100%',
          margin: '16px 0px',
          padding: '0px 8px',
          justifySelf: 'center',
        }}
      >
        <Skeleton
          data-testid="fleet-switcher-skeleton"
          variant="rounded"
          sx={{ height: '56px', width: '100%' }}
        />
      </Box>
    );
  }

  if (isFailed) {
    // TODO: improve error case handling
    return null;
  }

  if (!transformedContexts) {
    return null;
  }

  if (
    transformedContexts.root &&
    (!transformedContexts.subContexts ||
      transformedContexts.subContexts.length === 0)
  ) {
    return (
      <StaticFleetSwitcher
        navExpanded={navExpanded}
        selectedContext={selectedContext}
      />
    );
  }

  return (
    <FleetSwitcher
      contexts={{
        root: transformedContexts.root,
        subContexts: transformedContexts.subContexts,
      }}
      selectedContext={selectedContext}
      onContextSelect={handleContextSelection}
      navExpanded={navExpanded}
    />
  );
};

export default FleetSwitcherContainer;
