import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';

import { useAppDispatch } from '~/app/store';
import useGetAsyncPagedResults from '~/common/hooks/useGetAsyncPagedResults';
import useSessionId from '~/common/hooks/useSessionId';
import {
  AlertSessionViewType,
  DEFAULT_MAX_ALERTS_REQUEST_SIZE,
  getAlerts,
  markAlertAsRead,
  markAlertAsUnread,
  markAllAlertAsRead,
  setUnreadCount,
} from '~/features/alerts/alertsSlice';
import { usePubSubContext } from '~/features/header/components/NotificationBadge/Context/PubSubProvider';

import CSPlatformNotificationListView, {
  DisplayType,
  PlatformListProps,
} from './CSPlatformNotificationListView';
import { useStyles } from './CSPlatformNotificationListView.styles';

interface CSPlatformNotificationListWrapperProps
  extends RouteComponentProps<object, StaticContext, unknown> {
  insidePopover?: boolean;
  handleCloseNotification?: () => void;
}

// Define the ref type
interface CSPlatformNotificationListWrapperRef {
  isLoaded: () => boolean;
}

type StyleClasses = {
  titleWrapper: string;
  unreadItem: string;
  readItem: string;
  iconContainer: string;
  messageContainer: string;
  unReadIconContainer: string;
  badge: string;
  listItem: string;
  divider: string;
  skeleton: string;
  skeleton1: string;
  skeleton2: string;
  skeleton3: string;
  skeleton4: string;
  skeleton5: string;
};

const CSPlatformNotificationListWrapper =
  // eslint-disable-next-line react/display-name
  forwardRef<
    CSPlatformNotificationListWrapperRef,
    CSPlatformNotificationListWrapperProps
  >((props, ref) => {
    const { insidePopover, handleCloseNotification, ...rest } = props;
    const dispatch = useAppDispatch();
    const sessionId = useSessionId();
    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState<any[]>([]);
    const { classes } = useStyles();
    const { setMessageRead } = usePubSubContext();

    const dispatchGetAlerts = useCallback(
      (page) =>
        dispatch(
          getAlerts({
            sessionId,
            page,
            viewType: AlertSessionViewType.SUBSCRIPTION,
            rowsPerPage: DEFAULT_MAX_ALERTS_REQUEST_SIZE,
          })
        ),
      [dispatch, sessionId]
    );
    const { getAllResults } = useGetAsyncPagedResults({
      action: dispatchGetAlerts,
      rowsPerPage: DEFAULT_MAX_ALERTS_REQUEST_SIZE,
    });

    useEffect(() => {
      const fetchData = async () => {
        const results = await getAllResults();
        setListData(results || []);
        setLoading(false);
        const unreadCount = results?.filter((item) => !item.read).length || 0;

        // Determine if there are any unread notifications
        const hasUnread = unreadCount > 0;

        // Dispatch the action to update both hasUnread and approximateCount
        dispatch(setUnreadCount({ hasUnread, approximateCount: unreadCount }));
      };
      fetchData().finally(() => {});
    }, [getAllResults]);

    const handleMarkNotification = (alertId: string, read: boolean) => {
      if (read) {
        dispatch(markAlertAsRead({ alertId })).then(() => {
          setMessageRead(alertId);
          setListData((prevList) => {
            const value = prevList.map((item) =>
              item.id === alertId ? { ...item, read: true } : item
            );
            const unreadCount = value?.filter((item) => !item.read).length || 0;
            dispatch(
              setUnreadCount({
                hasUnread: unreadCount > 0,
                approximateCount: unreadCount,
              })
            );
            return value;
          });
        });
      } else {
        dispatch(markAlertAsUnread({ alertId })).then(() => {
          setListData((prevList) => {
            const value = prevList.map((item) =>
              item.id === alertId ? { ...item, read: false } : item
            );
            const unreadCount = value?.filter((item) => !item.read).length || 0;
            dispatch(
              setUnreadCount({
                hasUnread: unreadCount > 0,
                approximateCount: unreadCount,
              })
            );
            return value;
          });
        });
      }
    };

    const handleMarkAllAsRead = () => {
      dispatch(markAllAlertAsRead()).then(() => {
        setListData((prevList) =>
          prevList.map((item) => ({ ...item, read: true }))
        );
        dispatch(
          setUnreadCount({
            hasUnread: false,
            approximateCount: 0,
          })
        );
      });
    };

    useImperativeHandle(ref, () => ({ isLoaded: () => !loading }));

    if (loading) {
      return (
        <>
          {loading && !insidePopover && (
            <Box>
              <Skeleton
                data-testid={`LOADING-LIST-PRIMARY-TITLE-SKELETON`}
                variant="rectangular"
                className={classes.skeletonHeader}
                style={{
                  width: '140px',
                }}
              />
              <Skeleton
                data-testid={`LOADING-LIST-PRIMARY-TABBAR-SKELETON`}
                variant="rectangular"
                className={classes.skeletonHeader}
                style={{
                  width: '220px',
                  height: '20px',
                  borderRadius: '12px',
                }}
              />
            </Box>
          )}
          <List
            className={classes.listWrapper}
            data-testid="LOADING-LIST-WRAPPER"
          >
            {loading &&
              Array.from(new Array(3)).map((_, index) => (
                <ListItem key={index}>
                  <ListItemAvatar className={classes.readItem}>
                    <Skeleton
                      id={'LOADING-LIST-AVATAR-SKELETON'}
                      variant={'rounded'}
                      style={{
                        height: '44px',
                        width: '44px',
                        borderRadius: '12px',
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    data-testid={`CSPlatformListItemText${index}`}
                    primary={
                      <Skeleton
                        data-testid={`LOADING-LIST-PRIMARY-TEXT-SKELETON${index}`}
                        variant="rectangular"
                        style={{
                          width: '99%',
                          height: '20px',
                          borderRadius: '12px',
                        }}
                        className={`${classes.skeleton} ${
                          classes[`skeleton${index + 1}` as keyof StyleClasses]
                        }`}
                      />
                    }
                    secondary={
                      <Skeleton
                        data-testid={`LOADING-LIST-SECONDARY-TEXT-SKELETON${index}`}
                        variant="rectangular"
                        style={{
                          width: '20%',
                          height: '20px',
                          marginTop: '8px',
                          borderRadius: '12px',
                        }}
                        className={`${classes.skeleton} ${
                          classes[`skeleton${index + 1}` as keyof StyleClasses]
                        }`}
                      />
                    }
                  />
                </ListItem>
              ))}
          </List>
        </>
      );
    }

    const platformListProps: PlatformListProps = {
      hasPermission: true,
      listData,
      show: DisplayType.All,
      handleMarkNotification,
      handleMarkAllAsRead,
      insidePopover: insidePopover,
    };

    return (
      <CSPlatformNotificationListView
        {...platformListProps}
        insidePopover={insidePopover}
        handleCloseNotification={() =>
          handleCloseNotification ? handleCloseNotification() : undefined
        }
        {...rest}
      />
    );
  });

export default CSPlatformNotificationListWrapper;
