import { Paper } from '@mui/material';
import type { CSSProperties, ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  style?: CSSProperties;
  dataTestId?: string;
  className?: string;
}

const Card = ({ children, style, dataTestId, className }: CardProps) => {
  const defaultStyle: CSSProperties = {
    padding: 16,
    lineHeight: 1,
    border: 'none',
    borderRadius: 12,
    height: '100%',
    ...style,
  };

  return (
    <Paper
      style={defaultStyle}
      data-testid={dataTestId}
      elevation={1}
      className={className}
    >
      {children}
    </Paper>
  );
};

export default Card;
