import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/app/store';
import useDelayedResult from '~/common/hooks/useDelayedResult';
import { getAllowedContexts } from '~/features/profile/profileSlice';
import {
  allowedContextOperationSelector,
  selectActingPermissionsContextIds,
  selectAllowedContexts,
} from '~/features/profile/profileSlice.selectors';

export const useAllowedContexts = () => {
  const dispatch = useAppDispatch();
  const actingPermissionsContextIds = useSelector(
    selectActingPermissionsContextIds
  );
  const {
    result: allowedContexts,
    isLoading,
    isFailed,
  } = useDelayedResult(selectAllowedContexts, allowedContextOperationSelector);

  useEffect(() => {
    const { organizationsId, hubsId, fleetsId } =
      actingPermissionsContextIds || {};
    if (organizationsId) {
      dispatch(getAllowedContexts({ organizationsId, hubsId, fleetsId }));
    }
  }, [dispatch, actingPermissionsContextIds]);
  return { allowedContexts, isLoading, isFailed };
};
