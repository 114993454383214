export enum AppEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  UAT = 'uat',
}

export type AppConfig = {
  rawConfig: BDEnvironment | undefined;
  env: AppEnv;
  version: string;
  apiBaseUrl: string;
  mapKey: string;
  instrumentationKey: string;
  instrumentationEnv: string;
  instrumentationAppId: string;
  instrumentationClientToken: string;
  featureFlagProviderKey: string;
  auth: {
    authority: string;
    cdiAuthority: string;
    knownAuthority: string;
    clientId: string;
    redirectUri: string;
    staticLoginRedirectUri: string;
    cdiLogoutBaseUrl: string;
    maxAge: string;
  };
  servicePortalUrl: string;
  mfs: {
    supportEntryUrl: string;
    businessEnrollmentEntryUrl: string;
  };
};

export type BDEnvironment = {
  REACT_APP_ENV?: string;
  REACT_APP_INSTRUMENTATION_KEY?: string;
  REACT_APP_INSTRUMENTATION_ENV?: string;
  REACT_APP_INSTRUMENTATION_APP_ID?: string;
  REACT_APP_INSTRUMENTATION_CLIENT_TOKEN?: string;
  REACT_APP_API_BASE_URL?: string;
  REACT_APP_AZURE_AUTH_TOKEN_URL?: string;
  REACT_APP_AZURE_AUTHORITY?: string;
  REACT_APP_AZURE_CDI_AUTHORITY?: string;
  REACT_APP_AZURE_CLIENT_ID?: string;
  REACT_APP_AZURE_KNOWN_AUTHORITY?: string;
  REACT_APP_AZURE_REDIRECT_URI?: string;
  REACT_APP_MAP_KEY?: string;
  REACT_APP_VERSION?: string;
  REACT_APP_SERVICE_PORTAL_URL?: string;
  REACT_APP_LAUNCH_DARKLY_KEY?: string;
  MF_SUPPORT_ENTRY_URL?: string;
  MF_BUSINESS_ENROLLMENT_ENTRY_URL?: string;
  REACT_APP_STATIC_LOGIN_REDIRECT_PAGE?: string;
  REACT_APP_CDI_LOGOUT_BASE_URL?: string;
  REACT_APP_AZURE_MAX_AGE?: string;
};
