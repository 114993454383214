import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  menuTopSection: {
    borderTop: `1px solid ${theme.new.color.line.hairline}`,
  },
  menuBottomSection: {
    borderTop: `1px solid ${theme.new.color.line.hairline}`,
  },
  menuItem: {
    fontFamily: theme.typography.body_regular.fontFamily,
    fontSize: theme.typography.body_regular.fontSize,
    fontWeight: theme.typography.body_regular.fontWeight,
    lineHeight: theme.typography.body_regular.lineHeight,
  },
}));

export default useStyles;
