import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  AssetDiagnostics,
  AssetFlags,
  AssetOperationalStatus,
  AssetTelemetry,
  CapacityUnit,
  DiagnosticSeverity,
  DistanceUnit,
  EnergyEfficiency,
  FuelEconomyUnit,
  SpeedUnit,
  TelemetryStatus,
  TemperatureUnit,
  TirePressureUnit,
} from '~/common/models/asset.model';
import { isAfter } from '~/common/utils/date-time.utils';

export const DISTANCE_CONVERSIONS = {
  [DistanceUnit.KM]: {
    [DistanceUnit.MI]: 0.621371,
  },
  [DistanceUnit.MI]: {
    [DistanceUnit.KM]: 1.60934,
  },
};

export const ENERGY_EFFICIENCY_CONVERSIONS = {
  [EnergyEfficiency.KM]: {
    [EnergyEfficiency.MI]: 1.60934,
  },
  [EnergyEfficiency.MI]: {
    [EnergyEfficiency.KM]: 0.621371,
  },
};

export const CAPACITY_CONVERSIONS = {
  [CapacityUnit.GALLON]: {
    [CapacityUnit.LITER]: 3.79,
  },
};

export const FUEL_ECONOMY_CONVERSIONS = {
  [FuelEconomyUnit.MPG]: {
    [FuelEconomyUnit.L100K]: 235.215,
  },
  [FuelEconomyUnit.L100K]: {
    [FuelEconomyUnit.MPG]: 235.215,
  },
};

export const TIRE_PRESSURE_CONVERSIONS: {
  [key in TirePressureUnit]?: { [key in TirePressureUnit]?: number };
} = {
  [TirePressureUnit.PSI]: {
    [TirePressureUnit.KPA]: 6.89476,
  },
};
export const SPEED_CONVERSION = {
  [SpeedUnit.MPH]: {
    [SpeedUnit.MPS]: 0.44704,
    [SpeedUnit.KPH]: 1.60934,
  },
};
export const convertMeasurement = <T extends string>(
  value: number,
  conversions: { [key in T]?: { [key in T]?: number } },
  fromUnit: T,
  toUnit: T,
  conversionFromUnitFormula: (value: number, conversion: number) => number = (
    value: number,
    conversion: number
  ) => value * conversion,
  conversionToUnitFormula: (value: number, conversion: number) => number = (
    value: number,
    conversion: number
  ) => value / conversion
): number => {
  if (fromUnit === toUnit) {
    return value;
  }
  const conversionsFromUnit = conversions[fromUnit];
  if (conversionsFromUnit) {
    const conversion = conversionsFromUnit[toUnit];
    if (conversion) {
      return conversionFromUnitFormula(value, Number(conversion));
    }
  }
  const conversionsToUnit = conversions[toUnit];
  if (conversionsToUnit) {
    const conversion = conversionsToUnit[fromUnit];
    if (conversion) {
      return conversionToUnitFormula(value, Number(conversion));
    }
  }
  return Number.NaN;
};

export const convertDistance = (
  value: number,
  fromUnit: DistanceUnit,
  toUnit: DistanceUnit
): number => {
  return convertMeasurement(value, DISTANCE_CONVERSIONS, fromUnit, toUnit);
};

export const convertEnergyEfficiency = (
  value: number,
  fromUnit: EnergyEfficiency,
  toUnit: EnergyEfficiency
): number => {
  return convertMeasurement(
    value,
    ENERGY_EFFICIENCY_CONVERSIONS,
    fromUnit,
    toUnit
  );
};

export const convertSpeed = (
  value: number,
  fromUnit: SpeedUnit,
  toUnit: SpeedUnit
): number => {
  return convertMeasurement(value, SPEED_CONVERSION, fromUnit, toUnit);
};

export const convertCapacity = (
  value: number,
  fromUnit: CapacityUnit,
  toUnit: CapacityUnit
): number => {
  return convertMeasurement(value, CAPACITY_CONVERSIONS, fromUnit, toUnit);
};

export const convertKmpLToL100km = (kilometersPerLiter: number) => {
  return kilometersPerLiter > 0 ? 100 / kilometersPerLiter : 0;
};

export const convertFuelEconomy = (
  value: number,
  fromUnit: FuelEconomyUnit,
  toUnit: FuelEconomyUnit
): number => {
  return value > 0
    ? convertMeasurement(
        value,
        FUEL_ECONOMY_CONVERSIONS,
        fromUnit,
        toUnit,
        (value: number, conversion: number) => conversion / value,
        (value: number, conversion: number) => value / conversion
      )
    : 0;
};

export const convertTirePressure = (
  value: number,
  fromUnit: TirePressureUnit,
  toUnit: TirePressureUnit
): number => {
  if (fromUnit === toUnit) {
    return Math.round(value);
  }
  return Math.round(
    convertMeasurement(value, TIRE_PRESSURE_CONVERSIONS, fromUnit, toUnit)
  );
};

export const convertTemperature = (
  value: number,
  fromUnit: TemperatureUnit
): number => {
  switch (fromUnit) {
    case TemperatureUnit.F:
      return Math.round((value - 32) / 1.8);
    case TemperatureUnit.C:
      return Math.round(value * 1.8 + 32);
    default:
      return value;
  }
};

export const getMaxDiagnosticFlagDate = (
  flags?: AssetDiagnostics
): Date | undefined => {
  const maxDate =
    !!flags &&
    Object.keys(flags || []).reduce((maxDate, key) => {
      const flag = flags[key as keyof typeof flags];
      if (flag) {
        const timestamp =
          flag.deviceTimestamp !== undefined
            ? new Date(flag.deviceTimestamp).getTime()
            : 0;
        return !maxDate
          ? timestamp
          : !timestamp
            ? maxDate
            : Math.max(maxDate, timestamp);
      }
      return maxDate;
    }, 0);
  return maxDate ? new Date(maxDate) : undefined;
};

export const getMaxTelemetryDate = (
  status?: AssetTelemetry
): Date | undefined => {
  const filteredDates = [
    status?.location?.deviceTimestamp,
    status?.batteryStatus?.battery?.deviceTimestamp,
    status?.batteryStatus?.charge?.deviceTimestamp,
    status?.chargingStatus?.deviceTimestamp,
    status?.diagnostics?.deviceTimestamp, // c_diag
    status?.lock?.deviceTimestamp,
    status?.door?.deviceTimestamp,
    status?.odometer?.deviceTimestamp,
    status?.estimatedRange?.deviceTimestamp,
    status?.speedStatus?.deviceTimestamp,
    status?.direction?.deviceTimestamp,
    status?.tirePressures?.leftFront?.deviceTimestamp,
    status?.tirePressures?.leftRear?.deviceTimestamp,
    status?.tirePressures?.rightFront?.deviceTimestamp,
    status?.tirePressures?.rightRear?.deviceTimestamp,
  ]
    .map((date) => (date ? new Date(date).getTime() : 0))
    .filter((date) => !!date);

  return filteredDates.length
    ? new Date(Math.max(...filteredDates))
    : undefined;
};

export const getOperationalStatusDurationDate = ({
  lastUpdated,
  operationalStatus,
  telemetry,
  flags,
}: {
  lastUpdated?: string;
  operationalStatus?: AssetOperationalStatus;
  telemetry?: AssetTelemetry;
  flags?: AssetFlags;
}): Date | undefined => {
  let lastStatusUpdate: Date | undefined;
  switch (operationalStatus) {
    case AssetOperationalStatus.OFFLINE: {
      const lastTelemetryUpdate = getMaxTelemetryDate(telemetry);
      const lastDiagnosticUpdate = getMaxDiagnosticFlagDate(flags?.diagnostics);
      lastStatusUpdate = lastUpdated
        ? new Date(lastUpdated)
        : lastTelemetryUpdate && lastDiagnosticUpdate
          ? new Date(
              Math.max(
                lastTelemetryUpdate.getTime(),
                lastDiagnosticUpdate.getTime()
              )
            )
          : lastTelemetryUpdate || lastDiagnosticUpdate;
      break;
    }
    case AssetOperationalStatus.MOVING: {
      const speedTimestamp = telemetry?.speedStatus?.deviceTimestamp;
      const directionTimestamp = telemetry?.direction?.deviceTimestamp;
      if (speedTimestamp && directionTimestamp) {
        const lastSpeedUpdate = new Date(speedTimestamp);
        const lastDirectionUpdate = new Date(directionTimestamp);
        lastStatusUpdate = isAfter(lastSpeedUpdate, lastDirectionUpdate)
          ? lastSpeedUpdate
          : lastDirectionUpdate;
        break;
      }
      lastStatusUpdate = speedTimestamp
        ? new Date(speedTimestamp)
        : directionTimestamp
          ? new Date(directionTimestamp)
          : undefined;
      break;
    }
    case AssetOperationalStatus.MOVING_NO_DIRECTION:
    case AssetOperationalStatus.STOPPED:
      lastStatusUpdate = telemetry?.speedStatus?.deviceTimestamp
        ? new Date(telemetry?.speedStatus?.deviceTimestamp)
        : undefined;
      break;
  }
  return lastStatusUpdate;
};

export const formatLabelWithUom = (
  value: string | number | undefined | null,
  uom: string | undefined
) => (value != null ? `${value} ${uom}` : '');

export const convertToLocalizedDecimalString = (value: number) => {
  return Number.isInteger(value)
    ? value.toLocaleString()
    : Number(value.toFixed(1)).toLocaleString();
};

export const getPercentageStatusAndLabel = (
  telemetryStatus?: TelemetryStatus<number>
) => {
  if (!telemetryStatus) return null;

  const value = telemetryStatus?.value;
  const severity = telemetryStatus?.severity || DiagnosticSeverity.UNKNOWN;
  const badgeLabel =
    value != null ? Math.round(value) + '%' : DEFAULT_EMPTY_VALUE;

  return {
    severity: severity,
    label: badgeLabel,
  };
};
