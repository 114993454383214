import { ReactElement } from 'react';

import { RouteParams } from '../configs/route.config';
import {
  ApiAsset,
  ApiAssetType,
  Asset,
  AssetDiagnostics,
  AssetModel,
  DiagnosticSeverity,
} from './asset.model';
import { CopyWithPartialOptional, Entity, PagedResult } from './common.model';
import { Fleet } from './fleet.model';
import { Hub } from './hub.model';
import { VehicleSubscriberType } from './notification.model';
import { Organization } from './organization.model';

export enum AlertsViewType {
  FEED = 'Feed',
  SUBSCRIPTIONS = 'Subscriptions',
  ALERTS_HISTORY = 'AlertsHistory',
}

export enum AlertType {
  LOW_BATTERY = 'lowBattery',
  LOW_BRAKE_FLUID = 'lowBrakeFluid',
  LOW_WASHER_FLUID = 'lowWasherFluid',
  LOW_TIRE_PRESSURE = 'lowTirePressure',
  FLAT_TIRE = 'flatTire',
  IMPAIRED_FAULT = 'impairedFault',
  CRITICAL_FAULT = 'criticalFault',
}

export enum AlertSubscriptionStatus {
  SUBSCRIBED = 'Subscribed',
  NOTSUBSCRIBED = 'Not Subscribed',
}

export enum DiagnosticEvent {
  AIRBAG_SYSTEM = 'airbag-system',
  ANTILOCK_BRAKING_SYSTEM = 'antilock-braking-system',
  EMISSION_SYSTEM = 'emission-system',
  ENGINE_AND_TRANSMISSION = 'engine-and-transmission',
  LITHIUM_ION_BATTERY = 'lithium-ion-battery',
  LOW_BATTERY = 'low-battery',
  LOW_BRAKE_FLUID = 'low-brake-fluid',
  LOW_WASHER_FLUID = 'low-washer-fluid',
  LOW_TIRE_PRESSURE = 'low-tire',
  FLAT_TIRE = 'flat-tire',
  STABILITY_CONTROL_SYSTEM = 'stability-control-system',
  BRAKE_PADS = 'brake-pads',
  OIL_LIFE = 'oil-life',
  ENGINE_AIR_FILTER = 'engine-air-filter',
  ONSTAR_SYSTEM = 'onstar-system',
  ELECTRIC_DRIVE_SYSTEM = 'electric-drive-system',
}

export enum AlertEvent {
  ACCOUNT_UPDATE = 'app-account-update',
  ADD_USER = 'app-add-user',
  REMOVE_USER = 'app-remove-user',
  ONBOARDING_VEHICLE = 'vehicle-onboarding',
  DEFAULT_DIAGNOSTICS = 'default-diagnostics',
  AIRBAG_SYSTEM = DiagnosticEvent.AIRBAG_SYSTEM,
  ANTILOCK_BRAKING_SYSTEM = DiagnosticEvent.ANTILOCK_BRAKING_SYSTEM,
  EMISSION_SYSTEM = DiagnosticEvent.EMISSION_SYSTEM,
  ENGINE_AND_TRANSMISSION = DiagnosticEvent.ENGINE_AND_TRANSMISSION,
  LITHIUM_ION_BATTERY = DiagnosticEvent.LITHIUM_ION_BATTERY,
  LOW_BATTERY = DiagnosticEvent.LOW_BATTERY,
  LOW_BRAKE_FLUID = DiagnosticEvent.LOW_BRAKE_FLUID,
  LOW_WASHER_FLUID = DiagnosticEvent.LOW_WASHER_FLUID,
  LOW_TIRE_PRESSURE = DiagnosticEvent.LOW_TIRE_PRESSURE,
  FLAT_TIRE = DiagnosticEvent.FLAT_TIRE,
  STABILITY_CONTROL_SYSTEM = DiagnosticEvent.STABILITY_CONTROL_SYSTEM,
  BRAKE_PADS = DiagnosticEvent.BRAKE_PADS,
  OIL_LIFE = DiagnosticEvent.OIL_LIFE,
  ENGINE_AIR_FILTER = DiagnosticEvent.ENGINE_AIR_FILTER,
  ONSTAR_SYSTEM = DiagnosticEvent.ONSTAR_SYSTEM,
  ELECTRIC_DRIVE_SYSTEM = DiagnosticEvent.ELECTRIC_DRIVE_SYSTEM,
}

export enum AlertCategory {
  PROFILE = 'profile',
  ASSET = 'asset',
  DIAGNOSTIC = 'diagnostic',
}

export enum AlertContentVariableMapKeys {
  APP_URL = 'app_url',
  ALERT_TYPE = 'alert_type',
  ALERT_TITLE = 'alert_title',
  ALERT_OBJECT_ID = 'alert_objectid',
  ALERT_OBJECT_ALIAS = 'alert_object_alias',
  ALERT_ADDRESS = 'alert_address',
  ALERT_CAUSE = 'alert_cause',
  HOST = 'host',
  BATTERY_STATE_OF_CHARGE = 'battery_soc',
  HUB_ID = 'hub_id',
  FLEET_ID = 'fleet_id',
  ASSET_TYPE = 'asset_type',
  EXPECTED_FRONT_TIRES_KPA = 'tires_front_exp_kpa',
  EXPECTED_REAR_TIRES_KPA = 'tires_rear_exp_kpa',
  DRIVER_FRONT_TIRE_KPA = 'tire_driver_front_act_kpa',
  DRIVER_REAR_TIRE_KPA = 'tire_driver_rear_act_kpa',
  PASSENGER_FRONT_TIRE_KPA = 'tire_pass_front_act_kpa',
  PASSENGER_REAR_TIRE_KPA = 'tire_pass_rear_act_kpa',
  FAULT_CODE = 'fault_code',
  FAULT_NAME_ES = 'fault_name_es',
  FAULT_NAME_EN = 'fault_name_en',
  FAULT_NAME_FR = 'fault_name_fr',
}

export enum AlertTypeFilter {
  LOW_BATTERY = 'lowBattery',
  LOW_BRAKE_FLUID = 'lowBrakeFluid',
  LOW_WASHER_FLUID = 'lowWasherFluid',
  LOW_TIRE_PRESSURE = 'lowTirePressure',
  FLAT_TIRE = 'flatTire',
}

export enum AlertQueryFieldType {
  SOURCE_ID = 'event.sourceId',
  TIMESTAMP = 'event.eventTimestamp',
  ALERT_TYPE = 'event.event',
  SOURCE_NAME = 'event.contentVariableMap.alert_object_alias',
  SOURCE_TYPE = 'event.sourceInstance.type.model',
  HUB_ID = 'event.sourceInstance.hub.id',
  FLEET_ID = 'event.sourceInstance.fleet.id',
  ORG_ID = 'event.sourceInstance.organization.id',
  CATEGORY = 'category',
  REGISTRATIONS = 'registrations',
  RECEIPT_PREFERENCES = 'receiptPreferences',
}

export enum AlertEntitiesQueryFieldType {
  TIMESTAMP = 'lastEventTimestamp',
  SOURCE_ID = 'lastContentVariableMap.alert_objectid',
  SOURCE_NAME = 'lastContentVariableMap.alert_object_alias',
  SOURCE_TYPE = 'lastContentVariableMap.asset_type',
  HUB_ID = 'lastContentVariableMap.hub_id',
  FLEET_ID = 'lastContentVariableMap.fleet_id',
  ORG_ID = 'lastContentVariableMap.organization_id',
}

export type AlertTypeQueryFieldType =
  `lastEventTimestampByEvent.${keyof typeof AlertEvent}`;

export interface AlertSubscription
  extends Entity,
    Pick<Subscription, 'event' | 'active'> {
  subscribed: boolean | null;
  category?: string;
  sourceType?: string;
  subscriptionId?: Subscription['id'];
  index?: number;
  toggleData: {
    subscribed: boolean | null;
    index: number;
    disabled: boolean;
  };
}

export type AlertLink = {
  id?: string;
  name?: string;
  url?: string;
};

export interface AlertDetail extends Entity {
  link?: AlertLink;
  eventTimestamp?: string;
  contentVariableMap?: AlertDetailsMap;
  message?: string;
  status?: string | AlertTireDetails[];
  sourceInstance?: Asset | string;
}

export interface Alert {
  details: AlertDetail[];
  totalAlerts: number;
  token: string | null;
}

export interface AlertDetails {
  alerts: AlertDetail[];
  totalAlerts: number;
  token: string | null;
}

export interface Subscription {
  id: string;
  organizationId: string;
  profileId: string;
  category: string;
  event: AlertEvent;
  active: boolean;
}

export type SubscriptionToggleInfo = {
  disabled: boolean;
  selectId: string;
  subscribed: boolean | null;
};

export type AlertDetailsMap = {
  [key in AlertContentVariableMapKeys]?: string | number;
};

export type ReceiptPreferenceType = 'EMAIL' | 'FEED';

export interface AvailableSubscription
  extends Pick<Entity, 'name'>,
    CopyWithPartialOptional<
      Subscription,
      'organizationId' | 'profileId' | 'active'
    > {
  receiptPreferences: ReceiptPreferenceType[];
  eventOptions: SubscriptionEvent[];
  selectId: string;
  selectedAssets?: string[];
  subscriptionId?: Subscription['id'];
  preferredName?: string;
  selectedVehicleSubscriberType?: VehicleSubscriberType;
}

export interface SubscriptionType {
  sourceType: string;
  category: string;
  event: AlertEvent;
}

export interface ApiSubscription {
  items: Subscription[];
}

export interface ApiSubscriptionType {
  items: SubscriptionType[];
}

export interface ApiAlertDetailEvent {
  sourceId?: string;
  sourceType?: string;
  category?: string;
  event?: string;
  eventTimestamp?: string;
  sourceInstance?: ApiAsset | string;
  applicationId?: string;
  contentVariableMap?: AlertDetailsMap;
  returnUrl?: string;
}

export type ApiAlertDetailItem = PagedResult<ApiAlertDetail> & {
  token: string | null;
};

export type ApiAlertDetail = {
  id: string;
  event: ApiAlertDetailEvent;
  organizationId?: string;
  subscriptionId?: string;
  profileId?: string;
  createDateTime?: string;
  returnUrl?: string;
  read?: boolean;
};

export type AlertUnread = {
  hasUnread?: boolean;
  approximateCount?: number;
};

export type ApiAlertUnread = {
  hasUnread?: boolean;
  approximateCount?: number;
};

export type ApiAlertRead = {
  hasUnread?: boolean;
  approximateCount?: number;
};
export type AlertRead = {
  hasUnread?: boolean;
  approximateCount?: number;
};

export type AlertRouteParams = Pick<RouteParams, 'organizationsId'>;

export interface AlertTireDetail {
  alertSeverity: DiagnosticSeverity | string;
  tireValue: string;
}

export interface AlertTireDetails {
  tirePosition: string;
  alertDetails: AlertTireDetail;
}

export interface AlertsCardEntry {
  alertType: string;
  detail: string | ReactElement;
  date: string;
}

export interface AssetAlertAPI {
  id: string;
  event: {
    eventTimestamp: string;
    sourceType: string;
    category: string;
    event: string;
    sourceInstance: {
      diagnostics: AssetDiagnostics;
    };
    contentVariableMap: AlertDetailsMap;
  };
}
export interface AlertsAPI {
  assetId: string;
  assetName: string;
  organization?: Pick<Organization, 'id' | 'name'>;
  hub?: Pick<Hub, 'id' | 'name'>;
  fleet?: Pick<Fleet, 'id' | 'name'>;
  type: {
    model: ApiAssetType;
    version: string;
  };
  alerts: AssetAlertAPI[];
}

export interface AssetAlert {
  id: string;
  assetName: string;
  type?: AssetModel;
  organization?: Entity;
  hub?: Entity;
  fleet?: Entity;
  eventTimestamp: string;
  eventType: AlertEvent;
  diagnostics: AssetDiagnostics;
  contentVariableMap: AlertDetailsMap;
  read?: boolean;
  subscriptionId?: string;
}
export interface AlertCardAssetAlerts {
  assetId: string;
  assetName: string;
  type: AssetModel;
  alerts?: AssetAlert[];
  organization?: Pick<Organization, 'id' | 'name'>;
  hub?: Pick<Hub, 'id' | 'name'>;
  fleet?: Pick<Fleet, 'id' | 'name'>;
}
export type ApiAlertEvent = {
  eventTimestamp: string;
  sourceType: string;
  category: string;
  event: AlertEvent;
  sourceInstance: {
    diagnostics: AssetDiagnostics;
  };
  contentVariableMap: AlertDetailsMap;
};

export type ApiAlert = {
  id: string;
  event: ApiAlertEvent;
};

export type ApiAlertEntity = {
  sourceId: string;
  sourceType: string;
  lastEventTimestamp: string;
  lastEventTimestampByEvent: [
    {
      event: AlertEvent;
      eventTimestamp: string;
    },
  ];
  lastContentVariableMap?: AlertDetailsMap;
};

export const SUMMARY_NUMBER_OF_ALERTS = 3;

export type RegistrationItem = {
  category: string;
  event: string;
};

export type SubscriptionDetails = {
  id?: string;
  organizationId: string;
  registrations: RegistrationItem[];
  active: boolean;
  noiseThresholdInMinutes?: number;
  profileId?: string;
  receiptPreferences?: ReceiptPreferenceType[];
  preferredName?: string;
  assets?: string[];
};

export interface SubscriptionEvent {
  category: string;
  event: string;
  id: string;
  active: boolean;
  selectId: string;
  displayName: string;
}

export interface SubscriptionParams {
  subscription: AvailableSubscription;
}
