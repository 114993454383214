import { ConnectivityStatusType } from '~/common/models/common.model';
import {
  ApiConnectivityStatusSummary,
  ConnectivityStatusSummary,
} from '~/common/models/csDashboard.model';
import { mapConnectivityStatusType } from '~/features/assets/mappers/asset.mappers';

export const mapConnectivityStatusSummary = (
  data: ApiConnectivityStatusSummary
): ConnectivityStatusSummary => {
  let verificationPending = 0;
  let verified = 0;
  let connectionPending = 0;
  let connected = 0;

  data?.items?.forEach((item) => {
    const status = mapConnectivityStatusType(
      item.organizationPrimaryOwnershipStatus,
      item.provisioningState
    );
    switch (status) {
      case ConnectivityStatusType.VERIFICATION_PENDING:
        verificationPending++;
        break;
      case ConnectivityStatusType.VERIFIED:
        verified++;
        break;
      case ConnectivityStatusType.CONNECTION_PENDING:
        connectionPending++;
        break;
      case ConnectivityStatusType.CONNECTED:
        connected++;
        break;
    }
  });
  return {
    organizationId: data.organizationId,
    totalVehicleCount: data.totalVehicleCount,
    connectivityStatuses: {
      verified,
      verificationPending,
      connectionPending,
      connected,
    },
  };
};
