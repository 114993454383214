import { OperationStatus } from './apis/apiResponse.model';

export enum FeatureFlagsRequestType {
  GET_FEATURE_FLAGS = 'Get Feature Flags',
}

export type FeatureFlagValueType =
  | string
  | boolean
  | number
  | { [k: string]: FeatureFlagValueType };

export type FeatureFlag = {
  name: string;
  value: FeatureFlagValueType;
};

export type FeatureFlagsState = {
  operations: {
    [key in FeatureFlagsRequestType]?: OperationStatus;
  };
};

export const GROCERY_FLAG_NAME = 'F32356-Cold-Chain';
export const TRIP_HISTORY_SUPPRESSOR_FLAG_NAME =
  'F44668-Trip-History-Suppressor';
export const CHARGE_STATUS_FLAG_NAME = 'F37524-Charge-Status';
export const CHARGING_SCHEDULE_FLAG_NAME = '34205-Charging-Schedule-Config';
export const INSIGHTS_FLAG_NAME = 'F41450-Insights';
export const DVIC_FLAG_NAME = 'F37416-DVIC-MVP';
export const DISPATCH_MANAGEMENT_MVP_FLAG_NAME =
  'F44231-Dispatch-Management-MVP';
export const NEW_LOCATIONS_FLAG_NAME = 'S44669-Locations-Upgrade';
export const TRACE_SUPPRESSOR_FLAG_NAME = 'F44574-Trace-Suppressor';
export const UTILIZATION_SUMMARY_ALL_TIME_FILTER_FLAG_NAME =
  'F45825-Utilization-Summary-All-Time-Filter';
export const ASSIGN_VEHICLE_FLAG_NAME = 'S50708-ASSIGN-VEHICLE';
export const PERIPHERALS_FLAG_NAME = 'F52881-PERIPHERALS';
export const MRT_FLAG_NAME = 'F52091-MRT-FLAG';
export const CUSTOMER_SUPPORT_FLAG_NAME = 'F53569-CUSTOMER-SUPPORT-PAGE';
export const CREATE_NEW_FLEET_FLAG_NAME = 'F54585-CREATE-NEW-FLEET';
// Charge Level Widget Added to Dashboard View 9/19/2024
export const DASHBOARD_CHARGE_LEVEL = 'F52359-Charge-Level-Widget';
// Global Search flag added 7 Oct 2024 automatically expires 31 Mar 2025
export const GLOBAL_SEARCH_FLAG_NAME = 'F51951_Global_Search';
export const DIAGNOSTICS_FLAG_NAME = 'F50012-DIAGNOSTICS-COLUMNS';
export const REMOTE_COMMANDS_FLAG_NAME = 'F53692-Remote-Commands';
export const CAPS_FLAG_NAME = 'F52239-CAP-AGREEMENTS-PAGE';
// User Boarding List flag for V0.5 added 10/18/24
export const USER_LIST_V05_FLAG = 'F52176-User-List-Invite-Modal';
export const RECALLS_FLAG_NAME = '50343-Recall';
// Add Vehicles Modal flag for V0.5 added 10/23/24
export const ADD_VEHICLE_MODAL_V05_FLAG = 'F53618-Add-Vehicles-Modal';
export const FLEET_SWITCHER_FLAG_NAME = '51952-Fleet-Switcher';
export const PUB_SUB_FLAG = 'F53760-ENABLE-PUBSUB-ALERTS';
export const EXPIRED_STALE_DATA_FLAG = 'F50012-Enable-Stale-Data';
// Dashboard: Vehicle Pending Flag for V0.5ff added 01/17/25
export const DASHBOARD_VEHICLES_FLAG_NAME = 'F55127-Vehicles-Widget';
// Protect Your Account Warning Flag for V0.5 added 01/15/25
export const PROTECT_YOUR_ACCOUNT_WARNING_FLAG =
  'F53832-Protect-Your-Account-Warning';
