import { PayloadAction } from '@reduxjs/toolkit';

import { RouteParams } from '../configs/route.config';
import { SearchType } from '../constants/common.constant';
import { ApiResponse, OperationStatus } from './apis/apiResponse.model';
import { DoorStatus, LockStatus } from './asset.model';
import { BDError } from './error.model';

export enum ActivationStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DISABLED = 'disabled',
}
export type PartialEntity<T extends Entity> = Pick<T, keyof Entity> &
  Partial<T>;

// pick and make them optional
export type OptionalPick<T, K extends keyof T> = {
  [P in K]?: T[P];
};

// copy all and pick optional ones
export type CopyWithPartialOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<T>;

export enum UserSupportedLocale {
  en_US = 'en-US',
  en_CA = 'en-CA',
  fr_CA = 'fr-CA',
  es_MX = 'es-MX',
}

export type AppSupportedLocale =
  | UserSupportedLocale
  | ('en' | 'fr' | 'es' | 'es-US');

export const MAP_APP_LOCALE_TO_USER_LOCALE = {
  ['en']: UserSupportedLocale.en_US,
  ['fr']: UserSupportedLocale.fr_CA,
  ['es']: UserSupportedLocale.es_MX,
  ['es-US']: UserSupportedLocale.es_MX,
};

export interface Location {
  latitude: number;
  longitude: number;
}

export interface AssetRawLocation extends Location {
  timestamp?: string;
  required?: boolean;
}

export interface SummaryCounts {
  hubs?: number;
  fleets?: number;
  users?: number;
  assets?: number;
}

export enum SeverityLevel {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export type AggregateCounts<K extends keyof SummaryCounts> = {
  [key: string]: Pick<SummaryCounts, K>;
};
export interface Entity {
  id: string;
  name: string;
}

export interface PaginationInfo {
  page: number;
  rowsPerPage: number;
}

export interface PaginationContinuationInfo {
  rowsPerPage: number;
  continuationToken?: string | null;
}

export interface SkippableStateUpdate {
  skipStateUpdate?: boolean;
}

export interface ListViewSession
  extends PaginationInfo,
    PaginationContinuationInfo {
  count: number;
  sortOrder: { name: string; direction: 'asc' | 'desc' };
  rowsExpanded?: number[];
  selectedIndices?: number[];
  allSelected?: boolean;
}

export interface OperationSession {
  operationStatus?: OperationStatus;
}

export type FormPayload<
  FormType,
  RequiredKey extends keyof RouteParams,
  OptionalKey extends keyof RouteParams = never,
> = {
  formFields: FormType;
  params: Required<Pick<RouteParams, RequiredKey>> &
    Pick<RouteParams, OptionalKey>;
};

export type FormSubmitResponse<T> = Promise<
  FormSubmitSuccess<T> | FormSubmitFailure<T>
>;

export type FormSubmitSuccess<T> = PayloadAction<
  ApiResponse<T> | undefined,
  string,
  { requestStatus: 'fulfilled' }
>;

export type FormSubmitFailure<T> = PayloadAction<
  BDError<ApiResponse<T | null> | null> | undefined,
  string,
  { requestStatus: 'rejected' }
>;

/**
 * Union of keys from T whose types in T extend condition C
 */
export type ConstrainedKeys<T, C> = {
  [K in keyof T]-?: T[K] extends C ? K : never;
}[keyof T];

/**
 * Type consisting of keys from T whose types in T extend condition C
 */
export type ConstrainedType<T, C> = Pick<T, ConstrainedKeys<T, C>>;

/**
 * Picks key union K from T and strips null from any type T[K]
 */
export type NonNullablePick<T, K extends keyof T> = {
  [P in K]: NonNullable<Pick<T, P>[P]>;
};

/**
 * Simple type to use for deeply-nested translation string objects
 */
export type ComponentLabels = string | { [k: string]: ComponentLabels };

export type CommonAnalyticsInfo = {
  orgId?: string;
  hubId?: string;
  fleetId?: string;
};

export type CommonListAnalyticsInfo = CommonAnalyticsInfo & {
  listType: string;
};

export type CommonMapAnalyticsInfo = CommonAnalyticsInfo & {
  mapType: string;
};

/**
 * Type use for web analytics to track map marker clicks
 */
export type MapMarkerType = CommonMapAnalyticsInfo & {
  zoom?: number;
  center?: Location;
  markerAttributes?: MapAttributesType;
};
export type MapAttributesType = {
  assetIds?: string[];
  eventId?: string;
  isCluster?: boolean;
  isActive?: boolean;
  markerType?: DoorStatus | LockStatus | 'PACE';
};

/**
 * Type use for web analytics to track list view filter events
 */
export type FilterListAnalyticsInfo = CommonListAnalyticsInfo & {
  filters?: { field: string; value: string | string[] }[];
  reset?: boolean;
  chipDismissed?: { field: string; value: string | string[] };
};

/**
 * Type use for web analytics to track list view sort events
 */
export type SortListAnalyticsInfo = CommonListAnalyticsInfo & {
  reportId?: string;
  sortOrder?: {
    name: string;
    direction: 'asc' | 'desc';
  };
};

export type MapViewAnalyticsInfo = CommonMapAnalyticsInfo & {
  zoom?: number;
  center?: Location;
};

export type MapListAnalyticsInfo = CommonListAnalyticsInfo & {
  visible?: boolean;
};

export type UtilizationAnalyticsInfo = CommonAnalyticsInfo & {
  days?: number;
  changedColumn?: string;
  direction?: string;
  assetId?: string;
};

export enum AssetUtilizationWidgetTab {
  DISTANCE = 'distance',
  VEHICLE_ON_TIME = 'vehicle_on_time',
  ENERGY_CONSUMPTION = 'energy_consumption',
  ENERGY_CONSUMPTION_RATE = 'energy_consumption_rate',
}

export interface SearchCriteria {
  searchType: SearchType;
  input?: string;
}

export enum TimeUnit {
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year',
}

export interface PagedResult<T> {
  items: T[];
  total_items: number;
  continuation_token?: string;
}

export interface PagedResultWithErrors<T> extends PagedResult<T> {
  errors?: BDError[];
}

export enum BDId {
  ORG = 'organizationId',
  HUB = 'hubId',
  FLEET = 'fleetId',
}

export interface PagedSort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface DetailedPagedResult<T> {
  content: T[];
  total_items: number;
}

export enum SublistTab {
  HUBS = 'hubs',
  FLEETS = 'fleets',
}

export enum DateRange {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this_week',
  LAST_WEEK = 'last_week',
}

export enum ApiPaginationParamNames {
  PAGE = 'page',
  SIZE = 'size',
}
export type TimeZoneOption = {
  value: string;
  label: string;
};

export type ElementTypes<T> = T extends (infer U)[] ? NonNullable<U> : never;

export interface Job extends Entity {
  jobId: string;
  jobType: string;
  createdBy: string;
  createdDate: string;
}

export enum JobStatus {
  NOT_STARTED = 'not_started',
  ACTIVE = 'active',
  RETRYING = 'retrying',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  TERMINATED_BY_USER = 'terminated_by_user',
  TERMINATION_REQUESTED = 'termination_requested',
  TERMINATED_BY_TIMEOUT = 'terminated_by_timeout',
}

export enum JobOutcome {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type JobRunTime = {
  startAfter: string;
  preferredDurationInS: number;
};

export interface ApiJob {
  jobId: string;
  organizationId: string;
  jobType: string;
  createdDate: string;
  runtime: JobRunTime;
  createdBy?: string;
}

export enum ConnectivityStatusType {
  VERIFICATION_PENDING = 'verificationPending',
  VERIFIED = 'verified',
  CONNECTION_PENDING = 'connectionPending',
  CONNECTED = 'connected',
}

export enum FileTypes {
  CSV = 'CSV',
  XLS = 'XLS',
}

export interface ContinuationCacheItem<TData = unknown> {
  data: TData;
  continuationToken: string;
}

export interface ContinuationCache<TData = unknown> {
  [pageIndex: number]: ContinuationCacheItem<TData>;
}
