import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    display: 'flex',
    marginTop: '55px',
    padding: '10px 10px',
    minHeight: 'calc(100vh - 240px)',
    '& .MuiBox-root': {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '10px 20px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0',
      marginTop: '80px',
      minHeight: 'calc(100vh - 250px)',
    },
  },
}));
export default useStyles;
