import { Role } from '@gm-commercial/profile-model';

import { AppNames } from '~/common/models/appNames.model';

import { AuthenticationType } from '../../../features/session/model/authentication.model';
import { RouteParams, Routes } from '../../configs/route.config';
import {
  FeatureFlagValueType,
  GROCERY_FLAG_NAME,
} from '../../models/featureFlags.model';
import {
  AppRoutePaths,
  RouteConfig,
  RouteName,
} from '../../models/route.model';

export const findRoute = (routeName: RouteName): RouteConfig =>
  Routes.find((route) => route.name === routeName) || ({} as RouteConfig);

export const makeRoutePath = (
  pathInfo: string | RouteConfig,
  params: RouteParams,
  exact = false,
  includeQuery = false,
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined
): string => {
  // candidate paths either
  //   a) end with the string-type pathInfo, or
  //   b) are all the paths of the provided RouteConfig
  // if exact === true, pathInfo is assumed to be the desired path
  let candidatePaths = new Array<string>();
  if (exact && pathInfo && typeof pathInfo === 'string') {
    candidatePaths.push(pathInfo);
  } else {
    if (typeof pathInfo === 'string') {
      candidatePaths = Routes.reduce((flattened, config) => {
        if (config?.path) {
          if (typeof config.path === 'string') {
            flattened.push(config.path);
          } else {
            flattened = flattened.concat(config.path);
          }
        }
        return flattened;
      }, [] as string[]).filter((path) => path.endsWith(`/${pathInfo}`));
    } else if (pathInfo.path) {
      /**
       * If this is true we know we are dealing with a RouteConfig and will check for feature flags on the route.
       * If the feature is disabled we will return an empty path.
       */
      const featureDisabled =
        findFlagScope &&
        !pathInfo?.requiredFeatureFlags?.some(
          (flagName) => !!findFlagScope(flagName)
        );

      if (featureDisabled) {
        return '';
      }
      if (typeof pathInfo.path === 'string') {
        candidatePaths.push(pathInfo.path);
      } else {
        candidatePaths = candidatePaths.concat(pathInfo.path || []);
      }
    }
  }

  if (!candidatePaths.length) {
    return '';
  }

  // set fallback path to any that doesn't require params if no params are available
  let mostQualifiedPath =
    candidatePaths.find((p) => p.indexOf(':') === -1) || '';
  const usedParams: string[] = [];
  candidatePaths.reduce((mostParams, path) => {
    let workingPath = path;
    let paramsSupported = 0;

    for (const param in params) {
      const paramValue = params[param as keyof RouteParams];
      if (
        Object.prototype.hasOwnProperty.call(params, param) &&
        paramValue &&
        path.indexOf(`:${param}`) > 0
      ) {
        workingPath = workingPath.replace(`:${param}`, paramValue);
        paramsSupported++;
        usedParams.push(param);
      }
    }

    // select working path if it makes the most use of available route params and
    // does not require any params that are not currently available
    if (paramsSupported > mostParams && workingPath.indexOf(':') === -1) {
      mostQualifiedPath = workingPath;
      return paramsSupported;
    }
    return mostParams;
  }, 0);

  if (includeQuery) {
    const availableParams = Object.keys(params)
      .filter(
        (param) =>
          !usedParams.includes(param) && params[param as keyof RouteParams]
      )
      .reduce(
        (accumulator, param) => ({
          ...accumulator,
          [param]: params[param as keyof RouteParams],
        }),
        {}
      );
    const queryValue = new URLSearchParams({ ...availableParams }).toString();
    return queryValue
      ? `${mostQualifiedPath}?${queryValue}`
      : mostQualifiedPath;
  }

  return mostQualifiedPath;
};

export const getDefaultRoutePath = (
  role: Role | undefined,
  params: RouteParams,
  getFlag: (name: string) => FeatureFlagValueType | undefined
): string => {
  let route: string;
  switch (role) {
    case Role.FLEET_MANAGER:
    case Role.HUB_MANAGER:
      route = makeRoutePath(findRoute(RouteName.DASHBOARD), params);
      break;
    case Role.ORG_MANAGER:
      route = makeRoutePath(
        findRoute(RouteName.CS_PLATFORM_DASHBOARD),
        {
          organizationsId: params.organizationsId,
          hubsId: params.hubsId,
          fleetsId: params.fleetsId,
        },
        false,
        true
      );
      break;
    case Role.POC_GROCERYMANAGER:
      route = getFlag(GROCERY_FLAG_NAME)
        ? makeRoutePath(
            findRoute(RouteName.STORE_VIEW),
            { organizationsId: params.organizationsId },
            false,
            true
          )
        : makeRoutePath(findRoute(RouteName.ORG_DETAILS), params);
      break;
    case Role.OPERATOR:
      route = AppRoutePaths.MOBILE_APP_REQUIRED;
      break;
    case Role.SYSTEM_MANAGER:
    case Role.SYSTEM_READER:
      route = AppRoutePaths.ORGANIZATION_LIST;
      break;
    case Role.POC_ROUTEMANAGER:
      route = '/routes/plans';
      break;
    case Role.SERVICE_TECHNICIAN:
      route = params.organizationsId
        ? `/organizations/${params.organizationsId}/assetReport`
        : AppRoutePaths.ASSET_LIST;
      break;
    case Role.SERVICE_MANAGER:
      route = AppRoutePaths.ASSET_LIST;
      break;
    default:
      route = AppRoutePaths.UNAUTHORIZED;
      break;
  }
  return route;
};

export const getNavDisabledPaths = (): string[] =>
  [
    { ...findRoute(RouteName.PRIVACY) },
    { ...findRoute(RouteName.USER_TERMS) },
    { ...findRoute(RouteName.USER_TERMS_AND_CONDITIONS) },
  ]
    .reduce(
      (paths, route) => paths.concat(route.path || []),
      Object.values(AuthenticationType).map((type) => `/auth/${type}/login`)
    )
    .concat([
      AppRoutePaths.BUSINESS_ENROLLMENT,
      AppRoutePaths.TERMS_CONDITIONS,
    ]);

export const getSettingsNavPaths = (): string[] =>
  [{ ...findRoute(RouteName.CS_PLATFORM_SETTINGS) }].reduce(
    (paths, route) => paths.concat(route.path || []),
    [] as string[]
  );

export const getProfileDisabled = (): string[] =>
  [{ ...findRoute(RouteName.USER_TERMS_AND_CONDITIONS) }].reduce(
    (paths, route) => paths.concat(route.path || []),
    [] as string[]
  );

export const getNoAsyncFeatureFlagRoutes = (): string[] =>
  [
    { ...findRoute(RouteName.INSIGHT_LIST) },
    { ...findRoute(RouteName.INSIGHT_DETAILS) },
    { ...findRoute(RouteName.DISPATCH) },
  ].reduce((paths, route) => paths.concat(route.path || []), [] as string[]);

export const isImmersiveNavPath = (path: string): boolean => {
  return [AppNames.ACCOUNT, AppNames.SETTINGS].some((appName) =>
    path.startsWith(`/${appName}/`)
  );
};
