import { LDProvider } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren } from 'react';

import FeatureFlagsResolver from './FeatureFlagsResolver';

interface FeatureFlagsProviderProps {
  clientToken: string;
  async?: boolean;
}

const FeatureFlagProvider = ({
  clientToken,
  async,
  children,
}: PropsWithChildren<FeatureFlagsProviderProps>): JSX.Element => {
  return (
    <LDProvider
      clientSideID={clientToken}
      reactOptions={{ useCamelCaseFlagKeys: false }}
    >
      <FeatureFlagsResolver async={async}>{children}</FeatureFlagsResolver>
    </LDProvider>
  );
};

export default FeatureFlagProvider;
