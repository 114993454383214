import { Role } from '@gm-commercial/profile-model';

import {
  CapacityUnit,
  DiagnosticFlag,
  DiagnosticSeverity,
  DistanceUnit,
  EnergyEfficiency,
  EnergyUnit,
  FuelEconomyUnit,
  SpeedUnit,
  TemperatureUnit,
  TirePressureUnit,
} from '../models/asset.model';
import { UserSupportedLocale } from '../models/common.model';

export const DEFAULT_SESSION_CONFIG_KEY = 'all';

export const DEFAULT_EMPTY_VALUE = '--';
export const SPEED_WARNING = 55; //mph
export const SPEED_WARNING_LIMITS: {
  [k in SpeedUnit]?: number[];
} = {
  [SpeedUnit.KPH]: [80, 88, 96, 104, 112, 120, 128],
  [SpeedUnit.MPH]: [50, 55, 60, 65, 70, 75, 80],
};
export const SPEED_WARNING_DEFAULTS: {
  [k in SpeedUnit]?: number;
} = {
  [SpeedUnit.KPH]: 112,
  [SpeedUnit.MPH]: 70,
};
export const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const MINIMUM_SPEED_WARNING_VALUE_KM = 40;
export const DEFAULT_MIN_SELECTABLE_SPEED_WARNING_KM = 88;
export const DEFAULT_MAX_SELECTABLE_SPEED_WARNING_KM = 120;
export const SPEED_WARNING_VALUES: {
  [k in SpeedUnit]?: number[];
} = {
  [SpeedUnit.KPH]: [
    MINIMUM_SPEED_WARNING_VALUE_KM,
    48,
    56,
    64,
    72,
    80,
    DEFAULT_MIN_SELECTABLE_SPEED_WARNING_KM,
    96,
    104,
    112,
    DEFAULT_MAX_SELECTABLE_SPEED_WARNING_KM,
  ],
  [SpeedUnit.MPH]: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
};
export const MINIMUM_SELECTABLE_SPEED_WARNING_INDEX = 6;
export const MAXIMUM_SELECTABLE_SPEED_WARNING_INDEX = 10;
export const MINIMUM_SPEED_WARNING_VALUE_INDEX = 0;

// percentage of expected pressure
export const TIRES_THRESHOLD_LOW = 0.77;
export const TIRES_THRESHOLD_FLAT = 0.68;

export const KILOBYTE_IN_BYTES = 1000;
export const MEGABYTE_IN_BYTES = KILOBYTE_IN_BYTES * 1000;

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const INTL_DATE_FORMAT = 'yyyy/MM/dd';
export const SHORT_ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const MEXICO_DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const INTL_DATE_TIME_FORMAT = 'yyyy/MM/dd hh:mm a';

export const TIME_FORMAT_WITH_TIMEZONE = 'hh:mm a ZZZZ';
export const TIME_FORMAT_WITHOUT_TIMEZONE = 'h:mm a';
export const CANADA_TIME_FORMAT_WITH_TIMEZONE = 'HH:mm ZZZZ';
export const DATE_TIME_FORMAT_WITH_TIMEZONE = 'MM/dd/yyyy hh:mm a ZZZZ';
export const DATE_TIME_FORMAT_WITHOUT_TIMEZONE = 'M/dd/yyyy h:mm a';
export const DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE =
  'MM/dd/yyyy - hh:mm a ZZZZ';
export const INTL_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE =
  'yyyy/MM/dd - hh:mm a ZZZZ';
export const MEXICO_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE =
  'dd/MM/yyyy - hh:mm a ZZZZ';
export const DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE = 'MM-dd-yyyy hh:mm a ZZZZ';
export const MEXICO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE =
  'dd-MM-yyyy hh:mm a ZZZZ';
export const LAST_COMMUNICATED_DATE = 'MM-dd-yyyy - hh:mm ZZZZ';
export const DATE_TIME_API_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATE_TIME_API_FORMAT_COMPLETE = 'yyyy-MM-dd HH:mm:ss';
export const DATE_TIME_API_FORMAT_WITH_TIMEZONE = "yyyy-MM-dd HH:mm:ss'Z";
export const TIME_API_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
export const YEAR_MONTH_FORMAT_HEADER = 'EEE, DD';
export const MONTH_DAY_FORMAT = 'MMM dd';
export const MONTH_DAY_WEEK_FORMAT = 'EEE, MMM dd';
export const MONTH_SHORT_FORMAT = 'MMM';
export const DAY_FORMAT = 'dd';
export const LAST_UPDATED_DATE = 'MMM dd, yyyy - hh:mm a';
export const ISO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE =
  'yyyy-MM-dd - HH:mm ZZZZ';
export const SINGLE_MONTH_DAY_FORMAT = 'M/d';
export const INTL_SINGLE_MONTH_DAY_FORMAT = 'd/M';
export const DATE_TIME_FORMAT_WITH_SECONDS_AND_TIMEZONE =
  'MM-dd-yyyy hh:mm ss a ZZZZ';

export const INTL_MONTH_DAY_FORMAT = 'd MMM';
export const DEFAULT_MONTH_DAY_YEAR_DATE_FORMAT = 'MMM d, yyyy';
export const INTL_MONTH_DAY_YEAR_DATE_FORMAT = 'd MMM, yyyy';
export const MONTH_SINGLE_DAY_FORMAT = 'MMM d';
export const DEFAULT_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT = 'EEEE, MMM d, yyyy';
export const INTL_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT = 'EEEE, d MMM, yyyy';

export const DEFAULT_PAGINATION_PAGE = 0;
export const DEFAULT_PAGINATION_ROW_PER_PAGE = 10;
export const DEFAULT_PAGINATION_MIN_ROWS_PER_PAGE = 5;
export const DEFAULT_PAGINATION_MAX_ROWS_PER_PAGE = 100;
export const CONTINUATION_PAGINATION_MAX_ROWS_PER_PAGE = 200;

export const DEFAULT_TIRE_PRESSURE_UNIT = TirePressureUnit.KPA;
export const ASSET_TIRE_PRESSURE_UNIT = TirePressureUnit.PSI;
export const DEFAULT_SUPPORT_PHONE_NUMBER = '1-888-987-4377';
export const DEFAULT_DISTANCE_UNIT = DistanceUnit.KM;

export enum AppEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  UAT = 'uat',
}

export enum LanguageLocale {
  EN = 'en',
  EN_US = 'en-us',
}
export const SUPPORT_PHONE_NUMBER = {
  en: DEFAULT_SUPPORT_PHONE_NUMBER,
};

export const LOCALE_FORMAT_DATE = {
  en: DEFAULT_DATE_FORMAT,
  fr: SHORT_ISO_DATE_FORMAT,
  es: MEXICO_DEFAULT_DATE_FORMAT,
  [UserSupportedLocale.en_US]: DEFAULT_DATE_FORMAT,
  [UserSupportedLocale.en_CA]: SHORT_ISO_DATE_FORMAT,
  [UserSupportedLocale.fr_CA]: SHORT_ISO_DATE_FORMAT,
  'es-US': DEFAULT_DATE_FORMAT,
  [UserSupportedLocale.es_MX]: MEXICO_DEFAULT_DATE_FORMAT,
};

export const LOCALE_FORMAT_TIME = {
  en: TIME_FORMAT_WITH_TIMEZONE,
  fr: CANADA_TIME_FORMAT_WITH_TIMEZONE,
  es: TIME_FORMAT_WITH_TIMEZONE,
  [UserSupportedLocale.en_US]: TIME_FORMAT_WITH_TIMEZONE,
  [UserSupportedLocale.en_CA]: CANADA_TIME_FORMAT_WITH_TIMEZONE,
  [UserSupportedLocale.fr_CA]: CANADA_TIME_FORMAT_WITH_TIMEZONE,
  'es-US': TIME_FORMAT_WITH_TIMEZONE,
  [UserSupportedLocale.es_MX]: TIME_FORMAT_WITH_TIMEZONE,
};

export const LOCALE_TIME_FORMAT_WITHOUT_TIMEZONE = {
  en: TIME_FORMAT_WITHOUT_TIMEZONE,
  fr: TIME_FORMAT,
  es: TIME_FORMAT_WITHOUT_TIMEZONE,
  [UserSupportedLocale.en_US]: TIME_FORMAT_WITHOUT_TIMEZONE,
  [UserSupportedLocale.en_CA]: TIME_FORMAT,
  [UserSupportedLocale.fr_CA]: TIME_FORMAT,
  'es-US': TIME_FORMAT_WITHOUT_TIMEZONE,
  [UserSupportedLocale.es_MX]: TIME_FORMAT_WITHOUT_TIMEZONE,
};
export const LOCALE_DATE_TIME_FORMAT = {
  en: DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  fr: ISO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  es: MEXICO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  [UserSupportedLocale.en_US]: DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  [UserSupportedLocale.en_CA]: ISO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  [UserSupportedLocale.fr_CA]: ISO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  'es-US': DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
  [UserSupportedLocale.es_MX]: MEXICO_DATE_TIME_FORMAT_DASHES_WITH_TIMEZONE,
};

export const LOCALE_MONTH_DAY_FORMAT = {
  en: MONTH_SINGLE_DAY_FORMAT,
  fr: INTL_MONTH_DAY_FORMAT,
  es: INTL_MONTH_DAY_FORMAT,
  [UserSupportedLocale.en_US]: MONTH_SINGLE_DAY_FORMAT,
  [UserSupportedLocale.en_CA]: INTL_MONTH_DAY_FORMAT,
  [UserSupportedLocale.fr_CA]: INTL_MONTH_DAY_FORMAT,
  'es-US': MONTH_SINGLE_DAY_FORMAT,
  [UserSupportedLocale.es_MX]: INTL_MONTH_DAY_FORMAT,
};

export const LOCALE_MONTH_DAY_YEAR_FORMAT = {
  en: DEFAULT_MONTH_DAY_YEAR_DATE_FORMAT,
  fr: INTL_MONTH_DAY_YEAR_DATE_FORMAT,
  es: INTL_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.en_US]: DEFAULT_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.en_CA]: INTL_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.fr_CA]: INTL_MONTH_DAY_YEAR_DATE_FORMAT,
  'es-US': DEFAULT_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.es_MX]: INTL_MONTH_DAY_YEAR_DATE_FORMAT,
};

export const LOCALE_WEEKDAY_MONTH_DAY_YEAR_FORMAT = {
  en: DEFAULT_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  fr: INTL_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  es: INTL_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.en_US]: DEFAULT_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.en_CA]: INTL_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.fr_CA]: INTL_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  'es-US': DEFAULT_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
  [UserSupportedLocale.es_MX]: INTL_WEEKDAY_MONTH_DAY_YEAR_DATE_FORMAT,
};

export const LOCALE_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH = {
  en: DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  fr: INTL_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  es: MEXICO_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  [UserSupportedLocale.en_US]: DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  [UserSupportedLocale.en_CA]:
    INTL_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  [UserSupportedLocale.fr_CA]:
    INTL_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  'es-US': DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
  [UserSupportedLocale.es_MX]:
    MEXICO_DATE_TIME_FORMAT_WITH_SLASHES_AND_DASH_TIMEZONE,
};

export type UnitsType = {
  distance: DistanceUnit;
  capacity: CapacityUnit;
  fuelEconomy: FuelEconomyUnit;
  tirePressure: TirePressureUnit;
  speed: SpeedUnit;
  temperature: TemperatureUnit;
  energy: EnergyUnit;
  energyEfficiency: EnergyEfficiency;
};

export const DECIMAL_UNITS = {
  distance: DistanceUnit.KM,
  capacity: CapacityUnit.LITER,
  fuelEconomy: FuelEconomyUnit.L100K,
  tirePressure: TirePressureUnit.KPA,
  speed: SpeedUnit.KPH,
  temperature: TemperatureUnit.C,
  energy: EnergyUnit.kWh,
  energyEfficiency: EnergyEfficiency.KM,
};
export const USA_UNITS = {
  distance: DistanceUnit.MI,
  capacity: CapacityUnit.GALLON,
  fuelEconomy: FuelEconomyUnit.MPG,
  tirePressure: TirePressureUnit.PSI,
  speed: SpeedUnit.MPH,
  temperature: TemperatureUnit.F,
  energy: EnergyUnit.kWh,
  energyEfficiency: EnergyEfficiency.MI,
};
export const UNITS_CONFIGURATION: {
  [key in string]?: UnitsType;
} = {
  [UserSupportedLocale.en_US]: USA_UNITS,
  [UserSupportedLocale.en_CA]: DECIMAL_UNITS,
  [UserSupportedLocale.fr_CA]: DECIMAL_UNITS,
  [UserSupportedLocale.es_MX]: DECIMAL_UNITS,
  'es-US': USA_UNITS,
  fr: DECIMAL_UNITS,
  en: USA_UNITS,
  es: DECIMAL_UNITS,
};
export const SINGLE_MONTH_DAY_FORMAT_CONFIGURATION: {
  [key in string]?: string;
} = {
  [UserSupportedLocale.en_US]: SINGLE_MONTH_DAY_FORMAT,
  [UserSupportedLocale.en_CA]: INTL_SINGLE_MONTH_DAY_FORMAT,
  [UserSupportedLocale.fr_CA]: INTL_SINGLE_MONTH_DAY_FORMAT,
  [UserSupportedLocale.es_MX]: INTL_SINGLE_MONTH_DAY_FORMAT,
  'es-US': SINGLE_MONTH_DAY_FORMAT,
  fr: INTL_SINGLE_MONTH_DAY_FORMAT,
  en: SINGLE_MONTH_DAY_FORMAT,
  es: INTL_SINGLE_MONTH_DAY_FORMAT,
};
export const SLASH_WITH_SPACES = ' / ';
export const DASH_WITH_SPACES = ' - ';
export const KNOWN_DIAGNOSTIC_FLAGS: DiagnosticFlag[] = [
  DiagnosticFlag.TIRE_LOW,
  DiagnosticFlag.TIRE_FLAT,
  DiagnosticFlag.BATTERY_YELLOW,
  DiagnosticFlag.BATTERY_RED,
  DiagnosticFlag.FLUID_LOW_BRAKE,
  DiagnosticFlag.FLUID_LOW_WASHER,
];

export const ROLE_LEVELS = Object.values(Role).reduce(
  (levels, roleValue, idx) => ({ ...levels, [roleValue]: idx }),
  {} as { [k in Role]: number }
);

export const ROLE_KEYS = Object.entries(Role).reduce(
  (accumulator, [key, value]) => {
    return { ...accumulator, [value as Role]: key };
  },
  {} as { [key in Role]: keyof Role }
);

export const DIAGNOSTIC_SEVERITY_LEVELS = Object.values(
  DiagnosticSeverity
).reduce(
  (levels, severity, idx) => ({ ...levels, [severity]: idx }),
  {} as { [k in DiagnosticSeverity]: number }
);

export enum SessionConfigType {
  DETAIL_VIEW = 'Detail View',
  LIST_VIEW = 'List View',
}

export const DEFAULT_PAGE = '0';
export const DEFAULT_SIZE = '10';

export enum SearchType {
  ALERT_TYPE = 'alertType',
  FLEET = 'fleet',
  HUB = 'hub',
  ID = 'id',
  INSPECTION = 'inspection',
  ASSET = 'asset',
  OPERATOR = 'operator',
  ORDER = 'order',
  SCHEDULE_NAME = 'scheduleName',
  SERIAL_NUMBER = 'serialNumber',
  VIN = 'vin',
  WORK_PLAN_NAME = 'workPlanName',
  WORK_PLAN_FILE_SOURCE = 'workPlanFileSource',
  WORK_PLAN_STATUS = 'workPlanStatus',
  FILE_NAME = 'fileName',
  LICENSE_PLATE = 'licensePlate',
  IMEI = 'imei',
  ATTACHED_VEHICLE_NAME = 'attachedVehicleName',
  //Search types for the User List View V0.5
  USER_EMAIL = 'userEmail',
  USER_NAME = 'userName',
  USER_FLEET = 'userFleet',
}

export enum Efficiency {
  KWH100MI = 'kwh100mi',
  MIKWH = 'miKwh',
  MPGE = 'mpge',
}

export enum SearchConfigType {
  SEARCH_VIEW = 'Search View',
}

export const HOUR_ABBR = 'h';

export enum MessagingType {
  HUB = 'hub',
  FLEET = 'fleet',
}

export type ColumnLabel = { [index: string]: string };

export enum QueryParam {
  ORGANIZATIONS_ID = 'organizationsId',
  HUBS_ID = 'hubsId',
  FLEETS_ID = 'fleetsId',
  ASSETS_ID = 'assetsId',
  INSPECTIONS_ID = 'inspectionsId',
  ORDERS_ID = 'ordersId',
}
