import { Box } from '@mui/material';

import bgImageV3 from '../../assets/bgImageV3.png';

const HeroImage = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bgImageV3})`,
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

export default HeroImage;
