import { PublicClientApplication } from '@azure/msal-browser';

import { msalCdiConfig, msalConfig } from '~/common/configs/auth.config';

import { AuthenticationType } from './model/authentication.model';

export const msalInstance = new PublicClientApplication(msalConfig);
export const msalCdiInstance = new PublicClientApplication(msalCdiConfig);

//instances need to be initialized before calling msal api
//please look at https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/c7e042d457f6a1f464bb342c27a04b28f928880f/lib/msal-browser/docs/errors.md#uninitialized_public_client_application for more details
msalInstance.initialize().then();
msalCdiInstance.initialize().then();

export const getMsalInstance = (authType: AuthenticationType) => {
  switch (authType) {
    case AuthenticationType.SSO:
      return msalInstance;
    case AuthenticationType.CDI:
      return msalCdiInstance;
  }
};
