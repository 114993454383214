import { IconButton } from '@brightdrop/bd-ui';
import { Popover } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/app/store';
import useGetAsyncPagedResults from '~/common/hooks/useGetAsyncPagedResults';
import useSessionId from '~/common/hooks/useSessionId';
import {
  AlertSessionViewType,
  DEFAULT_MAX_ALERTS_REQUEST_SIZE,
  getAlerts,
  setUnreadCount,
} from '~/features/alerts/alertsSlice';
import { selectAlertsForUnreadCount } from '~/features/alerts/alertsSlice.selectors';

import AlertRedCircleIcon from '../../../../assets/icons/new/alertRedCircleIcon.svg?react';
import NotificationsIcon from '../../../../assets/icons/notificationsIcon.svg?react';
import NotificationList from '../NotificationList/NotificationList';
import { PubSubProvider, usePubSubContext } from './Context/PubSubProvider';
import useStyles from './NotificationBadge.styles';

const Component = () => {
  const dispatch = useAppDispatch();
  const unreadCount = useSelector(selectAlertsForUnreadCount); // Get unread count from Redux store
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<HTMLElement | null>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const sessionId = useSessionId(true, true);
  const { classes } = useStyles();
  const { hasUnreadEvent } = usePubSubContext();

  const handleClickNotification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsPopoverOpen(true);
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setIsPopoverOpen(false);
    setNotificationAnchorEl(null);
  };

  const hasUnreadNotifications = useMemo(
    () => unreadCount.hasUnread,
    [unreadCount.hasUnread]
  );

  const dispatchGetAlerts = useCallback(
    (page) =>
      dispatch(
        getAlerts({
          sessionId,
          page,
          viewType: AlertSessionViewType.SUBSCRIPTION,
          rowsPerPage: DEFAULT_MAX_ALERTS_REQUEST_SIZE,
        })
      ),
    [dispatch, sessionId]
  );

  const { getAllResults } = useGetAsyncPagedResults({
    action: dispatchGetAlerts,
    rowsPerPage: DEFAULT_MAX_ALERTS_REQUEST_SIZE,
  });

  useEffect(() => {
    const fetchData = async () => {
      const results = await getAllResults();
      const unreadCount = results?.filter((item) => !item.read).length || 0;

      // Determine if there are any unread notifications
      const hasUnread = unreadCount > 0;

      // Dispatch the action to update both hasUnread and approximateCount
      dispatch(setUnreadCount({ hasUnread, approximateCount: unreadCount }));
    };
    fetchData().finally(() => {});
  }, [getAllResults, dispatch]);

  const generateNotificationIconClassname = () => {
    const selectedClass = isPopoverOpen ? 'selected' : '';
    return `${classes.notificationIconButton} ${selectedClass}`;
  };
  const NotificationIcon = () => (
    <IconButton
      size="medium"
      data-testid="notifications-icon"
      aria-label="Notifications"
      aria-haspopup="true"
      onClick={handleClickNotification}
      className={generateNotificationIconClassname()}
      icon={<NotificationsIcon />}
    />
  );

  const UnreadNotificationIcon = () => (
    <IconButton
      size="medium"
      data-testid="notifications-icon"
      aria-haspopup="true"
      aria-label="Unread Notifications"
      onClick={handleClickNotification}
      className={generateNotificationIconClassname()}
      icon={
        <>
          <NotificationsIcon />
          <AlertRedCircleIcon className={classes.alertRedCircleIcon} />
        </>
      }
    />
  );

  let Icon = <NotificationIcon />;
  if (hasUnreadNotifications || hasUnreadEvent) {
    Icon = <UnreadNotificationIcon />;
  }

  return (
    <div>
      {Icon}
      <Popover
        open={!!notificationAnchorEl}
        anchorEl={notificationAnchorEl}
        classes={{ paper: classes.popoverContent }}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationList
          handleCloseNotification={handleCloseNotification}
          insidePopover={true}
        />
      </Popover>
    </div>
  );
};

const NotificationBadge = () => {
  return (
    <PubSubProvider>
      <Component />
    </PubSubProvider>
  );
};

export default NotificationBadge;
