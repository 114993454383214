import { PeripheralType } from '~/common/models/peripheral.model';

export const DEFAULT_PERIPHERAL_TYPE: PeripheralType =
  PeripheralType.FLEETCAMERA;

export const enum PeripheralParamKey {
  TYPE = 'type',
  STATUS = 'status',
  VIN = 'vin',
  IMEI = 'imei',
  LICENSE_PLATE = 'licensePlate',
  ATTACHED_VEHICLE_NAME = 'attachedVehicleName',
  LAST_COMM_DATE_START = 'lastCommunicatedDateStart',
  LAST_COMM_DATE_END = 'lastCommunicatedDateEnd',
  SIZE = 'size',
}

export const OLDEST_POSSIBLE_DATE = '1970-01-01T00:00:00Z';
export const ERROR_MSG_MAPPING_PERIPHERALS =
  'Failed to map peripheral API item';
export const ERROR_MSG_UNEXPECTED_RESPONSE = 'Unexpected peripherals response';
export const ERROR_MSG_UNEXPECTED_ONBOARD_RESPONSE =
  'Unexpected Peripherals Onboard Response';
export const ERROR_MSG_GET_PERIPHERALS_ITEMS = 'Get Peripherals Items';
export const SLIDE_IN_OUT_TIMEOUT = 500;
export const FIRST_PAGE_INDEX = 0;
export const SELECT_ALL_MAX_COUNT = 100;
