import {
  AssetConnectivityStatus,
  AssetModel,
  AssetReportType,
  AssetRequestFieldNames,
  AssetServiceStatus,
  BatteryRange,
  DistanceUnit,
} from './asset.model';
import { ConnectivityStatusType, Entity, PartialEntity } from './common.model';
import { Fleet } from './fleet.model';
import { Hub } from './hub.model';
import { Organization } from './organization.model';
import { FieldActionSummary } from './recalls.model';

export type LastCommunicated = 'Online' | 'Offline';

export enum AssetReportFieldType {
  ASSET_ID = 'id',
  ASSET_NAME = 'name',
  LICENSE_PLATE = 'licensePlate',
  FLEET_ID = 'fleetId',
  HUB_ID = 'hubId',
  ORG_ID = 'organizationId',
  BATTERY_VALUE = "statuses.battery['value']",
  BRAKE_FLUID_STATUS = "diagnostics.fluid_low_brake['value']",
  WASHER_FLUID_STATUS = "diagnostics.fluid_low_washer['value']",
  TIRE_STATUS = "diagnostics.tire_flat['value']",
  ASSET_TYPE = 'deviceType.model',
  ESTIMATED_RANGE = "statuses.estimated_range['value']['value']",
  READY_STATUS = 'ready',
  FAULT_COUNT = 'faultCount',
  LEFT_REAR_TIRE = "statuses.tire_driver_rear_act['value']",
  LEFT_FRONT_TIRE = "statuses.tire_driver_front_act['value']",
  RIGHT_FRONT_TIRE = "statuses.tire_pass_front_act['value']",
  RIGHT_REAR_TIRE = "statuses.tire_pass_rear_act['value']",
  FRONT_EXP_TIRE = "statuses.tires_front_exp['value']",
  BATTERY_CHARGING = "statuses.charge_active['value']",
  CHARGING_STATUS = 'battery.chargeState',
  CHARGING_POWER_TYPE = 'battery.chargePowerType',
  LAST_COMMUNICATED = 'lastCommunicated',
  FLAT_TIRE = TIRE_STATUS,
  LOW_TIRE = "diagnostics.tire_low['value']",
  SAFETY_COMPLIANT = 'state.safetyCompliant',
  ASSET_STATE_AVAILABILITY = 'state.availability',
  DIAGNOSTIC_HEALTH = 'state.diagnostics',
  //TODO: Confirm below field names with backend
  CONNECTIVITY_STATUS = 'connectivity',
  FAULT_STATUS = 'state.mil',
  LAST_UPDATED = LAST_COMMUNICATED,
  FUEL_VALUE = "statuses.fuel_level['value']",
  OIL_LIFE_VALUE = "statuses.oil_life['value']",
  MEDIUM_OIL_LIFE_STATUS = "diagnostics.oil_life_yellow['value']",
  LOW_OIL_LIFE_STATUS = "diagnostics.oil_life_red['value']",
  BRAKE_PAD_FRONT_VALUE = "statuses.brake_pad_life_front_pct['value']",
  MEDIUM_BRAKE_PAD_FRONT_STATUS = "diagnostics.brake_pad_life_front_yellow['value']",
  LOW_BRAKE_PAD_FRONT_STATUS = "diagnostics.brake_pad_life_front_red['value']",
  BRAKE_PAD_REAR_VALUE = "statuses.brake_pad_life_rear_pct['value']",
  MEDIUM_BRAKE_PAD_REAR_STATUS = "diagnostics.brake_pad_life_rear_yellow['value']",
  LOW_BRAKE_PAD_REAR_STATUS = "diagnostics.brake_pad_life_rear_red['value']",
  ENGINE_AIR_FILTER_VALUE = "statuses.engine_air_filter_life_in_pct['value']",
  MEDIUM_ENGINE_AIR_FILTER_STATUS = "diagnostics.engine_air_filter_yellow['value']",
  LOW_ENGINE_AIR_FILTER_STATUS = "diagnostics.engine_air_filter_red['value']",
  CONNECTIVITY_BOARDING_STATUS = "statuses.vehicleDataConnectivityBoardingStatus['value']",
  CONNECTIVITY_PROVISIONING = 'state.provisioning',
  CONNECTIVITY_PRIMARY_OWNERSHIP_STATUS = 'organizationPrimaryOwnershipStatus',
  LOW_DIESEL_EXHAUST_FLUID = "diagnostics.diesel_exhaust_red['value']",
  GOOD_DIESEL_EXHAUST_FLUID = "diagnostics.diesel_exhaust_green['value']",
  RECALLS_STATUS = 'fieldActionTypes',
  AVAILABILITY_STATUS = 'availability',
  CRITICAL_DIAGNOSTICS = 'state.diagnosticsCritical',
  WARNING_DIAGNOSTICS = 'state.diagnosticsImpaired',
}
export enum AssetComplianceFieldType {
  SAFETY_COMPLIANT = 'safetyCompliance.value',
  CHARGING_COMPLIANT = 'chargingCompliance.value',
}
export const ASSET_REPORT_FIELD_NAMES: AssetRequestFieldNames = {
  id: AssetReportFieldType.ASSET_ID,
  name: AssetReportFieldType.ASSET_NAME,
  asset: AssetReportFieldType.ASSET_NAME,
  licensePlate: AssetReportFieldType.LICENSE_PLATE,
  organizationId: AssetReportFieldType.ORG_ID,
  hubId: AssetReportFieldType.HUB_ID,
  fleetId: AssetReportFieldType.FLEET_ID,
  type: AssetReportFieldType.ASSET_TYPE,
  ready: AssetReportFieldType.READY_STATUS,
  connectivity: AssetReportFieldType.CONNECTIVITY_STATUS,
  battery: AssetReportFieldType.BATTERY_VALUE,
  brakeFluid: AssetReportFieldType.BRAKE_FLUID_STATUS,
  washerFluid: AssetReportFieldType.WASHER_FLUID_STATUS,
  tires: AssetReportFieldType.TIRE_STATUS,
  estimatedRange: AssetReportFieldType.ESTIMATED_RANGE,
  faultCount: AssetReportFieldType.FAULT_COUNT,
  leftRearTire: AssetReportFieldType.LEFT_REAR_TIRE,
  leftFrontTire: AssetReportFieldType.LEFT_FRONT_TIRE,
  rightFrontTire: AssetReportFieldType.RIGHT_FRONT_TIRE,
  rightRearTire: AssetReportFieldType.RIGHT_REAR_TIRE,
  batteryCharging: AssetReportFieldType.BATTERY_CHARGING,
  safetyCompliant: AssetReportFieldType.SAFETY_COMPLIANT,
  milState: AssetReportFieldType.FAULT_STATUS,
  lastUpdated: AssetReportFieldType.LAST_UPDATED,
  availability: AssetReportFieldType.ASSET_STATE_AVAILABILITY,
  diagnosticHealth: AssetReportFieldType.DIAGNOSTIC_HEALTH,
  fuel: AssetReportFieldType.FUEL_VALUE,
  oilLife: AssetReportFieldType.OIL_LIFE_VALUE,
  brakePadFront: AssetReportFieldType.BRAKE_PAD_FRONT_VALUE,
  brakePadRear: AssetReportFieldType.BRAKE_PAD_REAR_VALUE,
  engineAirFilter: AssetReportFieldType.ENGINE_AIR_FILTER_VALUE,
  connectivityBoardingStatus: AssetReportFieldType.CONNECTIVITY_BOARDING_STATUS,
  fieldActionTypeStatus: AssetReportFieldType.RECALLS_STATUS,
  availabilityStatus: AssetReportFieldType.AVAILABILITY_STATUS,
};

export enum BrakePadStatus {
  GOOD = 'good',
  IMPAIRED = 'impaired',
  CRITICAL = 'critical',
}
export enum BatterySocStatus {
  HIGH_CHARGE = 'info1',
  MEDIUM_CHARGE = 'info0',
  LOW_CHARGE = 'impaired',
  CRITICAL_CHARGE = 'critical',
}

export enum ReadyStatus {
  READY = 'ready',
  NOT_READY = 'not_ready',
  UNKNOWN = 'unknown',
}
export enum BatteryChargingStatus {
  NOT_CHARGING = 'not_charging',
  CHARGING = 'charging',
  WAITING = 'waiting',
  COMPLETED = 'completed',
  INTERRUPTED = 'interrupted',
  NOT_PLUGGED_IN = 'not_plugged_in',
  NO_DATA = 'no_data',
  UNKNOWN = 'unknown',
}

export enum PowerType {
  AC = 'AC',
  DC = 'DC',
  UNKNOWN = 'unknown',
}

export const SUPPORTED_REPORTS = [
  AssetReportType.FLEET_READINESS,
  AssetReportType.BATTERY,
  AssetReportType.FLUIDS,
  AssetReportType.RECALLS,
];

export enum ReportDiagnosticState {
  GOOD = 'good',
  IMPAIRED = 'impaired',
  CRITICAL = 'critical',
  NO_DATA = 'no_data',
  CLEAR = 'clear',
  UNKNOWN = 'unknown',
}

export enum SafetyCompliance {
  COMPLIANT = 'compliant',
  NON_COMPLIANT = 'non_compliant',
}

export enum FuelStatus {
  HIGH_FUEL = 'high',
  MEDIUM_FUEL = 'medium',
  LOW_FUEL = 'low',
  CRITICAL_FUEL = 'critical',
}
export type TiresStatus = {
  pressureInPSI: number;
  state: ReportDiagnosticState;
};
export enum OilLifeStatus {
  GOOD = 'good',
  IMPAIRED = 'impaired',
  CRITICAL = 'critical',
}

export enum VehiclePropulsion {
  EV = 'EV',
  ICE = 'ICE',
  HEV = 'HEV',
  PHEV = 'PHEV',
}
export enum LastCommunicatedStatus {
  WITHIN_1HR = 'within_1hr',
  WITHIN_1D = 'within_1day',
  WITHIN_1W = 'within_1week',
  WITHIN_M1W = 'within_moreThanWeek',
}
export enum DiagnosticDTCType {
  TIRE = 'tire',
  BRAKE_FLUID = 'brake_fluid',
  ENGINE_AIR_FILTER = 'engine_air_filter',
  WASHER_FLUID = 'washer_fluid',
  BRAKE_PAD = 'brake_pad',
  OIL_LIFE = 'oil_life',
  FUEL_LEVEL = 'fuel_level',
  BATTERY = 'battery',
  DIESEL_EXHAUST_FLUID = 'diesel_exhaust',
  AIRBAG = 'airbag',
  ABS = 'antilock_braking',
  ONSTAR = 'onstar',
  ENGINE_AND_TRANSMISSION = 'engine_and_transmission',
  EMISSIONS = 'emissions',
  STABILITY = 'stability_control',
  ELECTRIC_DRIVE_UNIT = 'electric_drive',
  ELECTRIC_LAMP = 'electric_lamps',
  BATTERY_SYSTEM = 'high_voltage_battery',
}
type OmittedKeys = 'fuel_level' | 'battery';

export type AllowedDiagnosticDTCType = Exclude<DiagnosticDTCType, OmittedKeys>;

export type EstimatedRangedInKM = {
  value: number;
  unitOfMeasure: DistanceUnit;
};
export type VehicleDetailsType = {
  make?: string;
  year?: number;
  model?: string;
  propulsion?: VehiclePropulsion;
};
export interface ApiDiagnostic {
  state: ReportDiagnosticState;
  type: DiagnosticDTCType;
  deviceTimestamp: string;
}
export interface Diagnostic {
  state: ReportDiagnosticState;
  type: AllowedDiagnosticDTCType;
  deviceTimestamp: string;
}
export interface AssetReportItem extends Entity {
  type: AssetModel;
  organization?: PartialEntity<Organization>;
  hub?: PartialEntity<Hub>;
  fleet?: PartialEntity<Fleet>;
  connectivity?: AssetConnectivityStatus;
  lastUpdated?: string;
  ready?: boolean;
  faultCount?: number;
  battery?: Partial<{
    isCharging: boolean;
    range: BatteryRange;
    value: number;
    estimatedRange?: EstimatedRangedInKM;
    lastUpdated?: string;
  }>;
  brakeFluid?: ReportDiagnosticState;
  washerFluid?: ReportDiagnosticState;
  leftRearTire?: TiresStatus;
  leftFrontTire?: TiresStatus;
  rightFrontTire?: TiresStatus;
  rightRearTire?: TiresStatus;
  estimatedRange?: EstimatedRangedInKM;
  tires?: ReportDiagnosticState;
  safetyCompliant?: boolean;
  milState?: ReportDiagnosticState;
  diagnosticHealth?: ReportDiagnosticState | null;
  availability?: AssetServiceStatus;
  chargingStatus?: BatteryChargingStatus;
  powerType?: PowerType;
  targetSOC?: {
    value?: number;
    estimatedRange?: EstimatedRangedInKM;
  };
  minutesToTargetSOC?: {
    value?: number;
    deviceTimestamp?: string;
  };
  fuel?: {
    value: number | undefined;
    state: ReportDiagnosticState | undefined;
  };
  licensePlate?: string;
  oilLife?: {
    value: number | undefined;
    state: ReportDiagnosticState | undefined;
  };
  brakePadRear?: {
    value: number | undefined;
    state: ReportDiagnosticState | undefined;
  };
  brakePadFront?: {
    value: number | undefined;
    state: ReportDiagnosticState | undefined;
  };
  engineAirFilter?: {
    value: number | undefined;
    state: ReportDiagnosticState | undefined;
  };
  connectivityBoardingStatus?: ConnectivityStatusType;
  vehicleType?: VehicleDetailsType;
  lastCommunicated?: {
    deviceTimestamp: string | undefined;
    status: LastCommunicated | undefined;
  };
  diagnostics?: Diagnostic[];
  fieldActionSummary?: FieldActionSummary;
}
