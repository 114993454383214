import { useFeatureFlags } from '@brightdrop/feature-flags-client';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/app/store';
import BDDialog from '~/common/components/BDDialog/BDDialog';
import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { PROTECT_YOUR_ACCOUNT_WARNING_FLAG } from '~/common/models/featureFlags.model';
import { selectProfileUserPreferences } from '~/features/profile/profileSlice.selectors';
import ProtectYourAccountModal from '~/features/protectYourAccount/ProtectYourAccountModal';
import { setFirstSignIn } from '~/features/session/authSlice';
import { selectFirstSignIn } from '~/features/session/authSlice.selectors';

const ProtectYourAccountModalWrapper = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { getFlag } = useFeatureFlags();
  const isFeatureFlagEnabled = getFlag(PROTECT_YOUR_ACCOUNT_WARNING_FLAG);
  const isFirstSignIn = useSelector(selectFirstSignIn);
  const doNotShowLegalNotice = useSelector(
    selectProfileUserPreferences
  )?.doNotShowLegalNotice;
  const shouldModalOpen =
    isFirstSignIn !== undefined &&
    doNotShowLegalNotice !== undefined &&
    isFeatureFlagEnabled
      ? !doNotShowLegalNotice && isFirstSignIn
      : false;
  const [isProtectYourAccountModalOpen, setIsProtectYourAccountModalOpen] =
    useState<boolean>(false);

  const onProtectYourAccountModalClose = useCallback(() => {
    setIsProtectYourAccountModalOpen(false);
    dispatch(setFirstSignIn(false));
  }, [dispatch]);

  useEffect(() => {
    setIsProtectYourAccountModalOpen(shouldModalOpen);
  }, [shouldModalOpen]);

  return (
    <>
      <BDDialog
        openModal={isProtectYourAccountModalOpen}
        title={translateText('account:protectYourAccount')}
        onCloseModal={onProtectYourAccountModalClose}
        component={ProtectYourAccountModal}
      />
    </>
  );
};

export default ProtectYourAccountModalWrapper;
