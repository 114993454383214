import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  overviewWrapper: {
    padding: '16px 0',
    marginTop: '16px',
  },
  displayDate: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
    width: '100%',
    '& .MuiTabs-flexContainer': {
      display: 'flex',
    },
    '& .MuiButtonBase-root': {
      padding: '0 12px',
      margin: '0',
      '&.Mui-selected .tab': {
        backgroundColor: theme.palette.brand.lighter,
        color: theme.palette.text.secondary,
      },
      '&:hover .tab': {
        backgroundColor: theme.palette.brand.light,
        color: theme.palette.text.secondary,
      },
      '& .tab': {
        '& svg path': { fill: 'none' },
        borderRadius: '12px',
        width: '100%',
        height: '100%',
        padding: '12px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        textAlign: 'left',
        minHeight: '104px',
        alignSelf: 'stretch',
      },
      '& .value': {
        fontSize: '40px',
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'baseline',
      },
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.border}`,
        margin: '0',
      },
      '& svg path': { fill: 'none' },
    },
  },
}));

export default useStyles;
