import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  spinner: {
    height: '4px',
    width: '99%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '642px',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    backgroundColor: theme.palette.brand.main,
    '& .MuiLinearProgress-bar1Indeterminate': {
      backgroundColor: theme.palette.common.white,
      mixBlendMode: 'normal',
      opacity: 0.6,
    },
    '& .MuiLinearProgress-bar2Indeterminate': {
      backgroundColor: theme.palette.common.white,
      mixBlendMode: 'normal',
      opacity: 0.6,
    },
  },
}));

export default useStyles;
