import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  newVersionAlert: {
    backgroundColor: theme.palette.text.primary,
    '& .MuiAlert-icon': {
      display: 'block',
      marginRight: '8px',
      height: '28px',
    },
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
      marginBottom: '0px',
    },
    '& .MuiAlert-message': {
      color: theme.palette.common.white,
    },
    '& .MuiAlert-action': {
      padding: '0 8px 0 12px',
      '& button': {
        backgroundColor: theme.palette.brand.main,
        color: theme.palette.common.white,
        padding: '4px 16px',
        height: '40px',
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          '& .MuiCircularProgress-root': {
            marginLeft: '10px',
            '& svg circle': {
              stroke: theme.palette.text.disabled,
            },
          },
        },
      },
    },
  },
}));
export default useStyles;
