import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactElement } from 'react';

import AlertIcon from '~/assets/icons/new/alert.svg?react';
import GoodIcon from '~/assets/icons/new/checkCircle.svg?react';
import ErrorIcon from '~/assets/icons/new/error.svg?react';
import FuelIcon from '~/assets/icons/new/fuelIcon.svg?react';
import { BDFilterModalOptionConfig } from '~/common/components/BDFilter/BDFilterModal';
import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { AssetReportType } from '~/common/models/asset.model';
import {
  ReportDiagnosticState,
  VehicleDetailsType,
} from '~/common/models/asset-report.model';
import { ConnectivityStatusType } from '~/common/models/common.model';
import {
  DAY,
  format,
  formatDuration,
  formatDurationTime,
  MINUTE,
} from '~/common/utils/date-time.utils';

import { AssetListFilterCriteria } from '../../assetsSlice';
import { ASSET_LIST_REPORT_FILTER_MESSAGES } from '../AssetListReportViewFilterModal.messages';
import {
  getBatteryFilterModalConfig,
  getFluidsFilterModalConfig,
  getReadinessFilterModalConfig,
  getRecallsFilterModalConfig,
  getTiresFilterModalConfig,
} from '../configs/AssetListReportViewFilter.config';

export const getReportFilterModalColumnsConfig = (
  selectedReport: AssetReportType,
  filterLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.filterLabels,
  valueLabels: typeof ASSET_LIST_REPORT_FILTER_MESSAGES.valueLabels,
  hideAvailabilityFilter: boolean,
  isChargeStatusFlagEnabled: boolean,
  isDiagnosticsFlagEnabled: boolean
): BDFilterModalOptionConfig<AssetListFilterCriteria>[] => {
  switch (selectedReport) {
    case AssetReportType.FLEET_READINESS:
      return getReadinessFilterModalConfig(
        filterLabels,
        valueLabels,
        hideAvailabilityFilter,
        isDiagnosticsFlagEnabled
      );
    case AssetReportType.FLUIDS:
      return getFluidsFilterModalConfig(
        filterLabels,
        valueLabels,
        hideAvailabilityFilter,
        isDiagnosticsFlagEnabled
      );
    case AssetReportType.BATTERY:
      return getBatteryFilterModalConfig(
        filterLabels,
        valueLabels,
        hideAvailabilityFilter,
        isChargeStatusFlagEnabled
      );
    case AssetReportType.TIRE_PRESSURE:
      return getTiresFilterModalConfig(
        filterLabels,
        valueLabels,
        hideAvailabilityFilter
      );
    case AssetReportType.RECALLS:
      return getRecallsFilterModalConfig(filterLabels, valueLabels);
    default:
      return getReadinessFilterModalConfig(
        filterLabels,
        valueLabels,
        hideAvailabilityFilter,
        isDiagnosticsFlagEnabled
      );
  }
};

export const formatLastDateCommunicated = (
  lastCommunication?: string,
  unitDisplay: 'short' | 'long' | 'narrow' = 'short',
  userLocale?: string
): string | undefined => {
  const offlineThreshold = DateTime.now().toJSDate();
  return lastCommunication &&
    new Date(lastCommunication).getTime() < offlineThreshold.getTime()
    ? formatDuration(
        offlineThreshold,
        new Date(lastCommunication),
        userLocale,
        unitDisplay
      )
    : undefined;
};

export const formatEstTimeToCompletion = (
  timeFormat: string,
  duration?: number,
  locale?: string,
  timeZone?: string,
  date?: string,
  isListView = false
) => {
  if (typeof duration === 'number') {
    if (duration === 0) {
      return DEFAULT_EMPTY_VALUE;
    }
    const durationInMs = duration * MINUTE;
    if (durationInMs > DAY) {
      return '24h +';
    }
    const estTime = date
      ? new Date(date).setMilliseconds(durationInMs)
      : undefined;
    const updatedDate = isListView ? (date ? date : undefined) : estTime;
    return updatedDate
      ? `${formatDurationTime(durationInMs)} (${format(
          new Date(updatedDate),
          timeFormat,
          locale,
          timeZone
        )})`
      : `${formatDurationTime(durationInMs)}`;
  }
  return DEFAULT_EMPTY_VALUE;
};

export const getDiagnosticModalLabel = <T extends string>(
  FILTER_MODAL_RANGE: {
    [k in T]: {
      start?: number;
      end?: number;
      inclusiveStart?: boolean;
      inclusiveEnd?: boolean;
    };
  },
  status: T
) => {
  const range = FILTER_MODAL_RANGE[status];
  if (range?.start && range?.end) {
    return `${range?.start}% - ${range?.end}%`;
  } else if (range?.start && !range?.end) {
    return `> ${range.start}%`;
  } else if (!range?.start && range?.end) {
    return `< ${range.end}%`;
  }
  return '';
};
export const getFuelLevelDiagnosticStatus = (
  testId: string,
  status?: ReportDiagnosticState,
  value?: string
): ReactElement => {
  switch (status) {
    case ReportDiagnosticState.GOOD:
    case ReportDiagnosticState.IMPAIRED:
    case ReportDiagnosticState.CRITICAL:
      return (
        <Box
          data-testid={`${status}-diagnostic-status`}
          display="flex"
          alignItems="center"
        >
          <FuelIcon
            className={`${status}-diagnostic-color`}
            data-testid={`vehicle-list-diagnostic-${testId}-status-icon`}
          />
          {value && (
            <Box
              className={'valueStatus'}
              component="span"
              ml="5px"
              data-testid={`fuel-level-diagnostic-${status}`}
            >
              {value}
            </Box>
          )}
        </Box>
      );

    case ReportDiagnosticState.NO_DATA:
    case ReportDiagnosticState.UNKNOWN:
    case ReportDiagnosticState.CLEAR:
      return (
        <Box data-testid="no-data-diagnostic-status">{DEFAULT_EMPTY_VALUE}</Box>
      );
    default:
      return (
        <Box data-testid="no-data-diagnostic-status">{DEFAULT_EMPTY_VALUE}</Box>
      );
  }
};
export const getReportDiagnosticStatus = (
  testId: string,
  status?: ReportDiagnosticState,
  value?: string
): ReactElement => {
  switch (status) {
    case ReportDiagnosticState.GOOD:
      return (
        <Box
          data-testid={`${status}-diagnostic-status`}
          display="flex"
          alignItems="center"
        >
          <GoodIcon
            className={`${status}-diagnostic-color`}
            data-testid={`vehicle-list-diagnostic-${testId}-status-icon`}
          />
          {value && (
            <Box
              component="span"
              ml="5px"
              data-testid={`vehicle-list-diagnostic-${testId}-diagnostic-status`}
            >
              {value}
            </Box>
          )}
        </Box>
      );
    case ReportDiagnosticState.IMPAIRED:
      return (
        <Box
          data-testid={`${status}-diagnostic-status`}
          display="flex"
          alignItems="center"
        >
          <AlertIcon className={`${status}-diagnostic-color`} />
          {value && (
            <Box component="span" ml="5px">
              {value}
            </Box>
          )}
        </Box>
      );
    case ReportDiagnosticState.CRITICAL:
      return (
        <Box
          data-testid={`${status}-diagnostic-status`}
          display="flex"
          alignItems="center"
        >
          <ErrorIcon className={`${status}-diagnostic-color`} />
          {value && (
            <Box component="span" ml="5px">
              {value}
            </Box>
          )}
        </Box>
      );
    case ReportDiagnosticState.NO_DATA:
    case ReportDiagnosticState.UNKNOWN:
    case ReportDiagnosticState.CLEAR:
      return (
        <Box data-testid="no-data-diagnostic-status">{DEFAULT_EMPTY_VALUE}</Box>
      );
    default:
      return (
        <Box data-testid="no-data-diagnostic-status">{DEFAULT_EMPTY_VALUE}</Box>
      );
  }
};

export const getConnectivityBoardingStatusStatus = (
  status: ConnectivityStatusType | undefined,
  value: string | undefined
): ReactElement =>
  status ? (
    <Box
      data-testid={`vehicle-list-connectivity-status-${status}`}
      display="flex"
      alignItems="center"
    >
      <Box>
        <Box className={`dot ${status}-connectivity-status`}></Box>
      </Box>
      {value && (
        <Box ml="3px" width={'100px'}>
          {value}
        </Box>
      )}
    </Box>
  ) : (
    <Box data-testid={`vehicle-list-connectivity-status-undefined`}>
      {DEFAULT_EMPTY_VALUE}
    </Box>
  );
export const getVehicleTypeDetails = (type: VehicleDetailsType) => {
  const { year, make, model } = type;
  const values = [year, make, model];
  return values
    .filter((value) => value !== null && value !== undefined)
    .join(' ');
};
export const getFuelChargeFilterModalLabel = <T extends string>(
  FILTER_MODAL_RANGE: {
    [k in T]: {
      start?: number;
      end?: number;
      inclusiveStart?: boolean;
      inclusiveEnd?: boolean;
    };
  },
  status: T
) => {
  const range = FILTER_MODAL_RANGE[status];
  if (range?.start && range?.end) {
    return `${range?.start}% - ${range?.end}%`;
  } else if (range?.start && !range?.end) {
    return `${range.start}% ${translateText('asset:orMore')}`;
  } else if (!range?.start && range?.end) {
    return `${range.end}% ${translateText('asset:orLess')}`;
  }
  return '';
};
