import { Button, DialogComponentProps } from '@brightdrop/bd-ui';
import { Role } from '@gm-commercial/profile-model';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { API_VERSION_DEFAULTS } from '~/common/apis/api.constants';
import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { uppercaseLiteral } from '~/common/utils/common.utils';
import { selectProfile } from '~/features/profile/profileSlice.selectors';
import { selectAuthToken } from '~/features/session/authSlice.selectors';

import useStyles from './ProtectYourAccountModal.styles';

const ProtectYourAccountModal = ({
  onCloseModal,
}: DialogComponentProps): JSX.Element => {
  const { classes } = useStyles();
  const protectYourAccountWarningMessage = translateText(
    'account:protectYourAccountWarning'
  );

  const [doNotShowModal, setDoNotShowModal] = useState<boolean>(false);
  const profile = useSelector(selectProfile);
  const indexOfRole =
    profile?.role !== undefined
      ? Object.values(Role).indexOf(profile?.role)
      : -1;
  //Setting Role in API Request to be what the API Service expects such as ORG_MANAGER, FLEET_MANAGER, etc.
  const userRole = Object.keys(Role)[indexOfRole] || null;
  //Setting status to uppercase as it is lower cased in the profile.mapper.ts file
  const userStatus =
    profile?.status !== undefined ? uppercaseLiteral(profile?.status) : null;
  const userLocale =
    profile?.userPreferences?.locale !== undefined
      ? profile?.userPreferences?.locale
      : null;
  const auth = useSelector(selectAuthToken);

  const doNotShowAgainMessage = translateText('account:doNotShowAgainMessage');
  const okButtonText = translateText('common:OK');

  const handleOkButtonClick = useCallback(async () => {
    if (doNotShowModal) {
      await fetch(
        `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.profileApiLatest}/${profile?.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${auth?.value}`,
          },
          body: JSON.stringify({
            //Role needs to return enum key instead of value, as there's no such thing as Account Admin or Advisor in backend
            role: userRole,
            status: userStatus,
            userPreferences: {
              locale: userLocale,
              doNotShowLegalNotice: true,
            },
          }),
        }
      );
    }
    onCloseModal(true);
  }, [
    auth?.value,
    doNotShowModal,
    onCloseModal,
    profile?.id,
    userLocale,
    userRole,
    userStatus,
  ]);

  const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoNotShowModal(event.target.checked);
  };

  return (
    <>
      <Typography
        variant="body_regular"
        className={classes.protectYourAccountWarningMessage}
      >
        {protectYourAccountWarningMessage}
      </Typography>
      <Box display="flex" flexDirection="column">
        <FormControlLabel
          control={
            <Checkbox
              checked={doNotShowModal}
              onChange={handleCheckBoxClick}
              className={classes.checkBox}
            />
          }
          label={doNotShowAgainMessage}
        />
        <div className={classes.buttonContainer}>
          <Button onClick={handleOkButtonClick}>{okButtonText}</Button>
        </div>
      </Box>
    </>
  );
};

export default ProtectYourAccountModal;
