import { makeStyles } from 'tss-react/mui';

interface WidgetCardStyleProps {
  widgetContentSkeletonHeight?: number;
}
const useStyles = makeStyles<WidgetCardStyleProps>()(
  (theme, { widgetContentSkeletonHeight }) => ({
    paper: {
      padding: '15px',
      width: '100%',
      height: '100%',
      '&.clickable': {
        cursor: 'pointer',
      },
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '15px',
      '& .MuiTypography-h4': {
        textTransform: 'uppercase',
        fontSize: '0.825rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '0.8rem',
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '0.875rem',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '1rem',
        },
      },
    },
    icon: {
      width: '16px',
      marginRight: '10px',
      '& svg': {
        height: '16px',
        '& > path': {
          fill: theme.palette.svgIcon.main,
        },
      },
      display: 'flex',
      backgroundSize: 'contain',
      '&.success path': {
        fill: theme.palette.success.main,
      },
      '&.error path': {
        fill: theme.palette.error.main,
      },
      '&.warning path': {
        fill: theme.palette.warning.main,
      },
      '&.none path': {
        fill: theme.bdColors.darkBlue,
      },
      [theme.breakpoints.up('lg')]: {
        width: '24px',
        '& svg': {
          height: '24px',
        },
      },
    },
    cardContainer: {
      width: '100%',
      height: '100%',
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        '& section.contentInner': {
          width: '100%',
          '& > div': {
            width: '100%',
            flexDirection: 'column',
            gap: '8px',
            marginRight: '0',
          },
        },
      },
      '&.bottomHeader': {
        margin: 0,
        padding: 0,
        justifyContent: 'space-between',
        '& >': {
          '&:last-of-type': {
            order: -1,
          },
          '&:first-of-type': {
            justifyContent: 'center',
            margin: '5px 0',
          },
        },

        '&.leftHeader >:first-of-type': {
          justifyContent: 'flex-start',
        },
        '& svg': {
          alignSelf: 'flex-start',
        },
      },
      '& .MuiTableCell-body': {
        maxWidth: '200px',
      },
    },
    accordionDetails: {
      flexFlow: 'wrap',
      flexDirection: 'column',
      paddingBottom: '10px',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
      padding: 0,
      '& >div.MuiBox-root': {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          paddingBottom: 0,
        },
      },
    },
    accordionSummary: {
      margin: 0,
      padding: 0,
      paddingTop: '5px',
      minHeight: 'auto',
      '&.Mui-expanded': { minHeight: 'auto' },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
      '& .MuiButtonBase-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    headerSkeleton: {
      margin: '0 20px',
    },
    contentSkeleton: {
      height: widgetContentSkeletonHeight
        ? `${widgetContentSkeletonHeight}px`
        : '145px',
    },
    content: {
      height: '100%',
      '&.bottomHeader': {
        paddingTop: 0,
      },
    },
    count: {
      marginLeft: '0.5rem',
    },
    sortContainer: {
      display: ' flex',
      width: '160px',
      height: '30px',
      borderRadius: '4px',
      marginLeft: '17px',
      '& .MuiSvgIcon-root': {
        opacity: '0.5',
      },
    },
  })
);

export default useStyles;
