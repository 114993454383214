import {
  Box,
  ButtonBase,
  ClickAwayListener,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { MutableRefObject, useRef, useState } from 'react';

import OrganizationIcon from '~/assets/icons/leftMenu/organizationIcon.svg?react';
import ArrowDownIcon from '~/assets/icons/new/arrowDownIcon.svg?react';
import ArrowUpIcon from '~/assets/icons/new/arrowUpIcon.svg?react';
import SelectedIcon from '~/assets/icons/new/checkCircle.svg?react';

import useStyles from './FleetSwitcher.styles';

interface ContextItem {
  id: string;
  name: string;
}

interface FleetSwitcherProps {
  contexts: { root: ContextItem; subContexts: ContextItem[] };
  selectedContext: ContextItem;
  onContextSelect: (item: ContextItem) => void;
  navExpanded: boolean;
}

/*
 * Despite the webkit usage, this is a standard approach for wrapping and truncating
 * long text, per https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
 */
const SX_WRAP_TRUNCATE: SxProps = {
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

/**
 * Displays currently-selected context with test wrapping; click to
 * display full list of available fleets
 */
const SwitcherMenuButton = ({
  buttonRef,
  menuOpen,
  navExpanded,
  handleMenuOpen,
  handleMenuClose,
  selectedContext,
}: {
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  menuOpen: boolean;
  navExpanded: boolean;
  handleMenuOpen: (clicked: boolean) => void;
  handleMenuClose: () => void;
  selectedContext: ContextItem;
}) => {
  const { classes } = useStyles();
  return (
    <ButtonBase
      data-testid="fleet-switcher-menu-button"
      ref={buttonRef}
      onClick={(event) => {
        if (menuOpen) {
          handleMenuClose();
        } else {
          handleMenuOpen(event.type === 'click');
        }
      }}
      onKeyDown={(event) => {
        if (event.key === ' ' || event.key === 'Enter') {
          event.preventDefault();
          handleMenuOpen(false);
        }
      }}
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px',
        borderRadius: '8px',
        width: '100%',
        textAlign: 'left',
        whiteSpace: 'break-spaces',
        backgroundColor: menuOpen
          ? theme.palette.brand.light
          : theme.palette.common.white,
        '&:hover': {
          backgroundColor: menuOpen
            ? theme.palette.brand.light
            : theme.new.color.brand[100],
        },
        '&:focus-visible': {
          outlineOffset: '1px',
          outline: `2px auto ${theme.palette.primary.main}`,
        },
      })}
    >
      <Box width="100%" display="flex" alignItems="center" gap="12px">
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '32px',
            minWidth: '32px',
            backgroundColor: theme.new.color.grey[200],
            borderRadius: '4px',
          })}
        >
          <OrganizationIcon className={classes.buttonIcon} />
        </Box>

        {navExpanded && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body_medium"
              sx={{
                ...SX_WRAP_TRUNCATE,
              }}
            >
              {selectedContext?.name}
            </Typography>
            {menuOpen ? (
              <ArrowUpIcon className={classes.menuToggleIcon} />
            ) : (
              <ArrowDownIcon className={classes.menuToggleIcon} />
            )}
          </Box>
        )}
      </Box>
    </ButtonBase>
  );
};

/**
 * Individual root (org) or sub-context (fleet) menu item; supports text
 * wrapping and selected state
 */
const SelectableListItem = ({
  item,
  selectedContext,
  handleMenuItemClick,
  isRoot = false,
}: {
  item: ContextItem;
  selectedContext: ContextItem;
  handleMenuItemClick: (item: ContextItem) => void;
  isRoot?: boolean;
}) => {
  const { classes } = useStyles();
  return (
    <MenuItem
      data-testid={
        selectedContext.id === item.id
          ? 'fleet-switcher-menu-selected-item'
          : 'fleet-switcher-menu-item'
      }
      selected={selectedContext.id === item.id}
      onClick={(event) => {
        if (isRoot && event.type !== 'click') {
          return; // ignore spacebar keypresses being treated like clicks
        }
        handleMenuItemClick(item);
      }}
      sx={(theme) => ({
        borderRadius: '8px',
        whiteSpace: 'break-spaces',
        '&:focus-visible': {
          backgroundColor: 'white',
        },
        ...(isRoot
          ? {
              position: 'sticky',
              top: 0,
              zIndex: 2,
              flex: 1,
            }
          : {
              margin: '0 20px',
              '&::before': {
                content: '""',
                position: 'absolute',
                left: '-12px',
                top: 0,
                bottom: 0,
                borderLeft: `1px solid ${theme.palette.grey[300]}`,
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                left: '-12px',
                top: '50%',
                width: '8px',
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                transform: 'translateY(-50%)',
              },
              '&:last-of-type': {
                '&::before': {
                  height: '50%',
                },
              },
            }),
      })}
    >
      <ListItemText
        primaryTypographyProps={{
          variant:
            selectedContext.id === item.id ? 'body_medium' : 'body_regular',
          sx: {
            ...SX_WRAP_TRUNCATE,
          },
        }}
        sx={(theme) => ({
          color:
            selectedContext.id === item.id
              ? theme.palette.primary.main
              : theme.palette.text.primary,
        })}
      >
        {item.name}
      </ListItemText>
      {selectedContext.id === item.id && (
        <SelectedIcon
          data-testid="fleet-switcher-menu-selected-item-icon"
          className={classes.selectedItemIcon}
        />
      )}
    </MenuItem>
  );
};

/**
 * Allows users with access to multiple fleet contexts to specify which context the
 * app should use when displaying content which may be filtered/scoped by fleet
 *
 * @see {@link https://dev.azure.com/BrightDrop/BrightDrop/_workitems/edit/51953}
 *
 * @param contexts The available fleet `subContexts` and parent org `root` context
 * @param selectedContextId The currently-selected context
 * @param onContextSelect Handler triggered when a context is selected
 * @param navExpanded If nav is not expanded, display switcher icon only
 */
const FleetSwitcher = ({
  contexts,
  selectedContext,
  onContextSelect,
  navExpanded,
}: FleetSwitcherProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const menuListRef = useRef<HTMLUListElement | null>(null);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  const handleMenuOpen = (clicked: boolean) => {
    setMenuOpen(true);
    if (!clicked) {
      setFocusIndex(0);

      // explicitly focus the first item when opening
      requestAnimationFrame(() => {
        focusItem(0);
      });
    }
  };

  const handleMenuItemClick = (item: ContextItem) => {
    if (item.id !== selectedContext.id) {
      onContextSelect(item);
    }
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setFocusIndex(null);
  };

  /**
   * Handles key-down events within the switcher menu, specifically:
   *   - arrow keys: navigation of menu items
   *   - enter/space: selection of focused menu item
   *   - tab/escape: close menu without selection
   * @param event
   * @returns
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!menuOpen) return;

    if (event.key === 'ArrowDown') {
      event.preventDefault();

      const nextIndex = Math.min(
        (focusIndex ?? 0) + 1,
        contexts.subContexts.length
      );
      setFocusIndex(nextIndex);
      focusItem(nextIndex);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();

      const prevIndex = Math.max((focusIndex ?? 0) - 1, 0);
      setFocusIndex(prevIndex);
      focusItem(prevIndex);
    } else if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      event.preventDefault();

      if (focusIndex !== null) {
        const item =
          focusIndex === 0
            ? contexts.root
            : contexts.subContexts[focusIndex - 1];
        onContextSelect(item);
        handleMenuClose();
      }
    } else if (event.key === 'Escape' || event.key === 'Tab') {
      event.preventDefault();

      handleMenuClose();
    }
  };

  const focusItem = (index: number) => {
    const menuItems =
      menuListRef.current?.querySelectorAll<HTMLElement>("[role='menuitem']");
    const focusTarget = menuItems?.[index];

    if (focusTarget) {
      // ensure focused element is not outside of the visible scroll area before focusing
      scrollIntoView(focusTarget);
      focusTarget.focus();
    }
  };

  /**
   * Compares top and bottom of target element to top and bottom of scrollable container,
   * and adjusts the scroll position for the container to ensure the target element is fully visible
   *
   * Note: since `offsetTop` is measured relative to the item's `offsetParent`, this logic assumes
   * the `scrollBoxRef` element is the parent of the `targetItem` element.
   *
   * @param targetItem item that may need to be scrolled into view
   */
  const scrollIntoView = (targetItem: HTMLElement) => {
    if (!scrollBoxRef.current) return;

    const { offsetTop: targetItemTop, offsetHeight: targetItemHeight } =
      targetItem;

    const {
      scrollTop: scrollContainerTop,
      clientHeight: scrollContainerHeight,
    } = scrollBoxRef.current;

    if (targetItemTop < scrollContainerTop) {
      scrollBoxRef.current.scrollTop = targetItemTop;
    } else if (
      targetItemTop + targetItemHeight >
      scrollContainerTop + scrollContainerHeight
    ) {
      scrollBoxRef.current.scrollTop =
        targetItemTop + targetItemHeight - scrollContainerHeight;
    }
  };

  return (
    <Box
      data-testid="fleet-switcher"
      sx={{ margin: '16px 0px', padding: '0px 8px' }}
    >
      <SwitcherMenuButton
        buttonRef={anchorEl}
        menuOpen={menuOpen}
        navExpanded={navExpanded}
        handleMenuOpen={handleMenuOpen}
        handleMenuClose={handleMenuClose}
        selectedContext={selectedContext}
      />

      {/* fleet switcher menu */}
      <Popper
        data-testid="fleet-switcher-menu"
        open={menuOpen}
        anchorEl={anchorEl.current}
        placement="bottom-start"
        modifiers={[{ name: 'offset', options: { offset: [12, 0] } }]}
        sx={(theme) => ({
          borderRadius: '8px',
          boxShadow: theme.new.boxShadows.elevation2,
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <Paper
            sx={(theme) => ({
              width: '320px',
              zIndex: theme.zIndex.drawer + 1,
              padding: '8px',
              overflow: 'hidden',
            })}
          >
            <MenuList
              ref={menuListRef}
              role="menu"
              aria-labelledby="fleet-switcher"
              onKeyDown={handleKeyDown}
              disablePadding
              sx={(theme) => ({
                // override the override in MuiList.styles.ts
                '& .MuiButtonBase-root.MuiMenuItem-root': {
                  '&.Mui-selected': {
                    backgroundColor: 'white',
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.brand.lighter,
                  },
                  '&:focus-visible': {
                    outlineOffset: '-1px',
                    outline: `2px auto ${theme.palette.primary.main}`,
                  },
                },
              })}
            >
              {/* sticky root context */}
              <SelectableListItem
                item={contexts.root}
                selectedContext={selectedContext}
                handleMenuItemClick={handleMenuItemClick}
                isRoot={true}
              />

              {/* scrollable sub-contexts */}
              <Box
                ref={scrollBoxRef}
                sx={{ maxHeight: '400px', overflowY: 'auto' }}
              >
                {contexts.subContexts.map((subContext) => (
                  <SelectableListItem
                    key={subContext.id}
                    item={subContext}
                    selectedContext={selectedContext}
                    handleMenuItemClick={handleMenuItemClick}
                  />
                ))}
              </Box>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

/**
 * Non-interactive component to be used when user has only a single available context
 *
 * @param selectedContextId The currently-selected context
 * @param navExpanded If nav is not expanded, display switcher icon only
 */
export const StaticFleetSwitcher = ({
  navExpanded,
  selectedContext,
}: {
  navExpanded: boolean;
  selectedContext: ContextItem;
}) => {
  const { classes } = useStyles();
  return (
    <Box
      data-testid="static-fleet-switcher"
      sx={{ margin: '16px 0px', padding: '0px 8px' }}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        gap="12px"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px',
          borderRadius: '8px',
          width: '100%',
          textAlign: 'left',
          whiteSpace: 'break-spaces',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '32px',
            minWidth: '32px',
            backgroundColor: theme.new.color.grey[200],
            borderRadius: '4px',
          })}
          title={navExpanded ? undefined : selectedContext.name}
        >
          <OrganizationIcon className={classes.buttonIcon} />
        </Box>

        {navExpanded && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body_medium"
              sx={{
                ...SX_WRAP_TRUNCATE,
              }}
            >
              {selectedContext.name}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FleetSwitcher;
