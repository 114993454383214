import { EntityState } from '@reduxjs/toolkit';

import {
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  ContinuationCache,
  ListViewSession,
  OperationSession,
  SearchCriteria,
} from '~/common/models/common.model';
import {
  PeripheralListItem,
  PeripheralMoveFleetSummary,
  PeripheralOnboardStatusResponse,
  PeripheralsOnboardResponse,
  PeripheralType,
} from '~/common/models/peripheral.model';
import { PeripheralFilterCriteria } from '~/features/peripherals/state/PeripheralFilter.model';

import { PeripheralRequestType } from './peripheralsSlice';

export enum PeripheralsSessionViewType {
  LIST_VIEW = 'LIST_VIEW',
}

export enum PeripheralRowAction {
  UNASSIGN = 'unassign',
  REMOVE = 'remove',
}
export interface RowActionContent {
  peripheralId: string;
  organizationId: string;
  hubId: string;
  fleetId: string;
  rowAction: PeripheralRowAction;
}

export interface PeripheralsListSession
  extends ListViewSession,
    OperationSession {
  importModalOpen?: boolean;
  onboardState: PeripheralsOnboardResponse;
  selectedIds?: string[];
  selectedItems?: PeripheralListItem[];
  peripherals?: EntityState<PeripheralListItem>;
  allSelected?: boolean;
  onboardStateStatus: PeripheralOnboardStatusResponse;
  errorSnackbarOpen?: boolean;
  rowActionContent?: RowActionContent;
  filterType?: PeripheralFilterCriteria;
  filterModalOpen?: boolean;
  moveFleetSummary?: PeripheralMoveFleetSummary;
  searchCriteria?: SearchCriteria;
  peripheralType?: PeripheralType;
  continuationCache?: ContinuationCache<PeripheralListItem[]>;
}

export type PeripheralsSessionConfig = {
  [PeripheralsSessionViewType.LIST_VIEW]?: {
    [key: string]: Partial<PeripheralsListSession>;
  };
};

export interface PeripheralsState {
  items: EntityState<PeripheralListItem>;
  sessionConfigs: PeripheralsSessionConfig;
  operations: {
    [key in BDRequestType | PeripheralRequestType]?: OperationStatus;
  };
}
