import { Asset } from '~/common/models/asset.model';

export const MOCK_ASSET_LIST: Array<Asset> = [
  {
    id: '00000000000000000',
    name: '00000000000000000',
    licensePlate: 'KL1234',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'Chevrolet',
      model: 'Camaro',
      year: 2001,
    },
  },
  {
    id: '1GYKPMRKXPZ000023',
    name: '000023 - 2023 Lyriq EV',
    licensePlate: '1234',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'Cadillac',
      model: 'Lyriq EV',
      year: 2023,
    },
  },
  {
    id: '000334434343434343',
    name: '000334434343434343',
    licensePlate: '123123zxc',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'GM',
      model: 'GMC',
      year: 2024,
    },
  },
  {
    id: '0202211122334146602022',
    name: '0202211122334146602022',
    licensePlate: 'RFA4321',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'Cadillac',
      model: 'Escalade',
      year: 2024,
    },
  },
  {
    id: '1GKS2EKL9PR100096',
    name: '100096 - 2023 Denali',
    licensePlate: 'ABCD1234',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'GMC',
      model: 'Denali',
      year: 2023,
    },
  },
  {
    id: '3GTUUFEL2PG140746',
    name: '140746 - 2023 Sierra',
    licensePlate: 'SIE4321',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'GMC',
      model: 'Sierra',
      year: 2023,
    },
  },
  {
    id: '1GNSCTKL4PR100012',
    name: '100012 - 2023 Tahoe',
    licensePlate: 'TAH6241',
    organizationPrimaryOwnershipStatus: null,
    tcpsAccepted: true,
    type: {
      model: 'test',
      version: 'test',
    },
    vehicleType: {
      make: 'Chevrolet',
      model: 'Tahoe',
      year: 2023,
    },
  },
];
