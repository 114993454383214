import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 298px)',
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      padding: '80px 80px 0 80px',
    },
  },
}));

export default useStyles;
