import { makeStyles } from 'tss-react/mui';

import alertImage1 from '../../../assets/images/alertMessage/alertImage1.jpg';
import alertImage4 from '../../../assets/images/alertMessage/alertImage4.jpg';
import mobileAppGraphic from '../../../assets/images/alertMessage/mobileAppMockup.png';
import defaultGraphic from '../../../assets/images/alertMessage/vanFrontView.png';
import bdLogoDark from '../../../assets/images/bdLogoDark.svg';

const useStyles = makeStyles()((theme) => ({
  body: {
    textAlign: 'center',
    color: theme.new.color.textSecondary,
    marginBottom: '16px',
  },
  logo: {
    backgroundImage: `url("${bdLogoDark}")`,
    backgroundSize: 'cover',
    width: '315px',
    height: '76px',
    margin: 'auto',
    marginBottom: theme.spacing(10),
  },
  alertTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 16px',
    textAlign: 'center',
    width: '100%',
  },
  alertMessageWrapper: {
    maxWidth: '1250px',
    margin: '10px auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginTop: '20px',
    },
    '& .alertMessageGraphic': {
      width: '415px',
      height: '460px',
      background: `url("${defaultGraphic}") 50% 50% no-repeat`,
      backgroundSize: 'auto 100%',
    },
    '&.topic_mobileAppRequired': {
      '& .alertTitleCopy': {
        fontSize: '2em',
        [theme.breakpoints.up('lg')]: {
          fontSize: '3em',
        },
      },
      '& > div > div': {
        width: '50%',
      },
      '& .alertWrapper': {
        padding: '20px',
      },
      '& .alertMessageGraphic': {
        width: '522px',
        height: '584px',
        backgroundImage: `url("${mobileAppGraphic}")`,
        backgroundPosition: '50% 50%',
      },
    },
    '&.topic_logoutSuccess': {
      '& > div > div': {
        width: '50%',
      },
      '& .alertMessageGraphic': {
        height: '580px',
        width: '100%',
        backgroundPosition: '0 50%',
        backgroundImage: `url("${alertImage1}")`,
      },
    },
    '&.topic_pageNotFound': {
      '& > div > div': {
        width: '50%',
      },
      '& .alertMessageGraphic': {
        height: '580px',
        width: '100%',
        backgroundPosition: '0 50%',
        backgroundImage: `url("${alertImage4}")`,
      },
    },
    '&.topic_inviteRequired': {
      '& > div > div': {
        width: '50%',
      },
      '& .alertMessageGraphic': {
        height: '580px',
        width: '100%',
        backgroundPosition: '0 50%',
        backgroundImage: `url("${alertImage1}")`,
      },
    },
    '&.noGraphic': {
      '& > div > div.leftColumn': {
        width: '100%',
      },
    },
  },
  alertMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      margin: '20px auto',
    },
  },
  alertMessageRightCol: {
    width: '40%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  alertMessageLeftCol: {
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
    },
    '& .alertWrapper': {
      padding: '0px',
      [theme.breakpoints.up('lg')]: {
        padding: '20px 30px',
      },
    },
    '& .appStoreLogos': {
      display: 'flex',
      '& > div:first-of-type': {
        marginRight: '10px',
      },
    },
  },
  imageContentContainer: {
    borderRadius: '8px',
    height: '206px',
    marginBottom: '53px',
    width: '320px',
  },
}));

export default useStyles;
